import {ModuleSettings} from "../../models/modules";

export interface FlowsFundingsModuleSettings extends ModuleSettings {
  defaultFundingType: number[];
  defaultReceivers: string[];
  defaultShowFundingBasis: boolean;
}

export const defaultFlowsFundingsModuleSettings: FlowsFundingsModuleSettings = {
  enabled: false,
  defaultFundingType: [0, 5],
  defaultReceivers: [],
  defaultShowFundingBasis: false,
}
