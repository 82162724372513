import {useContext} from "react";
import {ISettings} from "../../../server/models/settings";
import {Modules, ModulesSettings} from "../models/modules";
import {defaultModuleSettings, SettingsContext} from "./SettingsContext";
import {defaultGeneralSettings, GeneralSettings} from "../models/generalSettings";


export class SettingsStore implements ISettings {
  general: GeneralSettings;
  modules: ModulesSettings;

  constructor(settings: ISettings) {
    this.general = settings.general ?? defaultGeneralSettings
    this.modules = settings.modules ?? defaultModuleSettings;
  }

  public isModuleEnabled(module: Modules): boolean {
    return this.modules[module].enabled;
  }
}

export function useSettingsStore() {
  return useContext(SettingsContext).settings;
}

export function useUpdateSettings() {
  return useContext(SettingsContext).updateSettings;
}
