import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMore from "@mui/icons-material/ExpandMore";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

interface LanguageMenuProps {
  onLanguageChange: (lang: string) => void;
}

export const LanguageMenu: React.FC<LanguageMenuProps> = ({
  onLanguageChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedLanguage, setSelectedLanguage] = React.useState("de");
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (lang: string) => {
    handleClose();
    setSelectedLanguage(lang);
    i18n.language = lang;
    i18n.changeLanguage(lang);
    onLanguageChange(lang);
  };

  const availableLanguages = ["DE", "EN"];

  return (
    <div>
      <Button
        id="language-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          color: "var(--text-on-primary-color) !important",
          textTransform: "uppercase !important",
          background: "none !important",
        }}
        disableRipple
        disableElevation
      >
        {selectedLanguage}
        <ExpandMore sx={{ color: "var(--text-on-primary-color)" }} />
      </Button>
      <Menu
        id="language-menu"
        aria-labelledby="language-button"
        aria-label={t("LanguageSelection")}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {availableLanguages.map((lang) => (
          <MenuItem
            key={lang}
            onClick={() => handleSelect(lang.toLowerCase())}
            role="menuitem"
          >
            {lang}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
