import React, { useEffect, useMemo , useState} from 'react';
import { useParams } from 'react-router';
import { getUser, updateUser } from '../services/user-service';
import { useTranslation } from 'react-i18next';
import { IStoredUser } from '../../../server/models/types';
import { IfNoError } from '../components/helper-components';
import { BuildForm, email } from '../components/form-builder/mui-element-factory';
import { faUserPlus, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FormDescription } from '../components/form-builder/field-types';
import { useDispatch } from 'react-redux';
import { setNeedsUpdate } from './list-users';
    

export const EditUser:React.FC = () => {
    const {id} = useParams();
    const [user,setUser] = useState<IStoredUser|undefined>(undefined);
    const [error,setError] = useState("");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const editUserFormDescription  = useMemo<FormDescription<IStoredUser>>(()=>({
        fields: [
            {name: "username", type: "text", label: t("Username"), 
                validators: { required: "required"}, help: t("Pick your own username")},
            {name: "email", type: "email", label: "Email Address", help: t("Provide your email address"), 
                validators: { required: "required", pattern: { value: email, message: t("incorrect_email") }}},
            {name: "active", type: "checkbox", label: t("Active"),
                help: t("Activate or deactivate the account")}, 
            {name: "roles", type: "multicheckbox", label: t("Roles"), 
                options:['user','admin','events','group','payer'],
                inline: true
            },        
        ],
        name: "Edit User Profile",
        submitHandler: (user_) => updateUser(user_).then(()=>dispatch(setNeedsUpdate())),
        submitAction: { label: t("Update Account"), icon: faUserPlus, nextRoute:"/users"},
        cancelAction: { label: t("Cancel"), icon: faArrowCircleLeft, nextRoute:'back'},
        hiddenFields: ['_id']
    }),[dispatch, t]);
    useEffect(()=>{getUser(id || "").then(setUser)
        .catch(err=>err.message?setError(err.message):setError(err))},[id])
    return <IfNoError error={t(error)}>
        <div >
            <div >
            {user && <BuildForm {...editUserFormDescription} initialState={user} ></BuildForm>}
            </div>
        </div> 
    </IfNoError>
} 