import {ModuleSettings} from "../../models/modules";

export interface TopTransferModuleSettings extends ModuleSettings {
  defaultOrgGroupType: string;
  defaultMediaGroupType: string;
  // ...
}

export const defaultTopTransferModuleSettings: TopTransferModuleSettings = {
  enabled: false,
  defaultOrgGroupType: "membership",
  defaultMediaGroupType: "membership",
  // ...
}
