import { useMemo, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import DataTable, { TableColumn } from 'react-data-table-component-with-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileUpload, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../index';
import { IfNoError } from '../components/helper-components';
import { countEntities, deleteOrganisation, getOrganisations } from '../services/data-service';
import { IOrganisationDocument } from '../../../server/models/organisations';
import { ConfirmDelete } from '../components/deleteDialog';
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';

type OrgQuery = {
  name?: string;
  city_de?: string;
  federalState?: string;
}

interface TOrganisationsState {
  organisations: IOrganisationDocument[];
  needsUpdate: boolean;
  pending: boolean;
  page: number;
  size: number;
  count: number;
  query: OrgQuery;
}

export const organisationsSlice = createSlice({
  name: 'zipCodes',
  initialState: {
    organisations: [],
    needsUpdate: true,
    page: 1,
    pending: false,
    size: 10,
    count: 0,
    query: {}
  } as TOrganisationsState,
  reducers: {
    setOrganisationsList: (state, action: PayloadAction<IOrganisationDocument[]>) => (
      { ...state, organisations: action.payload, needsUpdate: false }
    ),
    setNeedsUpdate: (state, action: PayloadAction<void>) => ({ ...state, needsUpdate: true }),
    setPage: (state, action: PayloadAction<number>) => ({ ...state, page: action.payload, needsUpdate: true }),
    setSize: (state, action: PayloadAction<number>) => ({ ...state, size: action.payload, needsUpdate: true }),
    setCount: (state, action: PayloadAction<number>) => ({ ...state, count: action.payload }),
    setQuery: (state, action: PayloadAction<OrgQuery>) => ({ ...state, query: action.payload }),
    setPending: (state, action: PayloadAction<boolean>) => ({ ...state, pending: action.payload })
  }
})

export const { setOrganisationsList, setNeedsUpdate,
  setPage, setSize, setCount, setPending, setQuery } = organisationsSlice.actions;


export const ListOrganisations = () => {
  const { organisations, needsUpdate, page, size, count, pending, query } =
    useSelector<AppState, TOrganisationsState>(state => state.organisations)
  const [organisationToDelete, setOrganisationToDelete] = useState<IOrganisationDocument | null>(null);
  const [error, setError] = useState<string>("");
  const [sortBy, setSortBy] = useState<string | null>(null)
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('asc')
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const columns = useMemo<TableColumn<IOrganisationDocument>[]>(() => [
    {
      name: t('Organisation'),
      selector: row => row['name'],
      sortable: true,
      filterable: true
    },
    {
      name: t('City'),
      selector: row => row['city_de'],
      sortable: true,
      maxWidth: "300px",
      filterable: true
    },
    {
      name: t('Federal State'),
      selector: row => row['federalState'],
      sortable: true,
      right: false,
      width: "170px",
      filterable: true
    },
    {
      name: t('Actions'),
      //width: "100px",
      cell: row =>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button size='small' variant='contained' href={`/organisation/${row._id}`} aria-label={t('Edit')}><FontAwesomeIcon icon={faEdit} /></Button>
        <Button size='small' variant='contained' color="error" aria-label={t('Delete')} onClick={() => setOrganisationToDelete(row)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
      </Box>
    },
  ], [t]);
  const namesToColumns = useMemo(() => ({
    "Organisation": "name",
    "Städte": "city_de",
    "Federal State": "federalState",
    "City": "city_de",
    "Bundesland": "federalState"
  }), [])
  /*
  useEffect(()=>{
    countEntities('/zipcodes/count').then(c=>dispatch(setCount(c)))
  },[])
  */
  useEffect(() => {
    if (organisations.length === 0 || needsUpdate) {
      dispatch(setPending(true))
      Promise.all([countEntities('/organisations/count', query),
      getOrganisations(page, size, sortBy ?? '', sortOrder, query)])
        .then(([count, orgs]) => {
          dispatch(setCount(count))
          dispatch(setOrganisationsList(orgs))
        }).catch(err => {
          err.message ? setError(err.message) : setError(err)
        }).finally(() => dispatch(setPending(false)))
    }
  }, [needsUpdate, dispatch, organisations.length, page, query, size, sortBy, sortOrder])

  const onDelete = () => {
    if (organisationToDelete) {
      deleteOrganisation(organisationToDelete._id.toString())
        .then(() => dispatch(setNeedsUpdate()))
        .catch(err => setError(err))
        .finally(() => setOrganisationToDelete(null))
    } else {
      setOrganisationToDelete(null)
    }
  }

  return <>
    <IfNoError error={error}>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <Button variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faPlusCircle} />} href="/organisation">{t("New Organisation")}</Button>
        <Button variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faFileUpload} />} href="/organisation/upload">{t("Organisation Upload")}</Button>
      </Box>
      <ConfirmDelete show={!!organisationToDelete} elementToDelete={organisationToDelete}
        propertyToPresent="name"
        onHide={() => setOrganisationToDelete(null)} onDelete={onDelete} />
      <Card>
        <CardHeader title={t("Organisations")}/>
        <CardContent className='no-padding'>
          <DataTable
            columns={columns}
            data={organisations}
            pagination={true}
            paginationServer={true}
            onChangePage={e => dispatch(setPage(e))}
            onChangeRowsPerPage={e => dispatch(setSize(e))}
            paginationTotalRows={count}
            progressPending={pending}
            onFilter={e => {
              dispatch(setQuery({
                ...Object.values(e).reduce((acc, { column, value }) =>
                  ({ ...acc, [namesToColumns[column.name?.toString() ?? "test"]]: value }), {})
              }))
              dispatch(setNeedsUpdate())
            }}
            sortServer={true}
            onSort={(column, sortDirection) => {
              setSortOrder(sortDirection)
              setSortBy(namesToColumns[column.name?.toString() ?? ""])
              dispatch(setNeedsUpdate())
              //console.log(column, sortDirection)
            }}
          />
        </CardContent>
      </Card>
    </IfNoError>
  </>


}
