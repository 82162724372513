import { Region } from "../models/models";
import { IBaseQuery, IBaseWithoutData } from "../states/base_states";

export type TopRecord = {
    organisation: string;
    total: number;
    isGrouping?: boolean;
    children?: TopRecord[];
  };
  
  export type TTopResult = {
    top: TopRecord[];
    all: number;
    groupings?: [
      {
        members: any[];
        name: string;
        owner?: string;
      }
    ];
  };
  
  export interface ITopTransferQuery extends IBaseQuery {
    groupType?: string;
    federalState?: Region;
    x: number;
    onlyGroups: boolean;
    resultType: "org" | "media";
    pType: 2 | 4 | 31 | number[];
  }
  
  
  export interface TableRecord {
    organisation: string;
    group: string;
    total: number;
  }
  export interface ITopTransferState extends IBaseWithoutData<ITopTransferQuery> {
    chartModel?: TopRecord[];
    sunburstModel: ReturnType<typeof resultToSunburstModel>;
    mediaGroupType: string;
    onlyMediaGroups: boolean;
    orgGroupType: string;
    onlyOrgGroups: boolean;
    otherMediaDisabled: boolean;
    switchState: number;
    data?: TTopResult,
    table: TableRecord[]
  }

  export const resultToSunburstModel = (data: TTopResult, state: ITopTransferState) => {
    return [
      ...data.top.map((t) =>
        t.children
          ? {
            name: t.organisation,
            value: t.total,
            percent: Math.round((t.total / data.all) * 10000) / 100,
            children: t.children.map((c) => ({
              name: c.organisation,
              value: c.total,
              percent: Math.round((c.total / data.all) * 10000) / 100,
            })),
          }
          : {
            name: t.organisation,
            percent: Math.round((t.total / data.all) * 10000) / 100,
            value: t.total,
          }
      ),
      {
        name: "Others",
        value: data.all - data.top.reduce((acc, { total }) => acc + total, 0),
        percent:
          Math.round(
            ((data.all - data.top.reduce((acc, { total }) => acc + total, 0)) /
              data.all) *
            10000
          ) / 100,
      },
    ];
  };
  
  export const resultToChartModel = (data: TTopResult): TopRecord[] => [
    ...data.top,
    {
      organisation: "Others",
      total: data.all - data.top.reduce((acc, { total }) => acc + total, 0),
    },
  ];
  