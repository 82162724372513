import Config from "../../config/settings";
import { t } from "i18next";

export type Ad = {
    _id: string;
    filename: string;
    contentType: string;
    givenFileName: string;
    fileExtension: string;
    fileDescription: string;
    price: number;
    metadata: {
      originalFileName: string;
      transfer: {
        _id: string;
        organisation: string;
        media: string;
        transferType: number;
        amount: number;
        year: number;
        quarter: number;
        period: number;
      }
    }
  };
  
  export enum FilterType {
    string,
    list,
    priceRange,
    periodRange,
    combinedColumns,
    none
  }
  
  export interface IAdsSortFilter {
    from?: string;
    to?: string;
    organisation?: string[];
    media?: string[];
    sortBy?: string;
    sortOrder?: string;
    contentType?: string;
    givenFileName?: string;
    priceFrom?: string;
    priceTo?: string;
    page?: number;
    size?: number;
    orgGroupType?: string;
    orgGroups?: string[];
    mediaGroupType?: string;
    mediaGroups?: string[]
  }
  
  export type AdsState = {
    data: any[],
    pending: boolean,
    needsUpdate: boolean;
    totalCount?: number;
    sortFilter?: IAdsSortFilter;
  }
  
  export const titleCase = str =>
  str.split(/\s+/).map(w => w[0].toUpperCase() + w.slice(1)).join(' ');
  
  export const initialState: AdsState = {
    pending: false,
    data: [],
    sortFilter: {
      contentType: "", givenFileName: "", priceFrom: "", sortBy: "", sortOrder: "",
      page: 1,
      size: 10,
      organisation: [],
      media: [],
    },
    needsUpdate: false,
    totalCount: 0
  }
  
  export interface AdContentType {
    text: string;
    cType: string;
    payload: string[];
  }

 

  export const convertContentTypes = (types: string[]) => {
    const cTypes: AdContentType[] = [{
      text: t('All'), payload: types, cType: ""
    }];

    const addToArrayIfFound = (text: string, type: string, cType: string) => {
      const item = cTypes.find(it => it.text === text);
      if(item)
        item.payload.push(type);
      else
        cTypes.push({text: text, payload: [type], cType: cType})
    }

    for(const type of types) {
      if(Config.ads.imageTypes.includes(type)) 
        addToArrayIfFound(t('image'), type, "image");
      else if(Config.ads.audioTypes.includes(type)) 
        addToArrayIfFound(t('audio'), type, "audio");
      else if(Config.ads.videoTypes.includes(type)) 
        addToArrayIfFound(t('video'), type, "video");
      else if(Config.ads.fileTypes.includes(type)) 
        addToArrayIfFound(t('document'), type, "application");
    }
    return cTypes;
  }