import {ModuleSettings} from "../../models/modules";

export interface TopFundingsModuleSettings extends ModuleSettings {
  defaultMediaGroupType: string;
}

export const defaultTopFundingsModuleSettings: TopFundingsModuleSettings = {
  enabled: false,
  defaultMediaGroupType: "membership",
}
