import { useEffect, useMemo, useState } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import {
  getAllAds,
  getFlow,
  objectToParams,
  searchNames,
} from "../../services/data-service";
import {
  Help,
  ModalLoader,
  Render,
  ShowError,
} from "../../components/helper-components";
import { ResponsiveContainer } from "recharts";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faInfoCircle,
  faListUl,
  faStream,
} from "@fortawesome/free-solid-svg-icons";

import { infoSlice, TInfoState } from "../../App";
import qs from "query-string";
import {
  getAmountRangeString,
  getGroupTypesByType,
  isEqual,
  isObjectSubset,
  isTouchSupported,
  periodToString,
  range,
} from "../../helpers/helpers";
import "./flows.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Badge,
  Box,
  Grid,
  Tab as TabMui,
  Tabs as TabsMui,
} from "@mui/material";
import { getColor } from "../../colors";
import { ToggleSwitch } from "../../components/three-state/three-state-switch";
import DataTable from "react-data-table-component-with-filter";
import i18n from "../../i18n";
import { ExportToExcel } from "../../components/excel-exporter";
import ReactECharts from "echarts-for-react";
import "../top/top.scss";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";
import { SwitchButton } from "../../components/switch-button/switchButton";
import { TransferMultiSelectComp } from "../../components/multi-select/transfer-multi-select";
import { MultiSelectComp } from "../../components/multi-select/multi-select";
import {
  AmountSlider,
  PeriodSlider,
} from "../../components/slider/rangeSlider";
import useTouchDoubleClickChecker from "../../helpers/touchDoubleClickChecker";
import { GroupTypeSelector } from "../../components/group-type-selector";
import Config from "../../config/settings";
import {
  amountRangeReducers,
  extractAmountRange,
  IAmountFilterRange,
  IAmountRange,
  IBaseState,
  IOrgAndMediaQuery,
  orgAndMediaReducers,
} from "../../states/base_states";
import { summariseTransfers } from "../../helpers/flows-transfer.helper";
import { Ads } from "../ads/ads";
import { Fold, SettingsViewer } from "../../components/settings-viewer";

import * as echarts from "echarts/core";
import german from "echarts/i18n/langDE-obj";

import { defaultBaseTimelineModel, defaultSankeyOptions } from "./flows";
import { useSettingsStore } from "../../context/SettingsStore";
import { Modules } from "../../models/modules";
import { FlowsTransferModuleSettings } from "./flows-transfer.settings";
import { defaultModuleSettings } from "../../context/SettingsContext";

//TODO: export filteredData to excel and add range to chart title

echarts.registerLocale("de", german);

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const minHeight = Config.flows.sankey.minHeight;

export const getOtherMediaName = () => {
  return t("Other media");
};

export const getOtherMediaNameForMedia = (media: string) => {
  return t("Other media") + " (" + media + ")";
};

export const getOtherOrgName = () => {
  return t("Other organisations");
};

export const getOtherOrgNameForOrg = (org: string) => {
  return t("Other organisations") + " (" + org + ")";
};

type TSankeyModel = {
  nodes: { name: string }[];
  links: {
    source: number;
    target: number;
    value: number;
    transferType: 2 | 4 | 31 | number[];
  }[];
  right?: string;
  lineStyle?: {
    color: string;
  };
  layoutIterations?: number;
  label?: any;
};

let echartRef;

export type TFlowsResultRecord = {
  amount: number;
  media: string;
  mediaGroup?: string;
  orgGroup?: string;
  organisation: string;
  transferType: 2 | 4 | 31 | number[];
};

const toCurrency = (field: string) => (row: any) =>
  row[field]?.toLocaleString(i18n.language, {
    style: "currency",
    currency: "EUR",
  });

export type TFlowResult = TFlowsResultRecord[];

export type TTimelineResult = { period: string; total: number }[];

export type TFlowsQueryResult = {
  flows: TFlowResult;
  timeline: TTimelineResult;
};

export interface IFlowsQuery extends IOrgAndMediaQuery, IAmountFilterRange {
  showAbsoluteValues?: boolean;
  summariseValues?: boolean;
}

const fillupMissingQuarters = (period: string) => {
  if (period[0] === "Q" && parseInt(period[1], 10) > 1) {
    return range(1, parseInt(period[1], 10)).map((q) => ({
      period: `Q${q}/${period.slice(-4)}`,
      quarter: q,
      year: parseInt(period.slice(-4), 10),
      total: 0,
    }));
  }
  return [];
};

const timelineToEcharModel = (data: TTimelineResult) => [
  ...fillupMissingQuarters(data[0].period),
  ...data.map((e) => ({
    ...e,
    quarter: parseInt(e.period.charAt(1)),
    year: parseInt(e.period.split("/")[1]),
  })),
];

export interface IFlowState
  extends IBaseState<IFlowsQuery, TFlowResult>,
  IAmountRange<IFlowsQuery, TFlowResult> {
  timeline: TTimelineResult;
  timelineModel: ReturnType<typeof timelineToEcharModel>;
  chartModel: TSankeyModel;
  selectedOrganisaions: string[];
  organisationsList: string[];
  switchState: number;
  activeTab: number;
  filteredData: TFlowResult;
}

export const flowsSlice = createSlice({
  name: "flows",
  initialState: {
    pristine: true,
    data: [] as TFlowResult,
    filteredData: [] as TFlowResult,
    timeline: [] as any[] as TTimelineResult,
    switchState: 2,
    timelineModel: [],
    activeTab: 0,
    amountRange: [0, 0],
    query: {
      showAbsoluteValues: true,
      summariseValues: false,
      pType: 2,
      organisations: defaultModuleSettings.flows_transfer.defaultOrganisations,
      orgGroups: [],
      media: defaultModuleSettings.flows_transfer.defaultMedia,
      mediaGroups: [],
      mediaGroupType: "",
      orgGroupType: "",
      amountFilterRange: [0, 0],
    } as IFlowsQuery,
    pending: false,
    needsUpdate: false,
    chartModel: {
      node: [],
      nodes: [],
      links: [],
    } as TSankeyModel,
    selectedOrganisaions: [],
    organisationsList: [],
  } as IFlowState,
  reducers: {
    ...orgAndMediaReducers<IFlowsQuery, TFlowResult, IFlowState>(),
    ...amountRangeReducers<IFlowsQuery, TFlowResult, IFlowState>(),
    setData: (
      state,
      action: PayloadAction<{
        data: TFlowResult;
        showAbsoluteValues: boolean;
        summariseData: boolean;
      }>
    ) => {
      const { data, showAbsoluteValues, summariseData } = action.payload;
      const amountRange = extractAmountRange(data);
      return {
        ...state,
        data: data,
        filtered: data,
        query: { ...state.query, amountFilterRange: amountRange },
        amountRange: amountRange,
        needsUpdate: false,
        chartModel: responseToSankeyModel(
          data,
          showAbsoluteValues,
          summariseData,
          +state.query.from!,
          +state.query.to!
        ),
      };
    },
    setTimeline: (state, action: PayloadAction<TTimelineResult>) => ({
      ...state,
      timeline: action.payload,
      timelineModel: timelineToEcharModel(action.payload),
    }),
    setQuery: (state, action: PayloadAction<IFlowsQuery>) => ({
      ...state,
      query: { ...state.query, ...action.payload },
      switchState: Array.isArray(action.payload.pType)
        ? (action.payload.pType as number[]).length > 1
          ? 0
          : +action.payload.pType[0]
        : +action.payload.pType,
    }),
    setSummariseValues: (state, action: PayloadAction<boolean>) => {
      state.query.summariseValues = action.payload;
    },
    setNeedsUpdate: (state) => ({
      ...state,
      needsUpdate: true,
    }),
    updateFilteredData: (state, action: PayloadAction<void>) => {
      state.filteredData = state.data.filter((d) => {
        const amountFilterRange = state.query.amountFilterRange ?? [
          0,
          Number.MAX_VALUE,
        ];
        return (
          d.amount >= amountFilterRange[0] && d.amount <= amountFilterRange[1]
        );
      });
    },
    updateModelSankeyModel: (state, action: PayloadAction<void>) => {
      state.chartModel = responseToSankeyModel(
        state.filteredData,
        state.query.showAbsoluteValues ?? true,
        state.query.summariseValues ?? false,
        +state.query.from!,
        +state.query.to!
      );
    },
    clearNeedsUpdate: (state) => ({
      ...state,
      needsUpdate: false,
    }),
    setMediaGroupType: (state, action: PayloadAction<string>) => ({
      ...state,
      query: {
        ...state.query,
        mediaGroupType: action.payload,
        mediaGroups: [],
      },
    }),
    setOrgGroupType: (state, action: PayloadAction<string>) => ({
      ...state,
      query: {
        ...state.query,
        orgGroupType: action.payload,
        orgGroups: [],
      },
    }),
    setMedia: (state, action: PayloadAction<string[]>) => ({
      ...state,
      query: {
        ...state.query,
        media: action.payload,
      },
    }),
    setOrganisations: (state, action: PayloadAction<string[]>) => ({
      ...state,
      query: {
        ...state.query,
        organisations: action.payload,
      },
    }),
    setTouched: (state, action: PayloadAction<void>) => ({
      ...state,
      pristine: false,
    }),
    saveQuery: (state, action: PayloadAction<void>) => ({
      ...state,
      lastQuery: state.query,
    }),
    setShowAbsoluteValues: (
      state,
      action: PayloadAction<{
        showAbsoluteValues: boolean;
        summariseData: boolean;
      }>
    ) => {
      const { showAbsoluteValues, summariseData } = action.payload;
      return {
        ...state,
        query: { ...state.query, showAbsoluteValues: showAbsoluteValues },
        chartModel: responseToSankeyModel(
          state.filteredData,
          showAbsoluteValues,
          summariseData,
          +state.query.from!,
          +state.query.to!
        ),
      };
    },
    setRange: (state, action: PayloadAction<{ from: number; to: number }>) => ({
      ...state,
      query: {
        ...state.query,
        from: action.payload.from,
        to: action.payload.to,
      },
    }),
    extendQuery: (state, action: PayloadAction<Partial<IFlowsQuery>>) => ({
      ...state,
      query: { ...state.query, ...action.payload },
      switchState: Array.isArray(action.payload.pType)
        ? (action.payload.pType as number[]).length > 1
          ? 0
          : +action.payload.pType[0]
        : +(action.payload.pType ?? 2),
    }),
    setActiveTab: (state, action: PayloadAction<number>) => ({
      ...state,
      activeTab: action.payload,
    }),
    initializeStateFromSettings: (
      state,
      action: PayloadAction<FlowsTransferModuleSettings>
    ) => {
      // Update the state with the initial values from the settings
      return {
        ...state,
        query: {
          ...state.query,
          organisations: action.payload.defaultOrganisations,
          orgGroups: action.payload.defaultOrgGroups,
          media: action.payload.defaultMedia,
          mediaGroups: action.payload.defaultMediaGroups,
          mediaGroupType: action.payload.defaultMediaGroupType,
          orgGroupType: action.payload.defaultOrgGroupType,
        },
      };
    },
  },
});

const {
  setData,
  setPending,
  setQuery,
  setOrgGroupType,
  setMediaGroupType,
  setNeedsUpdate,
  clearNeedsUpdate,
  setTimeline,
  setRange,
  extendQuery,
  saveQuery,
  setShowAbsoluteValues,
  setTouched,
  setSummariseValues,
  setAmountFilterRange,
  updateFilteredData,
  updateModelSankeyModel,
  initializeStateFromSettings,
} = flowsSlice.actions;

const { setAdsSeen } = infoSlice.actions;

const payerSumMap: Map<string, number> = new Map();
const flowSumMap: Map<string, number> = new Map();
const receiverSumMap: Map<string, number> = new Map();
const groupsSet: Set<string> = new Set();
let adsGroupedByTransfer: Map<string, string> = new Map();

// should come with the data Result
getAllAds().then((res) => {
  adsGroupedByTransfer = res.files.reduce((map: Map<string, string>, item) => {
    if (item?.metadata?.adActions) {
      for (const adAction of item?.metadata?.adActions) {
        const key = adAction?.organisation + "||" + adAction?.media;
        map[key] = map[key] ?? [];
        map[key].push(item);
      }
    }
    return map;
  }, {});
});

const getAds = (source: string, target: string, from: number, to: number) => {
  const tOKey = source + "||" + target;
  const allAdsFromTo = adsGroupedByTransfer[tOKey];

  let ads = [];
  if (allAdsFromTo) {
    const flattenedAdActions = allAdsFromTo
      ?.map((it) => it?.metadata?.adActions)
      .filter((arr) => arr !== undefined)
      .reduce((accumulator, currentArray) => {
        return accumulator.concat(currentArray);
      }, []);
    ads = flattenedAdActions.filter(
      (it) =>
        it?.period >= from &&
        it?.period <= to &&
        it?.organisation === source &&
        it?.media === target
    );
  }
  return ads;
};

type LinkEndType = "org" | "media" | "orgGroup" | "mediaGroup";

const responseToSankeyModel = (
  data: TFlowResult,
  showAbsoluteVisualisation: boolean,
  summariseData: boolean,
  pFrom: number,
  pTo: number
): TSankeyModel => {
  payerSumMap.clear();
  receiverSumMap.clear();
  flowSumMap.clear();
  const rawNodes = data.reduce(
    (acc, { organisation, media, orgGroup, mediaGroup }) => {
      acc = { ...acc, [organisation]: "org", [media]: "media" }; //.add(orgGroup)
      if (orgGroup) {
        groupsSet.add(orgGroup);
        acc = { ...acc, [orgGroup]: "orgGroup" };
      }
      if (mediaGroup) {
        groupsSet.add(mediaGroup);
        acc = { ...acc, [mediaGroup]: "mediaGroup" };
      }
      return acc;
    },
    {}
  );

  let nodes = Object.entries(rawNodes).map(([k, v]) => ({
    name: k.startsWith("Other") ? getOtherMediaName() : k,
    type: v,
    label:
      v === "media"
        ? {
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
          position: "insideRight",
          offset: [-20, 0],
        }
        : {
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
        },
    itemStyle: {
      color: Config.flows.sankey.nodesColor,
      borderColor: Config.flows.sankey.nodesColor,
    },
  }));
  const linkMaps = {} as {
    [id: string]: {
      type: LinkEndType;
      to: {
        [id: string]: { [t: number]: number; type: LinkEndType };
      };
    };
  };
  const addLink = (
    from,
    to,
    tType,
    amount,
    srcType: LinkEndType = "org",
    targetType: LinkEndType = "media",
    hasAd: boolean = false
  ) => {
    const visAmount = showAbsoluteVisualisation ? amount : Math.log10(amount);
    if (linkMaps[from]) {
      if (linkMaps[from].to[to]) {
        if (linkMaps[from].to[to][tType]) {
          //linkMaps[from].to[to][tType] = linkMaps[from].to[to][tType] + visAmount
          linkMaps[from].to[to][tType] += visAmount;
        } else {
          linkMaps[from].to[to][tType] = visAmount;
        }
      } else {
        linkMaps[from].to[to] = { [tType]: visAmount, type: targetType };
      }
    } else {
      linkMaps[from] = {
        type: srcType,
        to: { [to]: { [tType]: visAmount, type: targetType } },
      };
    }
    if (payerSumMap.has(from))
      payerSumMap.set(from, payerSumMap.get(from) + amount);
    else payerSumMap.set(from, amount);

    const toKey = to.startsWith("Other") ? getOtherMediaName() : to;
    if (receiverSumMap.has(toKey))
      receiverSumMap.set(toKey, receiverSumMap.get(toKey) + amount);
    else receiverSumMap.set(toKey, amount);

    const flowKey = from + "||" + to;
    if (flowSumMap.has(flowKey))
      flowSumMap.set(flowKey, flowSumMap.get(flowKey) + amount);
    else flowSumMap.set(flowKey, amount);
  };

  const hasAdMap: Map<string, any> = new Map();
  const addHasAd = (link: any, ads: any) => {
    let src = link.organisation;
    if (link.orgGroup) {
      hasAdMap.set(link.organisation + "||" + link.orgGroup, ads);
      src = link.orgGroup;
    }
    if (link.mediaGroup) {
      hasAdMap.set(src + "||" + link.mediaGroup, ads);
      hasAdMap.set(link.mediaGroup + "||" + link.media, ads);
    } else {
      hasAdMap.set(src + "||" + link.media, ads);
    }
  };
  //const nodes = rawNodes
  data.forEach((l) => {
    let src = l.organisation;
    let srcType: LinkEndType = "org";
    if (l.transferType == 2) {
      const ads = getAds(l.organisation, l.media, +pFrom, +pTo);
      if (ads?.length > 0) addHasAd(l, ads);
    }
    if (l.orgGroup) {
      addLink(
        l.organisation,
        l.orgGroup,
        l.transferType,
        l.amount,
        "org",
        "orgGroup"
      );
      src = l.orgGroup;
      srcType = "orgGroup";
    }
    if (l.mediaGroup) {
      addLink(
        src,
        l.mediaGroup,
        l.transferType,
        l.amount,
        srcType,
        "mediaGroup"
      );
      addLink(
        l.mediaGroup,
        l.media,
        l.transferType,
        l.amount,
        "mediaGroup",
        "media"
      );
    } else {
      addLink(src, l.media, l.transferType, l.amount, srcType, "media");
    }
  });

  let links = Object.entries(linkMaps).reduce(
    (acc, [from, o]) => [
      ...acc,
      ...Object.entries(o.to).reduce(
        (acc_, [to, targetObj]) => [
          ...acc_,
          ...Object.keys(targetObj).reduce((acc2, key) => {
            if (key === "type") return acc2;
            const lineStyle: any = {
              color:
                key === "2"
                  ? Config.flows.sankey.transferFlowsColor
                  : Config.flows.sankey.fundingFlowsColor,
            };
            const hasAd = hasAdMap.get(from + "||" + to);
            if (hasAd) {
              lineStyle.shadowColor =
                Config.flows.sankey.shadowColorForObjectFlows;
              lineStyle.shadowOffsetX = 0;
              lineStyle.shadowOffsetY = 0;
              lineStyle.shadowBlur = 1;
            }
            return [
              ...acc2,
              {
                source: from,
                type: "org",
                srcType: o.type,
                targetType: targetObj.type,
                target: to.startsWith("Other") ? getOtherMediaName() : to,
                ads: hasAd ? hasAdMap.get(from + "||" + to) : [],
                value: targetObj[key],
                transferType: key,
                lineStyle: lineStyle,
              },
            ];
          }, [] as any),
        ],
        [] as any
      ),
    ],
    [] as any
  );

  if (summariseData) {
    const res = summariseTransfers(links, rawNodes, nodes, payerSumMap);
    links = res.sumLinks;
    nodes = res.sumNodes;
  }
  return {
    nodes: nodes,
    links: links,
    right: "5%",
    layoutIterations: summariseData ? 0 : 32,
    label: {
      borderWidth: 0,
      color: Config.flows.sankey.flowsLabelColor,
    },
  };
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const FlowsTransfers = () => {
  // const { setPayerGroupsEnabled, setBeneficiaryGroup, setShowAbsoluteVisualisation
  // } = infoSlice.actions
  const [error, setError] = useState("");
  const { tab } = useParams();
  const settings = useSettingsStore();
  const moduleSettings = settings.modules.flows_transfer;

  const navigate = useNavigate();
  const location = useLocation();
  const { periods, groups, groupTypes, adsCount, adsSeen } = useSelector<
    AppState,
    TInfoState
  >((state) => state.info);

  const setTab = (newValue: string, updateRoute: boolean) => {
    if (tab != newValue && updateRoute && moduleSettings.enabled) {
      navigate(`/flows/transfers/${newValue}${location.search}`, {
        replace: location.search.length === 0,
      });
    }
    setUpdateRoute(true);
  };
  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const isMobile = isMobileLandscape || isMobilePortrait;

  const { t, i18n } = useTranslation();
  const queryParams = useQuery();
  const dispatch = useDispatch();
  const {
    pending,
    needsUpdate,
    data,
    query,
    timeline,
    chartModel,
    switchState,
    timelineModel,
    lastQuery,
    pristine,
    amountRange,
    filteredData,
  } = useSelector<AppState, IFlowState>((state) => state.flow);

  const toPaymentTypeText = (field: string) => (row: any) => {
    switch (row[field]) {
      case 2:
        return t("Advertising");
      case 4:
        return t("Funding");
      default:
        return t("Fees");
    }
  };

  useEffect(() => {
    dispatch(updateFilteredData());
    dispatch(updateModelSankeyModel());
  }, [query.amountFilterRange]);

  useEffect(() => {
    document["lastClick"] = Date.now();
  }, []);

  const handleClick = ({ data }) => {
    if (isTouchSupported()) {
      const now = Date.now();
      const lastClick = document["lastClick"];
      // console.log('lastClick', lastClick)
      if (now - lastClick < 600) {
        return selectPeriod(data.period);
      } else {
        document["lastClick"] = now;
      }
    } else {
      return selectPeriod(data.period);
    }
  };

  const timelineTooltipFormatter = (params) => {
    params = params instanceof Array ? params : [params];
    const includesForecast = params.filter((e) => e.data.prediction).length > 0;
    const fmt = new Intl.NumberFormat(i18n.language, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    let d = params
      .map(
        (p) =>
          `${p.marker} ${p.seriesName}${p.data["prediction"] ? "*" : ""
          } <span style="float:right;margin-left:20px;font-size:14px;color:${p.data["prediction"] ? "#aaa" : "#666"
          };font-weight:900">${fmt.format(
            p.data[p.dimensionNames[p.encode.y]]
          )}</span>`
      )
      .join("<br/>");
    if (params.length > 1) {
      if (!includesForecast) {
        const s = params
          .filter((e) => !e.data.prediction)
          .reduce((acc, p) => acc + p.data[p.dimensionNames[p.encode.y]], 0);
        d = `${d}<br/><span style="font-weight:900">${t(
          "Total"
        )}</span><span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${fmt.format(
          s
        )}</span>`;
      } else {
        if (includesForecast) {
          const s = params.reduce(
            (acc, p) => acc + p.data[p.dimensionNames[p.encode.y]],
            0
          );
          d = `${d}<br/>${t(
            "Total"
          )}*<span style="float:right;margin-left:20px;font-size:14px;color:#aaa;font-weight:900">${fmt.format(
            s
          )}</span>`;
          d += `<br>*) ${t("Forecast")}`;
        }
      }
    }
    return `${params[0].name}<br/>${d}`;
  };

  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const colorsFromCSS = range(1, 9).map(i =>
      getComputedStyle(document.documentElement)
        .getPropertyValue(`--echart-color-${i}`)
        .trim()
    ).filter(c => c.length > 0)
    setColors(colorsFromCSS);
  }, []);

  const getColorFromIndex = (idx: number, count: number) => {
    return colors.length > 0 ? colors[idx] : getColor(idx, count); // Return color if available, otherwise empty string
  };

  const timelineOptions = useMemo(
    () => ({
      ...defaultBaseTimelineModel(
        isMobile,
        timelineTooltipFormatter,
        t("flows_timeline_title", {
          paymentType: t(
            query.pType == 2
              ? "advertising_payments"
              : query.pType == 4
                ? "funding_payments"
                : "advertising_and_funding_payments"
          ),
        }),
        t("flows_transfer_sub_title", {
          payers:
            query.organisations.length > 0 || (query.orgGroups?.length ?? 0) > 0
              ? [...query.organisations, ...(query.orgGroups ?? [])].join(", ")
              : t("all"),
          receivers:
            query.media.length > 0 || (query.mediaGroups?.length ?? 0) > 0
              ? [...query.media, ...(query.mediaGroups ?? [])].join(", ")
              : t("all"),
        }) +
        `\n${t("Source")}: KommAustria, ${t(
          "Date"
        )}: ${new Date().toLocaleString()}` +
        `\nLink: ${window.location.href}`,
        true
      ),

      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: timelineTooltipFormatter,
      },
      xAxis: { type: "category" },
      yAxis: {
        axisLabel: {
          formatter: (value) => `${value / 1000000} Mio.`,
        },
      },
      dataset: [
        {
          dimensions: ["year", "total", "quarter", "transferType"],
          source: timelineModel,
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "quarter", "=": 1 }],
            },
            print: false,
          },
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "quarter", "=": 2 }],
            },
            print: false,
          },
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "quarter", "=": 3 }],
            },
            print: false,
          },
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "quarter", "=": 4 }],
            },
            print: true,
          },
        },
      ],
      series: [
        {
          type: "bar",
          datasetIndex: 1,
          name: "Q1",
          emphasis: { focus: "series" },
          color: getColorFromIndex(1, 4),
          barCategoryGap: "10%",
        },
        {
          type: "bar",
          datasetIndex: 2,
          name: "Q2",
          emphasis: { focus: "series" },
          color: getColorFromIndex(2, 4),
          barCategoryGap: "10%",
        },
        {
          type: "bar",
          datasetIndex: 3,
          name: "Q3",
          emphasis: { focus: "series" },
          color: getColorFromIndex(3, 4),
          barCategoryGap: "10%",
        },
        {
          type: "bar",
          datasetIndex: 4,
          name: "Q4",
          emphasis: { focus: "series" },
          color: getColorFromIndex(4, 4),
          barCategoryGap: "10%",
        },
      ],
      onClick: () => alert("hi!"),
      animation: process.env.NODE_ENV !== "development",
    }),
    [t, timelineModel, colors, i18n.language]
  );

  useEffect(() => {
    if (periods && periods.length > 0) {
      //console.log("Setting from and to!!!")
      //console.log("Periods: " + JSON.stringify(periods, null, 2))
      const maxPeriod = periods[periods.length - 1];
      //console.log("Maxperiod: " + maxPeriod)
      if (!query.from || !query.to) {
        dispatch(
          setRange({
            from: maxPeriod,
            to: maxPeriod,
          })
        );
      }
    }
  }, [periods]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    //if (queryStringParsed) {
    //    setQueryStringParsed(false)
    //} else {
    if (
      periods &&
      periods.length > 0 &&
      query.organisations.length +
      query.media.length +
      (query.orgGroups ?? []).length +
      (query.mediaGroups ?? []).length >
      0 &&
      query.from &&
      query.to
    ) {
      //console.log("Checking query vs. location")
      const queryString = objectToParams(query);
      const currentQueryStringObj = qs.parse(location.search);
      const queryStringObjFromQueryObject = qs.parse(queryString);
      console.log(
        "isEqual: " +
        isEqual(currentQueryStringObj, queryStringObjFromQueryObject)
      );
      if (!isEqual(currentQueryStringObj, qs.parse(queryString))) {
        navigate(`/flows/transfers/${tab}?${queryString}`, {
          replace: Object.keys(currentQueryStringObj).length !== Object.keys(queryStringObjFromQueryObject).length,
        });
      }
      //    }
      dispatch(setNeedsUpdate());
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //console.log("Location changed")
    let q = {
      pType: [2],
      organisations: [],
      orgGroups: [],
      media: [],
      mediaGroups: [],
      orgGroupType: "",
      mediaGroupType: "",
    };
    [
      "organisations",
      "media",
      "pType",
      "orgGroups",
      "mediaGroups",
      "amountFilterRange",
    ].forEach((org) => {
      if (queryParams.getAll(org).length > 0) {
        q[org] = queryParams.getAll(org).filter((e) => e.length > 0);
      }
    });
    ["orgGroupType", "mediaGroupType"].forEach((org) => {
      if (queryParams.get(org)) {
        q[org] = queryParams.get(org);
      }
    });
    ["showAbsoluteValues", "summariseValues"].forEach((org) => {
      if (queryParams.get(org)) {
        q[org] = queryParams.get(org) === "true";
      }
    });
    ["from", "to"].forEach((org) => {
      if (queryParams.get(org)) {
        q[org] = parseInt(queryParams.get(org) ?? "0");
      }
    });
    if (
      q.organisations.length +
      q.media.length +
      (q.orgGroups ?? []).length +
      (q.mediaGroups ?? []).length >
      0 &&
      q["from"] &&
      q["to"]
    ) {
      if (!isObjectSubset(q, query)) {
        dispatch(extendQuery(q));
        dispatch(setNeedsUpdate());
      }
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(
      "Cached: " +
      (lastQuery != undefined &&
        isEqual(lastQuery, query, ["showAbsoluteValues", "summariseValues"]))
    );
    if (
      needsUpdate &&
      query.organisations.length +
      query.media.length +
      (query.orgGroups ?? []).length +
      (query.mediaGroups ?? []).length >
      0 &&
      query.from &&
      query.to &&
      (lastQuery == undefined ||
        !isEqual(lastQuery, query, [
          "showAbsoluteValues",
          "summariseValues",
          "amountFilterRange",
        ]))
    ) {
      setError("");
      //console.log("Sending new query")
      dispatch(saveQuery());
      dispatch(setPending(true));
      getFlow(query)
        .then(({ timeline, flows }) => {
          dispatch(
            setTimeline(
              timeline.map(({ period, total }) => ({
                total,
                period: periodToString(parseInt(period)),
              }))
            )
          );
          dispatch(
            setData({
              data: flows,
              showAbsoluteValues: query.showAbsoluteValues ?? true,
              summariseData: query.summariseValues ?? false,
            })
          );
        })
        .catch((err) => {
          setError(err?.response?.data?.message ?? err.message);
          dispatch(clearNeedsUpdate());
        })
        .finally(() => dispatch(setPending(false)));
    } else if (needsUpdate) {
      dispatch(clearNeedsUpdate());
    }
  }, [needsUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Fill the initial state with the default values from the settings
    if (pristine && location.search.length == 0) {
      console.log(location.search)
      const settingsToLoad = structuredClone(moduleSettings);
      settingsToLoad.defaultOrgGroupType =
        getGroupTypesByType(groupTypes, "org")
          .find((g) => g.name === moduleSettings.defaultOrgGroupType)
          ?._id?.toString() ?? "";
      settingsToLoad.defaultMediaGroupType =
        getGroupTypesByType(groupTypes, "media")
          .find((g) => g.name === moduleSettings.defaultMediaGroupType)
          ?._id?.toString() ?? "";

      dispatch(initializeStateFromSettings(settingsToLoad));
    }
    dispatch(setTouched());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const timelineColums = useMemo(
    () => [
      {
        name: t("Period"),
        selector: (row) => row["period"],
        sortable: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row["total"],
        sortable: true,
        format: toCurrency("total"),
        right: true,
      },
    ],
    [t, i18n.language]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => [
      {
        name: t("Payer"),
        selector: (row) => row["organisation"],
        sortable: true,
      },
      ...(query.orgGroupType
        ? [
          {
            name: t("Payer group"),
            selector: (row) => row["orgGroup"],
            sortable: true,
          },
        ]
        : []),
      {
        name: t("Beneficiary"),
        selector: (row) => row["media"],
        sortable: true,
      },
      ...(query.mediaGroupType
        ? [
          {
            name: t("Beneficiary group"),
            selector: (row) => row["mediaGroup"],
            sortable: true,
          },
        ]
        : []),
      {
        name: t("Payment type"),
        selector: (row) => row["transferType"],
        sortable: true,
        format: toPaymentTypeText("transferType"),
        // width: "10em",
      },
      {
        name: t("Amount"),
        selector: (row) => row["amount"],
        sortable: true,
        format: toCurrency("amount"),
        right: true,
      },
    ],
    [t, i18n.language, query.orgGroupType, query.mediaGroupType]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const updateSelection =
    (orgType: "org" | "media" | "orgGroups" | "mediaGroups") =>
      (list: string[]) => {
        setError("");
        const fields = {
          org: "organisations",
          media: "media",
          orgGroups: "orgGroups",
          mediaGroups: "mediaGroups",
        };
        dispatch(
          setQuery({
            ...query,
            [fields[orgType]]: list.filter((v) => v.length > 0),
          })
        );
        //dispatch(setNeedsUpdate())
      };

  const customToolTip = (params) => {
    const fmt = new Intl.NumberFormat(i18n.language, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });

    const isLink = params.data.source && params.data.target;
    let value = query.showAbsoluteValues
      ? params.value
      : Math.pow(10, params.value);
    if (!isLink) {
      if (payerSumMap.has(params.data.name))
        value = payerSumMap.get(params.data.name);

      if (receiverSumMap.has(params.data.name))
        value = receiverSumMap.get(params.data.name);
      return `
                <b>${params.data.name.startsWith("Other")
          ? t(params.data.name)
          : params.data.name
        }</b><br />
                ${fmt.format(value)}
                `;
    } else {
      if (flowSumMap.has(params.data.source + "||" + params.data.target))
        value = flowSumMap.get(params.data.source + "||" + params.data.target);

      const orgPercent = (
        (value /
          ((payerSumMap.has(params.data.source)
            ? payerSumMap.get(params.data.source)
            : 0) ?? 100)) *
        100
      ).toFixed(2);
      const mediaPercent = (
        (value /
          ((receiverSumMap.has(params.data.target)
            ? receiverSumMap.get(params.data.target)
            : 0) ?? 100)) *
        100
      ).toFixed(2);

      let ads = getAds(
        params.data.source,
        params.data.target,
        +query.from!,
        +query.to!
      );
      return (
        `
            <b>${params.data.source.startsWith("Other")
          ? t(params.data.source)
          : params.data.source
        }
            ${!params.data.source.startsWith("Other")
          ? `${isFinite(+orgPercent) && +orgPercent % 100 !== 0
            ? "(" + orgPercent + "%)"
            : ""
          } `
          : ""
        }
            ${isMobile ? "<br/>" : ""} &#x2192;
            ${params.data.target.startsWith("Other")
          ? t(params.data.target)
          : params.data.target
        }
            ${!params.data.target.startsWith("Other")
          ? `${isFinite(+mediaPercent) && +mediaPercent % 100 !== 0
            ? "(" + mediaPercent + "%)"
            : ""
          } `
          : ""
        }</b><br />
            ${fmt.format(value)} (${params.data.transferType === "2" ? t("Advertising") : t("Funding")
        })` +
        (ads?.length > 0
          ? `<br/>` + t("amount_ads", { amount: ads.length })
          : ``)
      );
    }
  };

  const sankeyOptions = useMemo(
    () =>
      defaultSankeyOptions(
        isMobile,
        customToolTip,
        t("flows_transfer_title", {
          paymentType: t(
            query.pType == 2
              ? "advertising_payments"
              : query.pType == 4
                ? "funding_payments"
                : "advertising_and_funding_payments"
          ),
          period:
            query.to !== query.from
              ? `${periodToString(query.from ?? 0)} - ${periodToString(
                query.to ?? 0
              )}`
              : periodToString(query.to ?? 0),
        }),
        t("flows_transfer_sub_title", {
          payers:
            query.organisations.length > 0 || (query.orgGroups?.length ?? 0) > 0
              ? [...query.organisations, ...(query.orgGroups ?? [])].join(", ")
              : t("all"),
          receivers:
            query.media.length > 0 || (query.mediaGroups?.length ?? 0) > 0
              ? [...query.media, ...(query.mediaGroups ?? [])].join(", ")
              : t("all"),
        }) +
        `\n${getAmountRangeString(query, amountRange, i18n, t)}` +
        `\n${t("Source")}: KommAustria, ${t(
          "Date"
        )}: ${new Date().toLocaleString()}` +
        `\nLink: ${window.location.href}`,
        chartModel
      ),
    [t, chartModel, i18n.language]
  );

  const selectPeriod = (period) => {
    const p = period.slice(3) + period.slice(1, 2);
    dispatch(setRange({ from: Number(p), to: Number(p) }));
    setActiveTab("flows");
    setUpdateRoute(false);
  };
  const toggleStates = [
    { label: t("Advertising"), value: 2 },
    { label: t("Funding"), value: 4 },
    { label: t("Both"), value: 0 },
  ];

  const onChartClick = (params) => {
    const isLink = params.data.source && params.data.target;
    const flowQuery = {
      organisations: [],
      media: [],
      orgGroups: [],
      mediaGroups: [],
    } as any;
    if (isLink) {
      if (
        params.data.target.startsWith("Andere") ||
        params.data.target.startsWith("Other")
      )
        return;
      switch (params.data.srcType) {
        case "org":
          flowQuery.organisations = [params.data.source];
          break;
        case "orgGroup":
          flowQuery.orgGroups = [params.data.source];
          break;
        case "mediaGroup":
          flowQuery.mediaGroups = [params.data.source];
          break;
      }
      switch (params.data.targetType) {
        case "media":
          flowQuery.media = [params.data.target];
          break;
        case "orgGroup":
          flowQuery.orgGroups = [params.data.target];
          break;
        case "mediaGroup":
          flowQuery.mediaGroups = [params.data.target];
          break;
      }
      dispatch(setQuery({ ...query, ...flowQuery }));
    } else {
      if (
        params.data.name.startsWith("Andere") ||
        params.data.name.startsWith("Other")
      )
        return;
      if (params.data.type === "org")
        dispatch(
          setQuery({
            ...query,
            organisations: [params.data.name],
            media: [],
            orgGroups: [],
            mediaGroups: [],
          })
        );
      else if (params.data.type === "media")
        dispatch(
          setQuery({
            ...query,
            media: [params.data.name],
            organisations: [],
            orgGroups: [],
            mediaGroups: [],
          })
        );
      else if (params.data.type === "orgGroup")
        dispatch(
          setQuery({
            ...query,
            orgGroups: [params.data.name],
            media: [],
            organisations: [],
            mediaGroups: [],
          })
        );
      else if (params.data.type === "mediaGroup")
        dispatch(
          setQuery({
            ...query,
            mediaGroups: [params.data.name],
            media: [],
            orgGroups: [],
            organisations: [],
          })
        );
    }
  };

  const onChartMouseMove = (params) => {
    const echartInstance = echartRef.getEchartsInstance();
    echartInstance.getZr().setCursorStyle("pointer");
  };

  const [activeTab, setActiveTab] = useState(tab ?? "flows");
  const [updateRoute, setUpdateRoute] = useState(true);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    if (newTab === "ads") dispatch(setAdsSeen(true));
  };

  useEffect(() => {
    if (tab !== undefined && tab !== "undefined") {
      setActiveTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    setTab(activeTab, updateRoute);
  }, [activeTab, updateRoute]);

  const onEvents = {
    click: onChartClick,
    mousemove: onChartMouseMove,
  };

  const touchDoubleClickChecker = useTouchDoubleClickChecker();

  const [touchEchartsEvents] = useState({
    click: (event) => {
      touchDoubleClickChecker(
        () => onChartClick(event),
        () => { },
        250
      );
    },
    dblclick: onChartClick,
  });

  const Settings = () => {
    return (
      <div className="settings" data-test-id="flowTransferSettings">
        <Grid container spacing={3} data-test-id="flowSettings">
          <Grid item xs={12} lg={4} md={4}>
            <TransferMultiSelectComp
              value={query.organisations}
              searchNames={searchNames}
              orgType="org"
              placeholder={t("Select a Payer")}
              label={t("Payers")}
              aria-label={t("Payers")}
              onChange={(e) => {
                updateSelection("org")(e);
              }}
            />
            <Render when={groupTypes && groupTypes.length > 0}>
              <GroupTypeSelector
                id="select-group-type-org-transfer-flow"
                value={query.orgGroupType}
                availableGroupTypes={groupTypes}
                type="org"
                onChange={(e) => {
                  dispatch(setOrgGroupType(e.target.value));
                }}
                aria-label={t("Payers") + " selector"}
              />
            </Render>
            <Render
              when={
                query.orgGroupType != undefined && query.orgGroupType.length > 0
              }
            >
              <MultiSelectComp
                options={groups
                  .filter(
                    (g) =>
                      g.type === "org" && g.group_type === query.orgGroupType
                  )
                  .map((g) => g.name)
                  .sort((a, b) => a.localeCompare(b))}
                value={query.orgGroups as string[]}
                placeholder={t("Select a Group")}
                label={t("Select payer groups")}
                onChange={(newValue) => updateSelection("orgGroups")(newValue)}
              />
            </Render>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <div className="col-center-element">
              <Render when={!isMobileLandscape}>
                <ToggleSwitch
                  values={toggleStates}
                  selected={switchState}
                  onChange={(pT) =>
                    dispatch(
                      setQuery({ ...query, pType: pT === 0 ? [2, 4] : pT })
                    )
                  }
                />

                <SwitchButton
                  simple={true}
                  selValue={!query.showAbsoluteValues ?? false}
                  onlabel={
                    <span>
                      {t("Logarithmic scale")} <Help text={t("scale_help")} />
                    </span>
                  }
                  offlabel={
                    <span>
                      {t("Logarithmic scale")} <Help text={t("scale_help")} />
                    </span>
                  }
                  onIconD={Config.switchButton.icons.numbersIconD}
                  offIconD={Config.switchButton.icons.percentIconD}
                  onChange={(checked: boolean) => {
                    dispatch(
                      setShowAbsoluteValues({
                        showAbsoluteValues: !checked,
                        summariseData: query.summariseValues ?? false,
                      })
                    );
                    //dispatch(setNeedsUpdate());
                  }}
                ></SwitchButton>
                <SwitchButton
                  simple={true}
                  selValue={query.summariseValues ?? false}
                  onlabel={
                    <span>
                      {t("summarise_values")}{" "}
                      <Help
                        translatedText={
                          Config.flows.summarise.minAmountOfFlows
                            ? t("summarise_values_percent_minAmount", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                              minAmount:
                                Config.flows.summarise.minAmountOfFlows.toString(),
                            })
                            : t("summarise_values_percent", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                            })
                        }
                      />
                    </span>
                  }
                  offlabel={
                    <span>
                      {t("summarise_values")}{" "}
                      <Help
                        translatedText={
                          Config.flows.summarise.minAmountOfFlows
                            ? t("summarise_values_percent_minAmount", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                              minAmount:
                                Config.flows.summarise.minAmountOfFlows.toString(),
                            })
                            : t("summarise_values_percent", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                            })
                        }
                      />
                    </span>
                  }
                  onChange={(checked: boolean) => {
                    dispatch(setSummariseValues(checked));
                    dispatch(
                      setData({
                        data: data,
                        showAbsoluteValues: query.showAbsoluteValues ?? true,
                        summariseData: checked,
                      })
                    );
                  }}
                ></SwitchButton>
              </Render>
            </div>
          </Grid>

          <Grid item xs={12} lg={4} md={4}>
            <Render when={isMobileLandscape}>
              <ToggleSwitch
                values={toggleStates}
                selected={switchState}
                onChange={(pT) =>
                  dispatch(
                    setQuery({ ...query, pType: pT === 0 ? [2, 4] : pT })
                  )
                }
              />
              <SwitchButton
                simple={true}
                selValue={query.showAbsoluteValues ?? true}
                onlabel={t("Absolute visualisation")}
                offlabel={t("Relative visualisation")}
                onIconD={Config.switchButton.icons.numbersIconD}
                offIconD={Config.switchButton.icons.percentIconD}
                onChange={(checked: boolean) => {
                  dispatch(
                    setShowAbsoluteValues({
                      showAbsoluteValues: checked,
                      summariseData: query.summariseValues ?? false,
                    })
                  );
                  //dispatch(setNeedsUpdate());
                }}
              ></SwitchButton>
            </Render>
            <TransferMultiSelectComp
              value={query.media}
              orgType="media"
              placeholder={t("Select a Beneficiary")}
              label={t("Beneficiaries")}
              searchNames={searchNames}
              onChange={(e) => {
                updateSelection("media")(e);
              }}
            />
            <Render when={groupTypes && groupTypes.length > 0}>
              <GroupTypeSelector
                id="select-group-type-transfer-flow"
                value={query.mediaGroupType}
                availableGroupTypes={groupTypes}
                type="media"
                onChange={(e) => {
                  dispatch(setMediaGroupType(e.target.value));
                }}
              />
            </Render>
            <Render
              when={
                query.mediaGroupType != undefined &&
                query.mediaGroupType.length > 0
              }
            >
              <MultiSelectComp
                options={groups
                  .filter(
                    (g) =>
                      g.type === "media" &&
                      g.group_type === query.mediaGroupType
                  )
                  .map((g) => g.name)
                  .sort((a, b) => a.localeCompare(b))}
                value={query.mediaGroups as string[]}
                placeholder={t("Select a Group")}
                label={t("Select beneficiary groups")}
                onChange={(newValue) =>
                  updateSelection("mediaGroups")(newValue)
                }
              />
            </Render>
          </Grid>
          <Grid item xs={12}>
            <PeriodSlider
              label={t("Period")}
              startPeriod={query.from ?? 0}
              endPeriod={query.to ?? 0}
              step={1}
              onChange={(start, end) =>
                dispatch(setRange({ from: start, to: end }))
              }
              labelFormater={periodToString}
              periods={periods}
              marksFilter={(period) => period.startsWith("Q1")}
            ></PeriodSlider>

            <AmountSlider
              label={t("Payment Range")}
              availableAmounts={amountRange}
              value={query.amountFilterRange ?? [0, 0]}
              onChange={(range) => dispatch(setAmountFilterRange(range))}
            />
          </Grid>
        </Grid>
      </div>
    );
  };
  // const mode = ((query.media?.length > 0) && (query.organisations?.length > 0)) ? 'both' : query.media?.length > 0 ? 'media' : 'org'
  return (
    <>
      <ShowError error={error} onClose={() => setError("")} />
      <ModalLoader isPending={pending} />
      <div className={isMobile ? "flows-mobile-settings" : "flows-settings"}>
        <SettingsViewer id="transfer-flows" fold={true} open={!isMobile}>
          <Settings />
        </SettingsViewer>
      </div>
      <Render when={data?.length === 0}>
        <Alert severity="warning">
          {t("No Data found that matches your settings")}
        </Alert>
      </Render>
      <TabsMui
        value={activeTab}
        onChange={handleTabChange}
        orientation={isMobilePortrait ? "vertical" : "horizontal"}
        centered
        sx={{ marginY: 2 }}
      >
        <TabMui
          label={
            <span>
              <FontAwesomeIcon icon={faStream} /> {t("Flows")}
            </span>
          }
          value="flows"
        />
        <TabMui
          value="timeline"
          label={
            <span>
              <FontAwesomeIcon icon={faChartLine} /> {t("Timeline")}
            </span>
          }
        />
        {settings.isModuleEnabled(Modules.Sujets) && (
          <TabMui
            value="ads"
            label={
              <span>
                <FontAwesomeIcon icon={faListUl} />
                <Render when={adsSeen || activeTab === "ads"}>
                  {t("sujets")}
                </Render>
                <Render when={!adsSeen && activeTab !== "ads"}>
                  <Badge badgeContent={adsCount}>
                    <span style={{ marginRight: ".5em" }}>{t("sujets")}</span>
                  </Badge>
                </Render>
              </span>
            }
          />
        )}
      </TabsMui>

      <Render when={activeTab === "flows" && data?.length > 0 && !error}>
        <Box sx={{ marginY: 3 }}>
          <div className="text-end info">
            <FontAwesomeIcon icon={faInfoCircle} />{" "}
            {t(
              `${isTouchSupported() ? "Double click" : "Click"
              } on the chart to get more information`
            )}
            <Render when={settings.isModuleEnabled(Modules.Sujets)}>
              <br /> <FontAwesomeIcon icon={faInfoCircle} />{" "}
              {t(`ad_legend_sankey`)}
            </Render>
          </div>
        </Box>

        {/* <div className="text-end info"></div> */}
        <ResponsiveContainer
          width="100%"
          height={
            chartModel.links.length * 20 < minHeight
              ? minHeight
              : chartModel.links.length * 20
          }
        >
          <ReactECharts
            ref={(e) => {
              echartRef = e;
            }}
            option={sankeyOptions}
            opts={{ locale: i18n.language.split("-")[0] }}
            style={{
              height: "100%",
              width: "100%",
              cursor: "pointer !important",
            }}
            onEvents={isTouchSupported() ? touchEchartsEvents : onEvents}
          />
        </ResponsiveContainer>
        <Fold
          id={`flows-transfer-table`}
          header={t("Raw Data")}
          fold={true}
          open={false}
          icon={<></>}
        >
          <DataTable
            title={t("flows_transfer_title", {
              paymentType: t(
                query.pType == 2
                  ? "advertising_payments"
                  : query.pType == 4
                    ? "funding_payments"
                    : "advertising_and_funding_payments"
              ),
              period:
                query.to !== query.from
                  ? `${periodToString(query.from ?? 0)} - ${periodToString(
                    query.to ?? 0
                  )}`
                  : periodToString(query.to ?? 0),
            })}
            pagination={true}
            columns={columns}
            data={filteredData}
            actions={
              <ExportToExcel
                data={filteredData}
                columns={columns}
                fileName="MoneyFlow"
              />
            }
          />
        </Fold>
      </Render>
      <Render when={activeTab === "timeline" && timeline?.length > 0 && !error}>
        <div className="text-end info">
          <FontAwesomeIcon icon={faInfoCircle} />{" "}
          {t(
            `${isTouchSupported() ? "Double click" : "Click"
            } on the chart to get more information`
          )}
        </div>
        <ReactECharts
          option={timelineOptions}
          onEvents={{ click: handleClick }}
          style={{ height: 450 }}
          opts={{ locale: i18n.language.split("-")[0] }}
        />
        <Fold
          id={`flows-transfer-timeline-table`}
          header={t("Raw Data")}
          fold={true}
          open={false}
          icon={<></>}
        >
          <DataTable
            title={t("flows_timeline_title", {
              paymentType: t(
                query.pType == 2
                  ? "advertising_payments"
                  : query.pType == 4
                    ? "funding_payments"
                    : "advertising_and_funding_payments"
              ),
            })}
            pagination={true}
            columns={timelineColums}
            data={timeline}
            actions={
              <ExportToExcel
                data={timeline}
                columns={timelineColums}
                fileName="MoneyFlow"
              />
            }
          />
        </Fold>
      </Render>
      <Render when={!error && settings.isModuleEnabled(Modules.Sujets)}>
        <div
          className="ads-transfer-container"
          style={{
            display: activeTab === "ads" ? "block" : "none",
          }}
        >
          <Ads
            org={query?.organisations}
            media={query?.media}
            from={query?.from?.toString()}
            to={query?.to?.toString()}
            orgGroupType={query?.orgGroupType}
            orgGroups={query?.orgGroups}
            mediaGroupType={query?.mediaGroupType}
            mediaGroups={query?.mediaGroups}
          />
        </div>
      </Render>
    </>
  );
};
