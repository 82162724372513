import Config from "../config/settings";
import { IFlowsFundingQuery, TYearlyFundingInfo } from "../pages/flows/flows-funding";

import { getFlowFunding } from "../services/data-service";

import {
  getOtherFundingTypesName,
  getOtherReceiversForRec,
  getOtherReceiversName,
} from "./flows-mixed.helper";
import { getColorFromPaymentDetailType } from "./helpers";

export type TFundingFlowsRecord = {
  amount: number;
  fundingType: string; // = payer
  receiver: string;
  receiverGroups?: string;
  showFundingBasis?: boolean;
  fundingBasis?: string;
};

export type TFundingFlowsResult = TFundingFlowsRecord[];


export type TFundingFlowsResponse = {
  flows: TFundingFlowsResult;
  timeline: TTimelineFundingResult;
}

export type TTimelineFundingResult = {
  year: number;
  yearsData: TYearlyFundingInfo[];
}[];



export const getFundingFlows = async (
  query: IFlowsFundingQuery,
  otherReceiverFundingEnabled: boolean
) => {
  const { flows, timeline } = await
    getFlowFunding(query, otherReceiverFundingEnabled)


  let fundings: TFundingFlowsResult = flows.map((item) => {
    return {
      amount: item.amount,
      receiver:
        item.receiver.toLocaleLowerCase() === "other receivers"
          ? getOtherReceiversName()
          : item.receiver,
      receiverGroups: item.receiverGroups,
      fundingType:
        item.fundingType.toLocaleLowerCase() === "other fundingtypes"
          ? getOtherFundingTypesName()
          : item.fundingType,
      paymentDetailType: item.fundingType,
      fundingBasis: item.fundingBasis,
      showFundingBasis: query.showFundingBasis ?? false,
    } as TFundingFlowsRecord;
  });

  return { flows: fundings, timeline };
};

type TSankeyLinkModel = {
  source: string;
  target: string;
  value: number;
  normalValue: number;
  lineStyle?: {
    color?: string;
    opacity?: number;
  };
};

export const getFundingFlowsLinks = (
  result: TFundingFlowsResult,
  fundingBaseColors: Map<string, string>,
  showAbsoluteVisualisation: boolean,
  summariseValues: boolean,
  uniqueData: any
) => {
  let linkModel: TSankeyLinkModel[] = [];

  // to calculate percentage in tooltip the sums are saved
  const payerSumMap: Map<string, number> = new Map();
  const receiverSumGroupMap: Map<string, number> = new Map();
  const receiverSumMap: Map<string, number> = new Map();
  const fundingBaseSumMap: Map<string, number> = new Map();

  // key = fundingType+|+fundingBasis+|+receiverGroups
  const receiverGroupMap: Map<string, number> = new Map();

  // key = fundingType+|+receiverGroups+|+receiver
  const receiverMap: Map<string, number> = new Map();

  // key = fundingType+|+fundindBase
  const fundingBaseMap: Map<string, number> = new Map();

  for (const item of [...(result.filter((item) => item.amount))].sort((a, b) => b.amount - a.amount)) {
    if (item.receiverGroups) {
      const receiverGroupKey =
        item.fundingType +
        "|" +
        (item.showFundingBasis ? item.fundingBasis + "|" : "|") +
        item.receiverGroups;
      if (receiverGroupMap.has(receiverGroupKey))
        receiverGroupMap.set(
          receiverGroupKey,
          ((receiverGroupMap.get(receiverGroupKey)
            ? receiverGroupMap.get(receiverGroupKey)
            : 0) as number) + item.amount
        );
      else receiverGroupMap.set(receiverGroupKey, item.amount);

      if (receiverSumGroupMap.has(item.receiverGroups)) {
        receiverSumGroupMap.set(
          item.receiverGroups,
          (receiverSumGroupMap.get(item.receiverGroups) as number) + item.amount
        );
      } else {
        receiverSumGroupMap.set(item.receiverGroups, item.amount);
      }
    }
    const receiverKey =
      item.fundingType +
      "|" +
      (item.receiverGroups ? item.receiverGroups : "") +
      "|" +
      (item.showFundingBasis && item.fundingBasis ? item.fundingBasis : "") +
      "|" +
      item.receiver;
    if (receiverMap.has(receiverKey))
      receiverMap.set(
        receiverKey,
        ((receiverMap.get(receiverKey)
          ? receiverMap.get(receiverKey)
          : 0) as number) + item.amount
      );
    else receiverMap.set(receiverKey, item.amount);

    if (receiverSumMap.has(item.receiver))
      receiverSumMap.set(item.receiver, (receiverSumMap.get(item.receiver) as number) + item.amount);
    else receiverSumMap.set(item.receiver, item.amount);

    if (payerSumMap.has(item.fundingType)) {
      payerSumMap.set(
        item.fundingType,
        (payerSumMap.get(item.fundingType) as number) + item.amount
      );
    } else {
      payerSumMap.set(item.fundingType, item.amount);
    }

    if (item.showFundingBasis && item.fundingBasis) {
      if (fundingBaseSumMap.has(item.fundingBasis))
        fundingBaseSumMap.set(item.fundingBasis, (fundingBaseSumMap.get(item.fundingBasis) as number) + item.amount);
      else fundingBaseSumMap.set(item.fundingBasis, item.amount);

      const fundingKey = item.fundingType + "|" + item.fundingBasis;
      if (fundingBaseMap.has(fundingKey))
        fundingBaseMap.set(
          fundingKey,
          ((fundingBaseMap.get(fundingKey)
            ? fundingBaseMap.get(fundingKey)
            : 0) as number) + item.amount
        );
      else fundingBaseMap.set(fundingKey, item.amount);
    }
  }

  for (const key of Array.from(fundingBaseMap.keys())) {
    const paymentDetailType = key.split("|")[0];
    const fundingBase = key.split("|")[1];
    linkModel.push({
      source: paymentDetailType,
      target: fundingBase,
      value: showAbsoluteVisualisation ? fundingBaseMap.get(key) as number : Math.log10(fundingBaseMap.get(key) as number),
      normalValue: fundingBaseMap.get(key) as number,
      lineStyle: {
        color: getColorFromPaymentDetailType(paymentDetailType),
        opacity: 0.3
      },
    });
  }

  for (const key of Array.from(receiverMap.keys())) {
    const paymentDetailType = key.split("|")[0];
    const receiverGroup = key.split("|")[1];
    const fundingBase = key.split("|")[2];
    const receiverMedia = key.split("|")[3];
    let color = getColorFromPaymentDetailType(paymentDetailType);
    if (fundingBase && fundingBaseColors.has(fundingBase)) {
      color = fundingBaseColors.get(fundingBase) as string;
    }
    const source = receiverGroup ? receiverGroup : fundingBase ? fundingBase : paymentDetailType;
    const payerSum = payerSumMap.has(source) ? payerSumMap.get(source) as number :
      receiverGroupMap.has(source) ? receiverGroupMap.get(source) as number :
        fundingBaseMap.has(source) ? fundingBaseMap.get(source) as number : 0;

    let normalValue = receiverMap.get(key) as number;
    let target = receiverMedia;
    if (summariseValues) {
      const isUnderThresold = (normalValue / payerSum) < Config.flows.summarise.percentThreshold;
      const linkModelIndexOfOthers = linkModel.findIndex(l => l.target === getOtherReceiversForRec(source)
        && l.source === source);
      if (isUnderThresold && (Config.flows.summarise.minAmountOfFlows <= linkModel.filter(l => l.source === source).length)) {
        if (linkModelIndexOfOthers > -1) {
          linkModel[linkModelIndexOfOthers].normalValue += normalValue;
        } else {
          linkModel.push({ source: source, target: getOtherReceiversForRec(source), value: normalValue, normalValue: normalValue, lineStyle: { color: color, opacity: 0.1 } })
        }
      } else {
        linkModel.push({
          source: source,
          target: target,
          value: showAbsoluteVisualisation ? receiverMap.get(key) as number : Math.log10(receiverMap.get(key) as number),
          normalValue: normalValue,
          lineStyle: {
            color: color,
            opacity: 0.3
          },
        });
      }
    } else {
      linkModel.push({
        source: source,
        target: target,
        value: showAbsoluteVisualisation ? receiverMap.get(key) as number : Math.log10(receiverMap.get(key) as number),
        normalValue: normalValue,
        lineStyle: {
          color: color,
          opacity: 0.3
        },
      });
    }
  }

  if(summariseValues) {
    const othersReceiver = linkModel.map((l, idx) => {
      return { index: idx, link: l};
    }).filter(l => l.link.target.startsWith(getOtherReceiversName()));
    if (othersReceiver.length > 0) {
      for(const link of othersReceiver) {	
        linkModel[link.index].value = showAbsoluteVisualisation ? linkModel[link.index].normalValue : Math.log10(linkModel[link.index].normalValue);
  
        receiverSumMap.set(link.link.target, linkModel[link.index].normalValue);
        uniqueData.push({
          name: link.link.target,
          label: { position: "insideRight", offset: [-20, 0] }
        });
      }
    }
    uniqueData = uniqueData.filter(d => linkModel.findIndex(l => l.target === d.name || l.source === d.name) > -1);
  }

  for (const key of Array.from(receiverGroupMap.keys())) {
    const paymentDetailType = key.split("|")[0];
    const fundingBase = key.split("|")[1];
    const receiverGroup = key.split("|")[2];
    let color = getColorFromPaymentDetailType(paymentDetailType);
    if (fundingBase && fundingBaseColors.has(fundingBase)) {
      color = fundingBaseColors.get(fundingBase) as string;
    }
    linkModel.push({
      source: fundingBase ? fundingBase : paymentDetailType,
      target: receiverGroup,
      value: showAbsoluteVisualisation ? receiverGroupMap.get(key) as number : Math.log10(receiverGroupMap.get(key) as number),
      normalValue: receiverGroupMap.get(key) as number,
      lineStyle: {
        color: color,
        opacity: 0.3
      },
    });
  }

  return { uniqueData, linkModel, payerSumMap, receiverSumGroupMap, receiverSumMap, fundingBaseSumMap };
};

export const addElementIfNotExist = <T>(oldList: T[], value: T): T[] => {
  return oldList.indexOf(value) ? [...oldList, value] : [...oldList];
};
