import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export type PopupProps = {
  show: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  title: JSX.Element;
  description: JSX.Element;
  footer?: JSX.Element;
};

export const Popup: React.FC<PopupProps> = ({
  show,
  setShow,
  title,
  description,
  footer,
}) => {
  const { t } = useTranslation();
  const style = {
    alignItems: "center",
    justifyContent: "center",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    border: "1px solid #444",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={show}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {description}
        </Typography>
        {footer
          ? footer
          : setShow && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setShow(false)}
              >
                {t("close")}
              </Button>
            )}
      </Box>
    </Modal>
  );
};
