import React from "react";
import { useTranslation } from "react-i18next";
import "./impress.css";
import { Card, CardContent, CardHeader } from "@mui/material";

export const Impress = () => {
  const { t } = useTranslation();
  return (
    <Card className="top">
      <CardHeader title={t("Impress")} />
      <CardContent>
        <h3>{t("Media Owner")}</h3>
        <address>
          <strong>FH JOANNEUM Gesellschaft mbH</strong>
          <br />
          Alte Poststraße 149
          <br />
          A-8020 Graz
          <br />
          Tel.: +43 (0)316 5453-0
          <br />
          E-Mail: medientransparenz@fh-joanneum.at
          <br />
        </address>
        <a href="https://fh-joanneum.at/hochschule/organisation/impressum/">
          Details
        </a>
        <h4>{t("Contact Person")}</h4>
        <p>
          FH-Prof. DI Dr. Peter Salhofer
          <br />
          Studiengang Wirtschaftsinformatik
        </p>
        <h4>{t("How this web-site came into being")}</h4>
        <p>{t("impress_1")}</p>
        <ul>
          <li>Amir Basyouni</li>
          <li>Mercedes Stibler</li>
          <li>Stephan Vrecer</li>
        </ul>
        <p>{t("impress_2")}</p>
        <p>{t("impress_3")}</p>
        <ul>
          <li>DI Amir Basyouni, BSc</li>
          <li>DI Gerald Weidinger, BSc</li>
        </ul>
        <p>{t("impress_4")}</p>
        <p>{t("impress_7")}</p>
        <p>{t("impress_8")}</p>
        <ul>
          <li>Peter Feldgrill, BSc</li>
          <li>Edith Hörtnagel, BSc</li>
          <li>Georg Praßl, BSc</li>
          <li>Marcel Puchas, BSc</li>
        </ul>
        <p>{t("impress_9")}</p>
        <p>{t("impress_10")}</p>
        <p>{t("impress_11")}</p>
        <ul>
          <li>DI Marcel Puchas</li>
          <li>Peter Feldgrill, BSc</li>
        </ul>
        <p>{t("impress_12")}</p>
        <p>{t("impress_13")}</p>
        <ul>
          <li>Verena Brettschuh, BSc</li>
          <li>Christopher Rolke, BSc</li>
          <li>Ramon Tippl, BSc</li>
          <li>Martin Wechtitsch, BSc</li>
        </ul>
        <h4>{t("Source of Data")}</h4>
        <p>{t("impress_5")}</p>
        <h4>{t("Exclusion of Liability")}</h4>
        <p>{t("impress_6")}</p>
      </CardContent>
    </Card>
  );
};
