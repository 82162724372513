import config from './server-config';
import axios from 'axios';
import { IStoredUser, IUser } from '../../../server/models/types';
import { createAuthenticationHeader } from './security';


const endpoint = axios.create({
    baseURL: config.usersURL,
    responseType: 'json'
});

export const getUsers = () =>
    endpoint.get<Array<IStoredUser>>('/', { headers: createAuthenticationHeader()} )
        .then(({data}) => data)

export const getUser = (id: string) =>
    endpoint.get<IStoredUser>(`/${id}`, { headers: createAuthenticationHeader()} )
        .then(({data}) => data)

export const updateUser = (user:IStoredUser) =>
    endpoint.put<IUser,IUser>(`/${user._id}`, user, { headers: createAuthenticationHeader()} )
   

