import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IfNoError } from "../components/helper-components";
import { BuildForm } from "../components/form-builder/mui-element-factory";
import { faArrowCircleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FormDescription } from "../components/form-builder/field-types";
import { useDispatch } from "react-redux";
import { setNeedsUpdate } from "./list-users";
import { createOrganisation } from "../services/data-service";
import { IOrganisation } from "../../../server/models/organisations";

export const orgTypes = [
  "Undetermined",
  "Company",
  "Association",
  "Chamber",
  "Education",
  "Foundation",
  "Municipality",
  "Fund",
  "Policy-relevant",
  "Ministry",
  "City",
  "Federal state",
  "Agency",
  "Museum",
];

export const NewOrganisation: React.FC = () => {
  // const [organisation, setOrganisation] = useState<IOrganisationDocument | undefined>(undefined); // eslint-disable-line no-unused-vars
  const [error, setError] = useState(""); // eslint-disable-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const newOrgFormDescription = useMemo<FormDescription<IOrganisation>>(
    () => ({
      fields: [
        {
          name: "name",
          type: "text",
          label: t("Name"),
          validators: { required: "required" },
        },
        {
          name: "type",
          type: "select",
          label: t("Type"),
          help: t("Select the type of this organisation"),
          options: orgTypes,
          disabled: false,
        },
        {
          name: "street",
          type: "text",
          label: t("Street"),
          validators: { required: "required" },
        },
        {
          name: "zipCode",
          type: "text",
          label: t("Zip Code"),
          validators: { required: "required" },
        },
        {
          name: "city_de",
          type: "text",
          label: t("City"),
          validators: { required: "required" },
        },
        {
          name: "federalState",
          type: "select",
          label: t("Federal State"),
          options: [
            "EU",
            "AT",
            "AT-1",
            "AT-2",
            "AT-3",
            "AT-4",
            "AT-5",
            "AT-6",
            "AT-7",
            "AT-8",
            "AT-9",
          ],
          labels: [
            t("EU"),
            t("AT"),
            t("AT-1"),
            t("AT-2"),
            t("AT-3"),
            t("AT-4"),
            t("AT-5"),
            t("AT-6"),
            t("AT-7"),
            t("AT-8"),
            t("AT-9"),
          ],
          disabled: false,
          validators: { required: "required" },
        },
        {
          name: "country_de",
          type: "text",
          label: t("Country"),
          validators: { required: "required" },
        },
      ],
      name: t("New Organisation"),
      submitHandler: (organisation_) =>
        createOrganisation(organisation_)
          .then(() => dispatch(setNeedsUpdate()))
          .catch((err) => {
            throw new Error(err.response?.data?.message?.message || err);
          })
          .catch((err) => setError(err)),
      submitAction: {
        label: t("Create Organisation"),
        icon: faSave,
        nextRoute: "/organisation/list",
      },
      cancelAction: {
        label: t("Cancel"),
        icon: faArrowCircleLeft,
        nextRoute: "back",
      },
    }),
    [dispatch, t]
  );
  return (
    <IfNoError error={t(error)}>
      <div className="flex-row-center">
        <div className="col-lg-4 col-md-6 col-sm-9 col-xs-12">
          <BuildForm {...newOrgFormDescription}></BuildForm>
        </div>
      </div>
      <pre>{/*JSON.stringify(group,null,2)*/}</pre>
    </IfNoError>
  );
};
