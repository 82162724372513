import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IfNoError } from "../components/helper-components";
import DataTable, { TableColumn } from "react-data-table-component-with-filter";

import { FormDescription } from "../components/form-builder/field-types";
import { BuildForm } from "../components/form-builder/mui-element-factory";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { GroupType } from "../models/models";
import {
  addGroupType,
  deleteGroupType,
  getGroupTypeList,
  updateGroupType,
} from "../services/data-service";

import { IGroupTypeDocument } from "../../../server/models/group-type";
import { ObjectId } from "mongoose";
import { GroupInUseError } from "../components/GroupInUseError";
import {ToggleSwitch} from "../components/three-state/three-state-switch";
import { Button, ButtonGroup, Card, CardContent, CardHeader, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';

export const AdminGroupTypes: React.FC = (props) => {
  const [error] = useState("");
  const { t } = useTranslation();
  const [editableRow, setEditableRow] = useState<ObjectId | undefined>(
    undefined
  );
  const [show, setShow] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);

  const [groupTypes, setGroupTypes] = useState<IGroupTypeDocument[]>([]);
  useEffect(() => {
    reloadGroupTypes();
  }, []);

  function reloadGroupTypes() {
    getGroupTypeList().then((res) => setGroupTypes(res));
  }

  const columns = useMemo<TableColumn<IGroupTypeDocument>[]>(() => {
    function updateGroupTypeName(row, newVal: HTMLInputElement | null) {
      if (newVal?.value) {
        updateGroupType({ ...row, name: newVal.value }).then(() => {
          reloadGroupTypes();
        });
      }
    }

    function editRow(row, closeRowAndUpdate = false) {
      if (closeRowAndUpdate) {
        setEditableRow(undefined);
        updateGroupTypeName(row, nameInputRef.current);
      } else {
        setEditableRow(row._id);
      }
    }

    function toggleActive(row) {
      setShow(false);
      updateGroupType({...row, isActive: !row.isActive})
        .then(() => {
          reloadGroupTypes();
        })
        .catch((err) => {
          console.error(err);
          reloadGroupTypes();
          setShow(true);
        });
    }

    function switchPB(row, type) {
      updateGroupType({ ...row, type })
        .then(() => {
          reloadGroupTypes();
        })
        .catch(() => {
          reloadGroupTypes();
        });
    }

    function removeRow(row) {
      deleteGroupType(row)
        .then(() => reloadGroupTypes())
        .catch(() => {
          reloadGroupTypes();
          setShow(true);
        });
    }

    return [
      {
        name: t("Name"),
        selector: (row) => row.name,
        cell: (row) =>
          editableRow !== row._id ? (
            <span>{`${row.name} (${t(row.name)})`}</span>
          ) : (
            <input
              defaultValue={row.name}
              type="text"
              className="form-control"
              ref={nameInputRef}
            />
          ),
        filterable: true,
        sortable: true,
        width: "45%",
        allowOverflow: false,
        wrap: true,
      },
      {
        name: `${t("Payer")}/${t("All")}/${t("Beneficiary")}`,
        cell: (row) => (
          <ToggleSwitch
            selected={row.type}
            values={[{value: "P", label: t('P')}, {value: "A", label: t('A')}, {value: "B", label: t('B')}]}
            onChange={(type) => switchPB(row, type)}
          />
        ),
        width: "30%",
      },
      {
        name: t("Active"),
        cell: (row) => (
          <FormGroup>
            <FormControlLabel control={
              <Switch checked={row.isActive} 
                      color="primary" 
                      onChange={(enabled) => toggleActive(row)}
                      />} label="Label" />
          </FormGroup>
          // <BootstrapSwitchButton
          //   checked={row.isActive}
          //   width={120}
          //   onstyle="primary"
          //   offstyle="warning"
            
          // />
        ),
        width: "10%",
      },
      {
        name: t("Actions"),
        cell: (row) => (
          <ButtonGroup>
            {editableRow !== row._id ? (
              <Button color="warning" variant="contained" onClick={() => editRow(row)}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            ) : (
              <Button color="warning" variant="contained" onClick={() => editRow(row, true)} >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            <Button color="error" variant="contained" onClick={() => removeRow(row)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </ButtonGroup>
        ),
        width: "15%",
      },
    ];
  }, [editableRow, t]);

  const submitHandler = (groupType: GroupType) =>
    addGroupType(groupType).then(() => reloadGroupTypes());

  const groupTypeFormDescription: FormDescription<GroupType> = {
    fields: [
      {
        name: "name",
        type: "text",
        label: t("Name"),
        validators: { required: "required" },
        help: t("Choose a name for the new Group Type."),
      },
    ],
    name: "",
    submitHandler: submitHandler,
    submitAction: { label: t("Add"), icon: faPlus, nextRoute: "/group-types" },
  };

  return (
    <>
      <IfNoError error={t(error)}>
        <Card>
          <CardHeader title={t("Group Types")}/>
          <CardContent>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-9 col-xs-12">
                <BuildForm {...groupTypeFormDescription}></BuildForm>
              </div>
            </div>
          </CardContent>
          <CardContent className='no-padding'>
            <div className="row mt-1">
              <div className="col-12">
                <div className="form-group">
                  <DataTable
                    columns={columns}
                    data={groupTypes}
                    pagination
                    striped
                    responsive={false}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </IfNoError>
      <GroupInUseError show={show} setShow={setShow} />
    </>
  );
};
