import {ModuleSettings} from "../../models/modules";

export interface FlowsMixedModuleSettings extends ModuleSettings {
  defaultReceivers: string[];
}

export const defaultFlowsMixedModuleSettings: FlowsMixedModuleSettings = {
  enabled: false,
  defaultReceivers: ['Der Standard', 'STANDARD Verlagsgesellschaft m.b.H.'],
}
