const palettes = require("nice-color-palettes");
const interpolate = require("color-interpolate");
const pallete = require("color-interpolate")(palettes[81]);

const palletePF = require("color-interpolate")(palettes[20]);

export const getColor = (idx: number, count: number, offset: number = 0.25) =>
  pallete(((idx + 1) / count) * (1 - 2 * offset) + offset);


export const getColorRTR = (idx: number, count: number, rtrColors: any[], offset: number = 0.00) => {
  const palleteRTR = interpolate(rtrColors)
  console.log(`Idx: ${idx} (${(idx + 1) / count}%), Count: ${count}, length of colors: ${rtrColors.length}`)
  return palleteRTR(((idx + 1) / count) * (1 - 2 * offset) + offset)
}



export const getPFColor = (idx: number, count: number, offset: number = 0.25) =>
  palletePF(((idx + 1) / count) * (1 - 2 * offset) + offset);
