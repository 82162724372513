const touchDoubleClickChecker = () => {
    let doubleClickTimeout;
    return (callbackDoubleClick, callbackSingleClick, doubleClickTimeoutDelay) => {
        if (!doubleClickTimeout) {
            doubleClickTimeout = setTimeout(() => {
                doubleClickTimeout = 0;
                callbackSingleClick();
            }, doubleClickTimeoutDelay);
        } else {
            clearTimeout(doubleClickTimeout);
            doubleClickTimeout = 0;
            callbackDoubleClick();
        }
    };
};

export default touchDoubleClickChecker;