import React, { createContext, useEffect } from 'react';
import { User, LoginInformation } from '../models/models';
import { login, clearUserData, loadUserData } from '../services/security';
import { useState } from 'react';
import jwt from 'jsonwebtoken';


export type TAuthContext = {
    user: User|null;
    signin: typeof login
    signout: typeof clearUserData
    isLoggedIn: ()=>boolean
    hasRole: (role:string)=>boolean
}

const initialUser:User = {
    username: 'NoName',
    email: '',
    password: '',
}

const initialValues: TAuthContext = {
    user: initialUser,
    signin: (data)=> Promise.resolve({user:initialUser, token:''}),
    isLoggedIn: ()=>false,
    hasRole: (role)=>false,
    signout: () => Promise.resolve([undefined,undefined])
}

export const AuthContext = createContext<TAuthContext>(initialValues);

const expiredDate = () => new Date(Date.now()-1000)

const getExpirationDate = (token:any) => {
    const dec = jwt.decode(token)
    return (dec && typeof dec === 'object') ? new Date((dec['exp'] || 1)*1000) : expiredDate()
}

export const AuthProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    useEffect(()=> {
        loadUserData()
        .then(({user: user_, token: token_}) =>  {
            console.log('Loaded data: ' + JSON.stringify(user_));
            setUser(user_);
            setToken(token_ ?? "")
            setExpirationDate(getExpirationDate(token_));
        }).catch((e:any) => console.log(e))},[]);
    const [user,setUser] = useState<User|null>(null);
    const [token,setToken] = useState<string>(""); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [expirationDate, setExpirationDate] = useState(expiredDate());
    const signin = (credentials:LoginInformation) => 
        login(credentials).then(({user: user_,token: token_})=>{
            setUser(user_);
            setToken(token_);
            setExpirationDate(getExpirationDate(token_));
            return {user: user_, token: token_}
        })
    const signout = () => {
        setUser(null);
        setToken("");
        setExpirationDate(expiredDate());
        return clearUserData()
    }
    const isLoggedIn = () => expirationDate > new Date();
    const hasRole = (role:string) => isLoggedIn() && (user?.roles?.includes(role) || false);
    return (
        <AuthContext.Provider value={{user, signin, signout, isLoggedIn, hasRole}}>
            {children}
        </AuthContext.Provider>    
    )
}
