import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import { TableColumn } from 'react-data-table-component-with-filter';




export type ExportToExcelProps<T> = {
    data: T[];
    fileName: string;
    columns?: TableColumn<T>[];
}

export const ExportToExcel = <T extends object>({ data, fileName, columns }: ExportToExcelProps<T>) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (tableData, fileName_) => {

      const data = columns ? tableData.map((item) => {
        let row = {} as any;
        columns.forEach((column) => {
          const colName = column.name?.toString() ?? 'name';
          const selector = column.selector ? column.selector : (v) => row[colName];
          row[colName ?? 'name'] = selector(item)
        });
        return row;
      }) : tableData;

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data_ = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data_, fileName_ + fileExtension);
    };

    return (
      <Button variant='contained'
        onClick={(e) => exportToCSV(data, fileName)}
        startIcon={<FontAwesomeIcon icon={faFileExcel} />}>Export</Button>
    );
  };
