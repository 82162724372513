import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FederalStateCode } from "../models/models";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../index";
import { search } from "../services/data-service";
import { IfNoError, ModalLoader } from "../components/helper-components";
import AccordionMui from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";

import "./search.css";
import { useNavigate } from "react-router-dom";

import { TInfoState } from "../App";

import { fundingTypesIndices } from "../helpers/helpers";
import Tooltip from "@mui/material/Tooltip";
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
} from "@mui/material";
import Config from "../config/settings";

type TSearchResultDetails = {
  transferYears: number[];
  fundingYears: number[];
  total: number;
  transferTypes: [2 | 4 | 31];
  name: string;
  organisationType?: string;
  federalState?: FederalStateCode;
};

export type TSearchResult = {
  org: TSearchResultDetails[];
  media: TSearchResultDetails[];
};

export type TSearchState = {
  query: { name: string };
  results: TSearchResult;
  needsUpdate: boolean;
  beneficiaryDetailsOpen: boolean;
  payerDetailsOpen: boolean;
};

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    query: { name: "" },
    results: {
      org: [],
      media: [],
    },
    needsUpdate: false,
    beneficiaryDetailsOpen: false,
    payerDetailsOpen: false,
  } as TSearchState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => ({
      ...state,
      query: { name: action.payload },
    }),
    setResult: (state, action: PayloadAction<TSearchResult>) => ({
      ...state,
      results: action.payload,
      needsUpdate: false,
    }),
    setNeedsUpdate: (state, action: PayloadAction<boolean>) => ({
      ...state,
      needsUpdate: action.payload,
    }),
    toggleBeneficiaryDetails: (state) => ({
      ...state,
      beneficiaryDetailsOpen: !state.beneficiaryDetailsOpen,
    }),
    togglePayerDetails: (state) => ({
      ...state,
      payerDetailsOpen: !state.payerDetailsOpen,
    }),
  },
});

const {
  setName,
  setResult,
  setNeedsUpdate,
  toggleBeneficiaryDetails,
  togglePayerDetails,
} = searchSlice.actions;

const MIN_LENGTH = 3;

let rtrStyleEnabled = false;
if (Config.skin === "rtr") {
  rtrStyleEnabled = true;
}

const chipStyle = {
  backgroundColor: rtrStyleEnabled ? "rgba(112,153,204,1)" : "",
  m: 0.2,
  fontSize: "60%",
  boxShadow:  !rtrStyleEnabled ? "3px 3px 4px 0px rgba(0,0,0,0.75)" : ""
};

export const Search = () => {

  const navigate = useNavigate();
  const { periods, periodsFunding } = useSelector<AppState, TInfoState>(
    (state) => state.info
  );
  const {
    query,
    results,
    needsUpdate,
    beneficiaryDetailsOpen,
    payerDetailsOpen,
  } = useSelector<AppState, TSearchState>((state) => state.search);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<string>("");
  const [pending, setPending] = useState<boolean>(false);

  const openFlow = (
    idx: number,
    oType: "media" | "org",
    type: "transfer" | "funding" = "transfer",
    event: any
  ) => {
    event.stopPropagation()
    if (type === "funding") {
      const to = periodsFunding[periodsFunding.length - 1];
      const from = periodsFunding[0];
      const entity = results[oType][idx];
      const fundingTypes = fundingTypesIndices()
        .map((i) => `fundingType=${i.toString()}`)
        .join("&");
      const queryString =
        `receiver=${encodeURIComponent(entity.name)}&` +
        `from=${from}&to=${to}&orgGroups=&mediaGroups=&${fundingTypes}`;
      navigate(`/flows/fundings/flows?${queryString}`);
      event.stopPropagation();
      return;
    }

    const entity = results[oType][idx];
    //let from = parseInt(entity.years[0].toString()+'1');
    //let to = parseInt(entity.years[entity.years.length-1].toString()+'4');
    //if (periods.length > 0 && to > periods[periods.length-1]) {
    const to = periods[periods.length - 1];
    //}
    //if (periods.length > 0 && from > periods[0]) {
    const from = periods[0];
    //}
    const pType = entity.transferTypes[0];
    const queryString =
      `${oType === "org" ? "organisations" : "media"}=${encodeURIComponent(
        entity.name
        // eslint-disable-next-line no-useless-concat
      )}&` + `from=${from}&to=${to}&pType=${pType}&orgGroups=&mediaGroups=`;
    navigate(`/flows/transfers/flows?${queryString}`);
    // dispatch(flowsActions.setQuery({
    //     organisations: oType === 'org' ? [entity.name] : [],
    //     media: oType === 'media' ? [entity.name] : [],
    //     pType,
    //     from,
    //     to,
    //     orgGroups: [],
    //     mediaGroups: []
    // }))
    //dispatch(flowsActions.setNeedsUpdate());
  };
  useEffect(() => {
    if (query.name.length >= MIN_LENGTH && needsUpdate) {
      setError("");
      setPending(true);
      search(query.name)
        .then((data) => ({
          org: [
            ...data.org.map((o) => ({
              ...o,
              transferYears: o.transferYears.sort((a, b) => a - b),
            })),
          ],
          media: [
            ...data.media.map((o) => ({
              ...o,
              transferYears: o.transferYears?.sort((a, b) => a - b),
              fundingYears: o.fundingYears?.sort((a, b) => a - b),
            })),
          ],
        }))
        .then((data) => dispatch(setResult(data)))
        .catch((err) => {
          setError(err?.response?.data ?? err.message);
        })
        .finally(() => setPending(false));
    } else {
      dispatch(setNeedsUpdate(false));
    }
  }, [needsUpdate, dispatch, query.name]);

  return (
    <>
      <ModalLoader isPending={pending} />
      <IfNoError error={error}>
        <Card className="card-search">
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                fullWidth
                sx={{
                  mt: "0 !important",
                }}
              >
                <TextField
                  variant="outlined"
                  type="search"
                  id="form1"
                  placeholder={t("Search")}
                  aria-label={t("Search")}
                  onChange={(e) => {
                    dispatch(setName(e.target.value));
                  }}
                  onKeyDown={(e) => {
                    console.log(e);
                    if (e.key === "Enter") {
                      dispatch(setNeedsUpdate(true));
                    }
                  }}
                  value={query.name}
                  sx={{
                    width: "100%",
                    mb: "15px",
                    mt: "0 !important",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type="button"
                          disabled={query.name.length < MIN_LENGTH}
                          onClick={() => dispatch(setNeedsUpdate(true))}
                          aria-label={t("Search") + " button"}
                        >
                          {!rtrStyleEnabled && <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Box>
            <pre>
              {/*JSON.stringify(query,null,2)}</pre>
            <pre>{JSON.stringify(needsUpdate,null,2)}</pre>
                <pre>{JSON.stringify(results,null,2)*/}
            </pre>
            <AccordionMui
              expanded={payerDetailsOpen || rtrStyleEnabled}
              onChange={() => dispatch(togglePayerDetails())}
            >
              <AccordionSummary
                expandIcon={!rtrStyleEnabled ? <ExpandMoreIcon /> : <></>}
                aria-controls="payer-results-content"
                id="panel-payer-results-header"
              >
                <Typography className="search-results-title">
                  {t("Hits for Payers")}&nbsp;
                  {rtrStyleEnabled ? (
                    <span className="search-results-number">{`${
                      results.org.length
                    } ${t("Hits")}`}</span>
                  ) : (
                    <Chip
                      size="small"
                      label={`${results.org.length} ${t("Hits")}`}
                      color={results.org.length > 0 ? "primary" : "error"}
                      sx={chipStyle}
                    />
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {results.org.map((r, idx) => (
                  <Box
                    onClick={(e) => openFlow(idx, "org", "transfer", e)}
                    className="result-line"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      {r.name}
                      {r.transferYears.map((y) => (
                        <Chip
                          size="small"
                          label={y}
                          color="primary"
                          sx={chipStyle}
                        />
                      ))}
                    </Box>
                    <div>
                      {r.total.toLocaleString(i18n.language, {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </Box>
                ))}
              </AccordionDetails>
            </AccordionMui>
            <AccordionMui
              data-test-id="beneficiariesHits"
              expanded={beneficiaryDetailsOpen || rtrStyleEnabled}
              onChange={() => dispatch(toggleBeneficiaryDetails())}
            >
              <AccordionSummary
                expandIcon={!rtrStyleEnabled ? <ExpandMoreIcon /> : <></>}
                aria-controls="beneficiaries-results-content"
                id="panel-beneficiary-results-header"
              >
                <Typography className="search-results-title">
                  {t("Hits for Beneficiaries")}&nbsp;
                  {rtrStyleEnabled ? (
                    <span className="search-results-number">{`${
                      results.media.length
                    } ${t("Hits")}`}</span>
                  ) : (
                    <Chip
                      size="small"
                      label={`${results.media.length} ${t("Hits")}`}
                      color={results.media.length > 0 ? "primary" : "error"}
                      sx={chipStyle}
                    />
                  )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                {results.media.map((r, idx) => (
                  <div
                    onClick={(e) =>
                      openFlow(
                        idx,
                        "media",
                        r.fundingYears ? "funding" : "transfer",
                        e
                      )
                    }
                    className="d-flex justify-content-between result-line"
                  >
                    <div>
                      {r.name}
                      {r.transferYears?.length > 0 ? (
                        <span
                          style={{ marginLeft: ".25em" }}
                          onClick={(e) => openFlow(idx, "media", "transfer", e)}
                        >
                          <Tooltip
                            id="tooltip"
                            placement="top"
                            title={r.transferYears.join(", ")}
                          >
                            <Chip
                              label={t("Media Transparency")}
                              color="primary"
                              size="small"
                              sx={chipStyle}
                            />
                          </Tooltip>
                        </span>
                      ) : (
                        <></>
                      )}
                      {r.fundingYears?.length > 0 ? (
                        <span
                          style={{ marginLeft: ".25em" }}
                          onClick={(e) => openFlow(idx, "media", "funding", e)}
                        >
                          <Tooltip
                            id="tooltip"
                            title={r.fundingYears.join(", ")}
                            placement="top"
                          >
                            <Chip
                              label={t("Fundings")}
                              color="primary"
                              size="small"
                              sx={chipStyle}
                            />
                          </Tooltip>
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      {r.total.toLocaleString(i18n.language, {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                  </div>
                ))}
              </AccordionDetails>
            </AccordionMui>
          </CardContent>
        </Card>
      </IfNoError>
    </>
  );
};
