import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {IDifference} from '../../../server/models/differences';
import {IDuplicate} from '../../../server/models/duplicates';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '..';
import {getDifferences, getDuplicates} from '../services/data-service';
import DataTable, {TableStyles} from 'react-data-table-component-with-filter';
import {ExportToExcel} from '../components/excel-exporter';
import i18n from '../i18n';
import {ShowError} from '../components/helper-components';
import {Card, CardContent, CardHeader} from '@mui/material';

const styles: TableStyles = {
    table: {
        style: {
            background: 'inherit'
        }
    },
    rows: {
        style: {
            background: 'inherit'
        }
    },
    headCells: {
        style: {
            fontWeight: 'bold'
        }
    },
    headRow: {
        style: {
            background: "#ccc"
        }
    },
    pagination: {
        style: {
            background: "#ccc"
        }
    },
    header: {
		style: {
            display: 'inherit',
			fontSize: '18px',
			backgroundColor: "#bbb",
			minHeight: '40px',
            paddingTop: '18px',
			paddingLeft: '16px',
			paddingRight: '8px',
            color: "#fff",
		},
	},
}
interface IAboutUsState {
    differences: IDifference[];
    duplicates: IDuplicate[];
    overview: {
        totalAmountRtr: number;
        totalAmountMta: number;
        totalCountRtr: number;
        totalCountMta: number;
    }
}
const calcOverview = (diffs: IDifference[]) =>
    diffs.reduce(({ totalAmountRtr, totalAmountMta, totalCountRtr, totalCountMta },
        { amountRtr, amountMta, countRtr, countMta }) => ({
            totalAmountRtr: totalAmountRtr + amountRtr,
            totalAmountMta: totalAmountMta + amountMta,
            totalCountRtr: totalCountRtr + countRtr,
            totalCountMta: totalCountMta + countMta
        }), {
        totalAmountRtr: 0,
        totalAmountMta: 0,
        totalCountRtr: 0,
        totalCountMta: 0
    })


export const aboutUsSlice = createSlice({
    name: 'aboutUs',
    initialState: {
        differences: [],
        duplicates: [],
        overview: {
            totalAmountRtr: 0,
            totalAmountMta: 0,
            totalCountRtr: 0,
            totalCountMta: 0
        }

    } as IAboutUsState,
    reducers: {
        setResults: (state, action: PayloadAction<{ diffs: IDifference[], dups: IDuplicate[] }>) => (
            {
                ...state, differences: action.payload.diffs,
                duplicates: action.payload.dups,
                overview: calcOverview(action.payload.diffs)
            }
        )
    }
})

export const { setResults } = aboutUsSlice.actions;

export const AboutUs = () => {
    const { t } = useTranslation();
    const [error, setError] = useState<string>("");
    const dispatch = useDispatch();
    const { differences, duplicates, overview } = useSelector<AppState, IAboutUsState>(state => state.aboutUs)
    useEffect(() => {
        Promise.all([getDifferences(), getDuplicates()])
            .then(([diffs, dups]) => dispatch(setResults({ diffs, dups })))
            .catch(err => err.message ? setError(err.message) : setError(err))
    }, [dispatch])
    const toPaymentTypeText = (field: string) => (row: any) => {
        switch (row[field]) {
            case 2: return t("Advertising")
            case 4: return t("Funding")
            default: return t("Fees")
        }
    }
    const diffColumns = useMemo(() => [
        {
            name: t("Period"),
            selector: row => row['period'],
            sortable: true,
            filterable: true,

        }, {
            name: t("Amount (RTR)"),
            selector: row => row["amountRtr"],
            format: toCurrency('amountRtr'),
            sortable: true

        }, {
            name: t("Amount (Here)"),
            selector: row => row["amountMta"],
            sortable: true,
            format: toCurrency('amountMta'),
        }, {
            name: t("Difference Amount"),
            selector: row => row["diffAmount"],
            sortable: true,
            format: toCurrency('diffAmount')
            // right: true
        }, {
            name: t("Entries (RTR)"),
            selector: row => row["countRtr"],
            sortable: true,
        }, {
            name: t("Entries (Here)"),
            selector: row => row["countMta"],
            sortable: true,
        }, {
            name: t("Differences Entries"),
            selector: row => row["diffCount"],
            sortable: true,
        }
    ], [t])

    const dupColumns = useMemo(() => [
        {
            name: t("Year"),
            selector: row => row['year'],
            sortable: true,
            filterable: true,
            width: "6em"
        },
        {
            name: t("Quarter"),
            selector: row => row['quarter'],
            sortable: true,
            filterable: true,
            width: "4em"
        },
        {
            name: t("Payer"),
            selector: row => row['organisation'],
            sortable: true,
            filterable: true
        }, {
            name: t("Beneficiary"),
            selector: row => row["media"],
            sortable: true,
            filterable: true
        }, {
            name: t("Payment type"),
            selector: row => row["transferType"],
            sortable: true,
            format: toPaymentTypeText('transferType'),
            width: "10em"
        },
        {
            name: t("Occurrence"),
            selector: row => row['occurrences'],
            sortable: true,
            width: "4em"
        },
        {
            name: t("Amount"),
            selector: row => row["amount"],
            sortable: true,
            format: toCurrency('amount'),
            right: true
        }
    ], [t]) // eslint-disable-line react-hooks/exhaustive-deps

    const toEuro = (value: number) => value.toLocaleString(i18n.language, {
        style: 'currency',
        currency: 'EUR',
    })

    return <>
        <ShowError error={error} onClose={() => setError('')} />
        <Card className="top">
            <CardHeader title={t('About us')}/>
            <CardContent>
                    <h3>{t('Background')}</h3>
                    <p>{t("about_1")}</p>
                    <p>{t("about_2")}</p>
                    <Link to='/impress'>{t('Impress')}</Link>
                    <hr />
                    <div className="row">
                        <div className="col-lg-3 col-md-3">
                            <img src="/img/logo_odi.png" height="60px" alt="Open Data Inside" />
                        </div>
                        <div className="col-lg-9 col-md-9">
                            <img src="https://www.netidee.at/themes/Netidee/images/netidee-logo-color.svg" alt="NetIdee Logo" />
                            <p>{t('about_6')}</p>
                        </div>
                    </div>
            </CardContent>
        </Card>
        <Card className="top">
            <CardHeader title={t('about_terms')}/>
            <CardContent>
                    <b><div>{t('Media Transparency')}</div></b>
                    <div>{t('about_MT')}</div>
                    <br />
                    <b><div>{t('Fundings')}</div></b>
                    <div>{t('about_PF')}</div>
                    <br />
                    <b><div>{t('Mixed')}</div></b>
                    <div>{t('about_mixed')}</div>
                    <br />
                    <a
                    href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=20007610 "
                    target="_blank"
                    rel="noreferrer"
                    >
                    {t('source')}
                </a>
            </CardContent>
        </Card>
        <Card className="top">
            <CardHeader title={t('Data Differences')}/>
            <CardContent>
                    <div>{t('diff_explain_1')}</div>
                    <div>{t('diff_overview', {
                        diffAmount: toEuro(overview.totalAmountMta - overview.totalAmountRtr),
                        amountRtr: toEuro(overview.totalAmountRtr),
                        amountMta: toEuro(overview.totalAmountMta),
                        countDiff: overview.totalCountRtr - overview.totalCountMta
                    })}</div>
                    <br />
                    <div>{t('duplicates_table_intro')}</div>
                    <DataTable
                        title={t("Duplicate entries in RTR data set")}
                        pagination={true}
                        customStyles={styles}
                        columns={dupColumns}
                        data={duplicates}
                        actions={<ExportToExcel data={duplicates} fileName="Duplicates" />}
                    />
                    <br />
                    <div>{t('difference_table_intro')}</div>
                    <DataTable
                        title={t("Differences between original RTR-data and this web-site")}
                        pagination={true}
                        customStyles={styles}
                        columns={diffColumns}
                        data={differences}
                        actions={<ExportToExcel data={differences} fileName="Differences" />}
                    />
                    <br />
                    <b><div>{t('diff_MT_PF')}</div></b>
                    <div>{t('MT_Text_3')}</div>
            </CardContent>
        </Card>
    </>
}
const toCurrency = (field: string) => (row: any) =>
    row[field]?.toLocaleString(i18n.language, {
        style: 'currency',
        currency: 'EUR',
    })
