import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { getTranslation } from './services/data-service';

// get language from browser
// var lang = navigator.language.slice(0,2);
i18n.language = "";
var fallbackLng = "de";


// load translation from MongoDB

const backendOptions = {
  request: (options, url, payload, callback) => {
    try {
      getTranslation(i18n.language !== ""? i18n.language: fallbackLng).then((result) => {
        callback(null, {
          data: result,
          status: 200, 
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    fallbackLng: 'de',
    debug: true,
    interpolation: {
      escapeValue: false,
    }
  });

// i18n.changeLanguage(navigator.language);


export default i18n;
  