import { useEffect, useMemo, useState } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { objectToParams, searchNames } from "../../services/data-service";
import {
  Help,
  ModalLoader,
  Render,
  ShowError,
} from "../../components/helper-components";
import { ResponsiveContainer } from "recharts";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faInfoCircle,
  faStream,
} from "@fortawesome/free-solid-svg-icons";

import {
  fundingTypes,
  fundingTypesIndices,
  getColorFromPaymentDetailType,
  getFundingValueFromName,
  isEqual,
  isObjectSubset,
  isTouchSupported,
  range,
  toCurrency,
} from "../../helpers/helpers";
import "./flows-transfers.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getColor, getPFColor } from "../../colors";
import DataTable, { Media } from "react-data-table-component-with-filter";
import { ExportToExcel } from "../../components/excel-exporter";
import { Alert, Grid, Tab as TabMui, Tabs as TabsMui } from "@mui/material";
import ReactECharts from "echarts-for-react";
import {
  addElementIfNotExist,
  getMixedFlows,
  getMixedFlowsLinks,
  TMixedFlowsQuery,
  TMixedFlowsRecord,
  TMixedFlowsResult,
} from "../../helpers/flows-mixed.helper";
import qs from "query-string";
import { TTimelineFundingResult } from "../../helpers/flows-funding.helper";
import "../top/top.scss";
import "./flows.scss";
import { TInfoState } from "../../App";
import { SwitchButton } from "../../components/switch-button/switchButton";
import { useMediaQuery } from "react-responsive";
import { TransferMultiSelectComp } from "../../components/multi-select/transfer-multi-select";
import { MultiSelectComp } from "../../components/multi-select/multi-select";
import { PeriodSlider } from "../../components/slider/rangeSlider";
import useTouchDoubleClickChecker from "../../helpers/touchDoubleClickChecker";
import { GroupTypeSelector } from "../../components/group-type-selector";
import { baseReducers, IBaseState } from "../../states/base_states";
import Config from "../../config/settings";
import { Fold, SettingsViewer } from "../../components/settings-viewer";

import * as echarts from "echarts/core";
import german from "echarts/i18n/langDE-obj";
import { defaultBaseTimelineModel, defaultSankeyOptions } from "./flows";
import {
  defaultFlowsMixedModuleSettings,
  FlowsMixedModuleSettings,
} from "./flows-mixed.settings";
import { useSettingsStore } from "../../context/SettingsStore";

echarts.registerLocale("de", german);

const minHeight = Config.flows.sankey.minHeight;

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const keysToNames = {
  receiver: "receiver",
  receiverGroups: "receiverGroups",
  org: "org",
  orgGroups: "orgGroups",
};

let echartRef;

type TSankeyDataModel = {
  name: string;
};

type TSankeyLinkModel = {
  source: string;
  target: string;
  value: number;
};

type TSankeyChartModel = {
  data: TSankeyDataModel[];
  links: TSankeyLinkModel[];
  right?: string;
  lineStyle?: {
    color: string;
  };
};

export type TTimelineResult = { period: string; total: number }[];

const timelineToEcharModel = (data: TTimelineFundingResult) => {
  const result = data
    .map((item) => {
      return {
        year: item.year,
        ...item.yearsData.reduce(
          (acc, q) => ({
            ...acc,
            [getFundingValueFromName(q.name)]: q.total,
          }),
          fundingTypes.reduce((acc, _, idx) => ({ ...acc, [idx]: 0 }), {
            22: 0,
          })
        ),
      };
    })
    .sort((a, b) => a.year - b.year);
  return result;
};

const mediaGroups: string[] = [];
const mtTypes: string[] = [];
const pfTypes: string[] = [];
const fundingBaseTypes: string[] = [];
const receivers: string[] = [];

let fundingBaseColors: Map<string, string>;
const getDataModel = (
  acc: {
    name: string;
    itemStyle?: { color: string; borderColor: string };
    label?: { position?: string; offset?: any };
  }[],
  currentValue: TMixedFlowsRecord
): TSankeyDataModel[] => {
  if (currentValue.amount) {
    acc.push({
      name: currentValue.paymentDetailType,
      itemStyle: {
        color: getColorFromPaymentDetailType(currentValue.paymentDetailType),
        borderColor: getColorFromPaymentDetailType(
          currentValue.paymentDetailType
        ),
      },
    });

    receivers.push(currentValue.receiverMedia);
    acc.push({
      name: currentValue.receiverMedia,
      label: { position: "insideRight", offset: [-20, 0] },
    });

    if (currentValue.showFundingBasis && currentValue.fundingBasis) {
      const fundingBaseColor =
        "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
      fundingBaseTypes.push(currentValue.fundingBasis);
      fundingBaseColors.set(currentValue.fundingBasis, fundingBaseColor);
      acc.push({
        name: currentValue.fundingBasis,
        itemStyle: { color: fundingBaseColor, borderColor: fundingBaseColor },
      });
    }

    if (currentValue.receiverGroups) {
      mediaGroups.push(currentValue.receiverGroups);
      acc.push({ name: currentValue.receiverGroups });
    }

    if (currentValue.transferType) mtTypes.push(currentValue.paymentDetailType);
    else pfTypes.push(currentValue.paymentDetailType);
  }
  return acc;
};
let payerSumMap: Map<string, number> = new Map();
let receiverGroupSumMap: Map<string, number> = new Map();
let receiverSumMap: Map<string, number> = new Map();
let fundingBaseSumMap: Map<string, number> = new Map();

const dataToSankeyDataModel = (
  result: TMixedFlowsResult,
  showAbsoluteVisualisation: boolean
): TSankeyChartModel => {
  fundingBaseColors = new Map();
  const duplicateData = Array.from(new Set(result.reduce(getDataModel, [])));
  const uniqueData = Array.from(
    new Map(duplicateData.map((v) => [JSON.stringify([v.name]), v])).values()
  );

  let res = getMixedFlowsLinks(
    result,
    fundingBaseColors,
    showAbsoluteVisualisation
  );
  payerSumMap = res.payerSumMap;
  receiverGroupSumMap = res.receiverSumGroupMap;
  receiverSumMap = res.receiverSumMap;
  fundingBaseSumMap = res.fundingBaseSumMap;

  return {
    data: uniqueData,
    links: [...res.linkModel],
    right: "5%",
    label: {
      borderWidth: 0,
      color: Config.flows.sankey.flowsLabelColor,
    },
  } as TSankeyChartModel;
};

export interface TMixedFlowsState
  extends IBaseState<TMixedFlowsQuery, TMixedFlowsResult> {
  pristine: boolean;
  timeline: TTimelineFundingResult;
  timelineModel: ReturnType<typeof timelineToEcharModel>;
  sankeyModel: ReturnType<typeof dataToSankeyDataModel>;
  restrictTimelineToRange: boolean;
  selectedOrganisaions: string[];
  organisationsList: string[];
  timelineTabularData: { year: number; type: string; amount: number }[];
}

let initialFlowsMixedState: TMixedFlowsState = {
  pristine: true,
  data: [] as TMixedFlowsResult,
  timeline: [] as any[] as TTimelineFundingResult,
  timelineModel: [],
  sankeyModel: {} as TSankeyChartModel,
  timelineTabularData: [],
  query: {
    receiver: defaultFlowsMixedModuleSettings.defaultReceivers,
    org: [],
    orgGroups: [],
    receiverGroups: [],
    group_type: "",
    mediaGroupType: "",
  } as TMixedFlowsQuery,
  pending: false,
  needsUpdate: false,
  selectedOrganisaions: [],
  organisationsList: [],
  restrictTimelineToRange: false,
};

export const flowsMixedSlice = createSlice({
  name: "flowsMixed",
  initialState: initialFlowsMixedState,
  reducers: {
    ...baseReducers<TMixedFlowsQuery, TMixedFlowsResult, TMixedFlowsState>(),
    setFlowFundingData: (
      state,
      action: PayloadAction<{
        data: TMixedFlowsResult;
        showAbsoluteVisualisation: boolean;
      }>
    ) => {
      const { data, showAbsoluteVisualisation } = action.payload;
      return {
        ...state,
        data: data,
        needsUpdate: false,
        sankeyModel: dataToSankeyDataModel(data, showAbsoluteVisualisation),
      };
    },
    setTimeline: (state, action: PayloadAction<TTimelineFundingResult>) => ({
      ...state,
      timeline: action.payload,
      timelineModel: timelineToEcharModel(action.payload),
      timelineTabularData: action.payload.reduce(
        (acc, v) => [
          ...acc,
          ...v.yearsData.map((d) => ({
            year: v.year,
            type: d.name,
            amount: d.total,
          })),
        ],
        [] as { year: number; type: string; amount: number }[]
      ),
    }),
    setShowAbsoluteValues: (state, action: PayloadAction<boolean>) => {
      state.query.showAbsoluteValues = action.payload;
    },
    setShowFundingBasis: (state, action: PayloadAction<boolean>) => {
      state.query.showFundingBasis = action.payload;
    },
    initializeStateFromSettings: (
      state,
      action: PayloadAction<FlowsMixedModuleSettings>
    ) => {
      // Update the state with the initial values from the settings
      return {
        ...state,
        query: {
          ...state.query,
          receiver: action.payload.defaultReceivers,
        },
      };
    },
  },
});

const {
  setFlowFundingData,
  setPending,
  setQuery,
  setNeedsUpdate,
  setTimeline,
  clearNeedsUpdate,
  setRange,
  saveQuery,
  setShowAbsoluteValues,
  setShowFundingBasis,
  initializeStateFromSettings,
} = flowsMixedSlice.actions;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const FlowsMixed = () => {
  const [error, setError] = useState("");
  const [[pStart, pEnd], setPeriods] = useState<number[]>([0, 0]);
  const navigate = useNavigate();
  // @ts-ignore
  const queryParams = useQuery();
  const location = useLocation();
  const {
    periods,
    periodsFunding,
    mixedPeriods,
    groups,
    otherMediaDisabled,
    groupTypes,
  } = useSelector<AppState, TInfoState>((state) => state.info);
  const { t, i18n } = useTranslation();
  const moduleSettings = useSettingsStore().modules.flows_mixed;
  const dispatch = useDispatch();
  const {
    pending,
    needsUpdate,
    data,
    query,
    timeline,
    timelineModel,
    sankeyModel,
    lastQuery,
    restrictTimelineToRange,
    timelineTabularData,
  } = useSelector<AppState, TMixedFlowsState>((state) => state.flowsMixed);
  const { tab } = useParams();

  const setTab = (newValue: string, updateRoute: boolean) => {
    if (tab != newValue && updateRoute && moduleSettings.enabled) {
      navigate(`/flows/mixed/${newValue}${location.search}`);
    }
    setUpdateRoute(false);
  };

  useEffect(() => {
    document["lastClick"] = Date.now();
  }, []);

  useEffect(() => {
    if (moduleSettings) {
      dispatch(initializeStateFromSettings(moduleSettings));
    }
  }, [moduleSettings, dispatch]);

  const handleClick = ({ data }) => {
    if (isTouchSupported()) {
      const now = Date.now();
      const lastClick = document["lastClick"];
      console.log("lastClick", lastClick);
      if (now - lastClick < 600) {
        return selectPeriod(data.period);
      } else {
        document["lastClick"] = now;
      }
    } else {
      return selectPeriod(data.year);
    }
  };

  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const isMobile = isMobileLandscape || isMobilePortrait;

  const customToolTip = (params) => {
    const fmt = new Intl.NumberFormat(i18n.language, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    let data = params.data;
    if (data.source) {
      let percentage = 0;
      let targetPercentage = 0;
      if (payerSumMap.has(data.source))
        percentage =
          (data.normalValue / (payerSumMap.get(data.source) as number)) * 100;
      else if (receiverGroupSumMap.has(data.source))
        percentage =
          (data.normalValue /
            (receiverGroupSumMap.get(data.source) as number)) *
          100;
      else if (fundingBaseSumMap.has(data.source))
        percentage =
          (data.normalValue / (fundingBaseSumMap.get(data.source) as number)) *
          100;

      if (payerSumMap.has(data.target))
        targetPercentage =
          (data.normalValue / (payerSumMap.get(data.target) as number)) * 100;
      if (receiverGroupSumMap.has(data.target))
        targetPercentage =
          (data.normalValue /
            (receiverGroupSumMap.get(data.target) as number)) *
          100;
      if (receiverSumMap.has(data.target))
        targetPercentage =
          (data.normalValue / (receiverSumMap.get(data.target) as number)) *
          100;
      if (fundingBaseSumMap.has(data.target))
        targetPercentage =
          (data.normalValue / (fundingBaseSumMap.get(data.target) as number)) *
          100;

      return `
              <b>${data.source} ${
        percentage % 100 !== 0 ? "(" + percentage.toFixed(2) + "%)" : ""
      } ${isMobile ? "<br/>" : ""} → ${data.target}
              ${
                targetPercentage % 100 !== 0
                  ? "(" + targetPercentage.toFixed(2) + "%)"
                  : ""
              }</b><br />
              ${fmt.format(data.normalValue)}
        `;
    } else {
      let value = Math.pow(10, params.value);
      if (receiverGroupSumMap.has(params.name))
        value = receiverGroupSumMap.get(params.name) as number;
      if (fundingBaseSumMap.has(params.name))
        value = fundingBaseSumMap.get(params.name) as number;
      if (payerSumMap.has(params.name))
        value = payerSumMap.get(params.name) as number;
      if (receiverSumMap.has(params.name))
        value = receiverSumMap.get(params.name) as number;

      return `
                <b>${params.data.name}</b><br />
                ${fmt.format(value)}
          `;
    }
  };

  const sankeyOptions = useMemo(
    () =>
      defaultSankeyOptions(
        isMobile,
        customToolTip,
        t("flows_mixed_title"),
        t("flows_mixed_timeline_sub_title", {
          period:
            query.to !== query.from
              ? `${query.from ?? 0} - ${query.to ?? 0}`
              : query.to ?? 0,
          receivers:
            query.receiver.length > 0 || (query.receiverGroups?.length ?? 0) > 0
              ? [...query.receiver, ...(query.receiverGroups ?? [])].join(", ")
              : t("all"),
        }) +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
        sankeyModel
      ),
    [t, sankeyModel, i18n.language]
  );

  const timelineTooltipFormatter = (params) => {
    params = params instanceof Array ? params : [params];
    const includesForecast = params.filter((e) => e.data.prediction).length > 0;
    const fmt = new Intl.NumberFormat(i18n.language, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    let d = params
      .map(
        (p) =>
          `${p.marker} ${p.seriesName}${
            p.data["prediction"] ? "*" : ""
          } <span style="float:right;margin-left:20px;font-size:14px;color:${
            p.data["prediction"] ? "#aaa" : "#666"
          };font-weight:900">${fmt.format(
            p.data[p.dimensionNames[p.encode.y]]
          )}</span>`
      )
      .join("<br/>");
    if (params.length > 1) {
      if (!includesForecast) {
        const s = params
          .filter((e) => !e.data.prediction)
          .reduce((acc, p) => acc + p.data[p.dimensionNames[p.encode.y]], 0);
        d = `${d}<br/><span style="font-weight:900">${t(
          "Total"
        )}</span><span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${fmt.format(
          s
        )}</span>`;
      } else {
        const s = params.reduce(
          (acc, p) => acc + p.data[p.dimensionNames[p.encode.y]],
          0
        );
        d = `${d}<br/>${t(
          "Total"
        )}*<span style="float:right;margin-left:20px;font-size:14px;color:#aaa;font-weight:900">${fmt.format(
          s
        )}</span>`;
        d += `<br>*) ${t("Forecast")}`;
      }
    } else if (includesForecast) {
      d += `<br>*) ${t("Forecast")}`;
    }
    return `${params[0].name}<br/>${d}`;
  };

  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const colorsFromCSS = range(1,9).map( i => 
      getComputedStyle(document.documentElement)
        .getPropertyValue(`--echart-color-${i}`)
        .trim()
      ).filter(c => c.length > 0)
    setColors(colorsFromCSS);
  }, []);

  const getColorFromIndex = (idx: number, count: number) => {
    return colors.length > 0 ? colors[idx] : getColor(idx, count); // Return color if available, otherwise empty string
  };

  const timelineOptions = useMemo(
    () => ({
      ...defaultBaseTimelineModel(
        isMobile,
        timelineTooltipFormatter,
        t("flows_mixed_timeline_title"),
        t("flows_mixed_timeline_sub_title", {
          receivers:
            query.receiver.length > 0 || (query.receiverGroups?.length ?? 0) > 0
              ? [...query.receiver, ...(query.receiverGroups ?? [])].join(", ")
              : t("all"),
        }) +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
        true
      ),
      xAxis: { type: "category" },
      yAxis: {
        axisLabel: {
          formatter: (value) => `${value / 1000000} Mio.`,
        },
      },
      dataset: [
        {
          dimensions: ["year", "22", ...fundingTypesIndices()],
          source: timelineModel,
        },
      ],
      series: [
        {
          type: "bar",
          name: t("Advertising"),
          emphasis: { focus: "series" },
          color: getColorFromIndex(1, 3),
        },
        ...fundingTypes.map((ft, i) => ({
          type: "bar",
          name: t(ft),
          emphasis: { focus: "series" },
          color: getPFColor(i + 1, fundingTypes.length),
        })),
      ],
    }),
    [timelineModel, t, colors, i18n.language]
  );

  useEffect(() => {
    if (mixedPeriods && mixedPeriods.length > 0) {
      const maxPeriodIndex = mixedPeriods.length - 1;
      const maxPeriod = mixedPeriods[maxPeriodIndex];
      if (!query.from || !query.to) {
        dispatch(
          setQuery({
            ...query,
            from: maxPeriod,
            to: maxPeriod,
          })
        );
        setPeriods([maxPeriodIndex, maxPeriodIndex]);
      } else if (query.from && query.to) {
        const minIndex = mixedPeriods.findIndex((it) => it === query.from);
        const maxIndex = mixedPeriods.findIndex((it) => it === query.to);
        dispatch(setNeedsUpdate());
        setPeriods([minIndex, maxIndex]);
      } else {
        dispatch(setNeedsUpdate());
      }
    }
  }, [mixedPeriods]);

  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      mixedPeriods &&
      mixedPeriods.length > 0 &&
      query.receiver.length + (query.receiverGroups ?? []).length > 0 &&
      query.from &&
      query.to
    ) {
      //console.log("Checking query vs. location")
      const queryString = objectToParams(query);
      const currentQueryStringObj = qs.parse(location.search);
      if (!isEqual(currentQueryStringObj, qs.parse(queryString))) {
        navigate(`/flows/mixed/${tab}?${queryString}`);
        dispatch(setNeedsUpdate());
      }
      //    }
    } else if (
      query.receiver.length + (query.receiverGroups ?? []).length ===
      0
    ) {
      dispatch(
        setFlowFundingData({
          data: [],
          showAbsoluteVisualisation: query.showAbsoluteValues ?? false,
        })
      );
      dispatch(setTimeline([]));
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let q = {
      receiver: [],
      org: [],
      orgGroups: [],
      receiverGroups: [],
    };
    ["receiver", "org", "orgGroups", "receiverGroups"].forEach((org) => {
      if (queryParams.getAll(org).length > 0) {
        q[org] = queryParams.getAll(org).filter((e) => e.length > 0);
      }
    });
    ["from", "to"].forEach((org) => {
      if (queryParams.get(org)) {
        q[org] = parseInt(queryParams.get(org) ?? "0");
      }
    });
    //console.log("QUERY:");
    //console.log(q)
    if (
      q.receiver.length + (q.receiverGroups ?? []).length > 0 &&
      q["from"] &&
      q["to"]
    ) {
      if (!isObjectSubset(q, query)) {
        dispatch(setQuery({ ...query, ...q }));
      }
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(
      "Cached: " +
        (lastQuery != undefined &&
          isEqual(lastQuery, query, ["showAbsoluteValues"]))
    );
    if (
      needsUpdate &&
      query.from &&
      query.to &&
      query.receiver.length + (query.receiverGroups ?? []).length > 0 &&
      (lastQuery == undefined ||
        !isEqual(lastQuery, query, ["showAbsoluteValues"]))
    ) {
      setError("");
      dispatch(saveQuery());
      dispatch(setPending(true));
      //Promise.all([
      getMixedFlows(
        query,
        query.showFundingBasis ?? false,
        otherMediaDisabled,
        restrictTimelineToRange
      )
        //getMixedTimeline(query, restrictTimelineToRange),
        //])
        .then(({ flows, timeline }) => {
          dispatch(
            setFlowFundingData({
              data: flows,
              showAbsoluteVisualisation: query.showAbsoluteValues ?? false,
            })
          );
          dispatch(setTimeline(timeline));
        })
        .catch((err) => {
          setError(err?.response?.data?.error ?? err.message);
          dispatch(
            setFlowFundingData({
              data: [],
              showAbsoluteVisualisation: query.showAbsoluteValues ?? false,
            })
          );
          dispatch(clearNeedsUpdate());
        })
        .finally(() => {
          dispatch(setPending(false));
          dispatch(clearNeedsUpdate());
        });
    } else if (needsUpdate) {
      dispatch(clearNeedsUpdate());
    }
  }, [needsUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const colums = useMemo(
    () => [
      {
        name: t("Payer"),
        selector: (row) => row["organisation"],
        sortable: true,
        hide: Media.MD,
      },
      {
        name: t("Beneficiary"),
        selector: (row) => row["receiverMedia"],
        sortable: true,
      },
      {
        name: "Art",
        selector: (row) => row["paymentTypeFull"],
        sortable: true,
        hide: Media.MD,
      },
      {
        name: t("Amount"),
        selector: (row) => row["amount"],
        sortable: true,
        format: toCurrency("amount"),
        right: true,
      },
    ],
    [t, i18n.language]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const updateSelection =
    (orgType: "org" | "orgGroups" | "receiver" | "receiverGroups") =>
    (list: string[]) => {
      setError("");
      dispatch(
        setQuery({
          ...query,
          [keysToNames[orgType]]: list.filter((v) => v.length > 0),
        })
      );
    };

  const selectPeriod = (period) => {
    dispatch(setRange({ from: period, to: period }));
    setUpdateRoute(false);
    setActiveTab("flows");
  };

  const onChartMouseMove = () => {
    const echartInstance = echartRef.getEchartsInstance();
    echartInstance.getZr().setCursorStyle("pointer");
  };

  const onChartClick = (params) => {
    if (
      params.data.source?.includes("Andere") ||
      params.data.name?.includes("Andere") ||
      params.data.target?.includes("Andere")
    )
      return;
    const toPeriod = periods[periods.length - 1];
    const fromPeriod = periods[0];

    const toPeriodFunding = periodsFunding[periodsFunding.length - 1];
    const fromPeriodFunding = periodsFunding[0];

    // handle payer click
    if (params.data.name && mtTypes.includes(params.data.name))
      navigate(
        `/flows/transfers/flows?from=${fromPeriod}&to=${toPeriod}&pType=2`
      );
    else if (params.data.name && pfTypes.includes(params.data.name)) {
      navigate(
        `/flows/fundings/flows?&fundingType=${getFundingValueFromName(
          params.data.name
        )}&from=${fromPeriodFunding}&to=${toPeriodFunding}`
      );
    }

    // handle funding base type
    const fundingBaseName = params.data.name ?? params.data.source;
    if (fundingBaseTypes.includes(fundingBaseName)) {
      let query = `/flows/fundings/flows?showFundingBasis=true&from=${mixedPeriods[pStart]}&to=${mixedPeriods[pEnd]}`;
      const link = sankeyOptions.series.links.find(
        (it) => it.target === fundingBaseName
      );
      if (link) {
        if (params.data.target && mediaGroups.includes(params.data.target))
          query += `&fundingType=${getFundingValueFromName(
            link?.source
          )}&receiverGroups=${params.data.target}`;
        else if (params.data.target && receivers.includes(params.data.target))
          query += `&fundingType=${getFundingValueFromName(
            link?.source
          )}&receiver=${params.data.target}`;
        else query += `&fundingType=${getFundingValueFromName(link?.source)}`;
      }
      navigate(query);
    } else if (
      params.data.source &&
      (mtTypes.includes(params.data.source) ||
        pfTypes.includes(params.data.source))
    ) {
      if (mtTypes.includes(params.data.source)) {
        let queryString = `from=${mixedPeriods[pStart] + "1"}&to=${
          mixedPeriods[pEnd] + "4"
        }`;
        if (mediaGroups.includes(params.data.target))
          queryString += `&pType=2&mediaGroups=${encodeURIComponent(
            params.data.target
          )}`;
        else
          queryString += `&pType=2&media=${encodeURIComponent(
            params.data.target
          )}`;
        navigate(`/flows/transfers/flows?${queryString}`);
      } else if (pfTypes.includes(params.data.source)) {
        let queryString = `from=${fromPeriodFunding}&to=${toPeriodFunding}`;
        if (mediaGroups.includes(params.data.target))
          queryString += `&fundingType=${getFundingValueFromName(
            params.data.source
          )}&receiverGroups=${encodeURIComponent(params.data.target)}`;
        else if (receivers.includes(params.data.target))
          queryString += `&fundingType=${getFundingValueFromName(
            params.data.source
          )}&receiver=${encodeURIComponent(params.data.target)}`;
        else
          queryString += `&fundingType=${getFundingValueFromName(
            params.data.source
          )}`;
        navigate(`/flows/fundings/flows?${queryString}`);
      }
    } else {
      const nam = params.data.name ? params.data.name : params.data.target;
      if (mediaGroups.includes(nam)) {
        dispatch(
          setQuery({
            ...query,
            receiverGroups: addElementIfNotExist(query.receiverGroups, nam),
            receiver: [],
          })
        );
      } else if (receivers.includes(nam)) {
        dispatch(
          setQuery({
            ...query,
            receiver: addElementIfNotExist(query.receiver, nam),
            receiverGroups: [],
          })
        );
      }
    }
  };

  const onEvents = {
    click: onChartClick,
    mousemove: onChartMouseMove,
  };

  const touchDoubleClickChecker = useTouchDoubleClickChecker();

  const [touchEchartsEvents] = useState({
    click: (event) => {
      touchDoubleClickChecker(
        () => onChartClick(event),
        () => {},
        250
      );
    },
    dblclick: onChartClick,
  });

  const timelineColums = useMemo(
    () => [
      {
        name: t("Year"),
        selector: (row) => row["year"],
        sortable: true,
      },
      {
        name: t("Type"),
        selector: (row) => row["type"],
        sortable: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row["amount"],
        sortable: true,
        format: toCurrency("amount"),
        right: true,
      },
    ],
    [t, i18n.language]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const Settings = () => {
    return (
      <div className="settings" data-test-id="flowMixedSettings">
        <Grid container spacing={3} data-test-id="flowSettings">
          <Grid item xs={12} lg={4} md={4}>
            <SwitchButton
              simple={true}
              selValue={!query.showAbsoluteValues ?? false}
              onlabel={
                <span>
                  {t("Logarithmic scale")} <Help text={t("scale_help")} />
                </span>
              }
              offlabel={
                <span>
                  {t("Logarithmic scale")} <Help text={t("scale_help")} />
                </span>
              }
              onIconD={Config.switchButton.icons.numbersIconD}
              offIconD={Config.switchButton.icons.percentIconD}
              onChange={(checked: boolean) => {
                dispatch(setShowAbsoluteValues(!checked));
                dispatch(
                  setFlowFundingData({
                    data: data,
                    showAbsoluteVisualisation: !checked,
                  })
                );
              }}
            ></SwitchButton>

            {/* <div className="label">
                  {t("show_details_funding")} <Help text={t("Show details funding long")} />
                </div> */}
            <SwitchButton
              simple={true}
              selValue={query.showFundingBasis ?? false}
              label={
                <span>
                  {t("show_details_funding")}{" "}
                  <Help text={t("show_details_funding_help")} />
                </span>
              }
              onIconD={Config.switchButton.icons.visibilityIconD}
              offIconD={Config.switchButton.icons.visibilityHideIconD}
              onChange={(checked: boolean) => {
                dispatch(setShowFundingBasis(checked));
                dispatch(setNeedsUpdate());
              }}
            ></SwitchButton>
          </Grid>
          <Grid item xs={12} lg={8} md={8}>
            <TransferMultiSelectComp
              value={query.receiver}
              orgType="media"
              placeholder={t("Select a Beneficiary")}
              label={t("Beneficiaries")}
              searchNames={searchNames}
              onChange={(e) => {
                updateSelection("receiver")(e);
              }}
            />
            <GroupTypeSelector
              id="select-group-type-org-funding-flow"
              value={query.mediaGroupType}
              availableGroupTypes={groupTypes}
              type="media"
              onChange={(e) => {
                dispatch(
                  setQuery({
                    ...query,
                    receiverGroups: [],
                    mediaGroupType: e.target.value,
                  })
                );
              }}
            />
            <Render
              when={
                query.mediaGroupType != undefined &&
                query.mediaGroupType.length > 0
              }
            >
              <MultiSelectComp
                options={groups
                  .filter(
                    (g) =>
                      g.type === "media" &&
                      g.group_type === query.mediaGroupType
                  )
                  .map((g) => g.name)
                  .sort((a, b) => a.localeCompare(b))}
                value={query.receiverGroups}
                placeholder={t("Select a Group")}
                label={t("Groups")}
                onChange={(newValue) =>
                  updateSelection("receiverGroups")(newValue)
                }
              />
            </Render>
          </Grid>
          <Grid item xs={12}>
            <PeriodSlider
              label={t("Period")}
              startPeriod={query.from ?? 0}
              endPeriod={query.to ?? 0}
              step={1}
              onChange={(start, end) =>
                dispatch(setQuery({ ...query, from: start, to: end }))
              }
              periods={periodsFunding}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const [activeTab, setActiveTab] = useState(tab ?? "flows");
  const [updateRoute, setUpdateRoute] = useState(true);

  useEffect(() => {
    setTab(activeTab, updateRoute);
  }, [activeTab, updateRoute]);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <>
      <ShowError error={error} onClose={() => setError("")} />
      <ModalLoader isPending={pending} />

      <div className={isMobile ? "flows-mobile-settings" : "flows-settings"}>
        <SettingsViewer id="transfer-flows" fold={true} open={!isMobile}>
          <Settings />
        </SettingsViewer>
      </div>
      <Render
        when={
          !sankeyModel || !sankeyModel.data || sankeyModel?.data?.length === 0
        }
      >
        <Alert severity="warning">
          {t("No Data found that matches your settings")}
        </Alert>
      </Render>
      <TabsMui
        value={activeTab}
        onChange={handleTabChange}
        orientation={isMobilePortrait ? "vertical" : "horizontal"}
        centered
      >
        <TabMui
          label={
            <span>
              <FontAwesomeIcon icon={faStream} /> {t("Flows")}
            </span>
          }
          value="flows"
        />
        <TabMui
          value="timeline"
          label={
            <span>
              <FontAwesomeIcon icon={faChartLine} /> {t("Timeline")}
            </span>
          }
        />
      </TabsMui>
      <Render when={activeTab === "flows" && data?.length > 0 && !error}>
        <div className="text-end info" style={{ margin: "1em 0" }}>
          <FontAwesomeIcon icon={faInfoCircle} />{" "}
          {t(
            `${
              isTouchSupported() ? "Double click" : "Click"
            } on the chart to get more information`
          )}
        </div>
        <ResponsiveContainer
          width="100%"
          height={
            sankeyModel?.data?.length * 20 < 1000
              ? minHeight
              : sankeyModel?.data?.length * 20
          }
        >
          <ReactECharts
            ref={(e) => {
              echartRef = e;
            }}
            option={sankeyOptions}
            opts={{ locale: i18n.language.split("-")[0] }}
            style={{
              height: "100%",
              width: "100%",
              cursor: "pointer !important",
            }}
            onEvents={isTouchSupported() ? touchEchartsEvents : onEvents}
          />
        </ResponsiveContainer>

        <Fold
          id={`flows-mixed-table`}
          header={t("Raw Data")}
          fold={true}
          open={false}
          icon={<></>}
        >
          <DataTable
            title={t("Money Flows")}
            pagination={true}
            columns={colums}
            data={data}
            actions={<ExportToExcel data={data} fileName="MoneyFlow" />}
          />
        </Fold>
      </Render>
      <Render when={activeTab === "timeline" && timeline?.length > 0 && !error}>
        <div className="text-end info">
          <FontAwesomeIcon icon={faInfoCircle} />{" "}
          {t(
            `${
              isTouchSupported() ? "Double click" : "Click"
            } on the chart to get more information`
          )}
        </div>
        <ReactECharts
          option={timelineOptions}
          onEvents={{ click: handleClick }}
          style={{ height: 450 }}
          opts={{ locale: i18n.language.split("-")[0] }}
        />
        <Fold
          id={`flows-mixed-timeline-table`}
          header={t("Raw Data")}
          fold={true}
          open={false}
          icon={<></>}
        >
          <DataTable
            title={t("flows_funding_timeline_title")}
            pagination={true}
            columns={timelineColums}
            data={timelineTabularData}
            actions={<ExportToExcel data={timeline} fileName="MoneyFlow" />}
          />
        </Fold>
      </Render>
    </>
  );
};
