import { useContext, useMemo } from "react";
import { LoginInformation } from "../models/models";
import { FormDescription } from "./form-builder/field-types";
import { BuildForm, email } from "./form-builder/mui-element-factory";
import "./login_form.css";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../context/auth-context";
import { useTranslation } from "react-i18next";

export const Login = () => {
  const { t } = useTranslation();
  const handleSubmit = (user: LoginInformation) =>
    authContext?.signin(user).then((user_) => console.log(user_));
  const loginFormDescription = useMemo<FormDescription<LoginInformation>>(
    () => ({
      fields: [
        {
          name: "email",
          type: "email",
          label: t("Email Address"),
          validators: {
            required: "required",
            pattern: { value: email, message: t("incorrect_email") },
          },
        },
        {
          name: "password",
          type: "password",
          label: t("Password"),
          validators: {
            required: "required",
            minLength: { value: 8, message: t("password_too_short") },
          },
        },
      ],
      name: t("Login"),
      submitHandler: handleSubmit,
      submitAction: {
        label: t("Login"),
        icon: faSignInAlt,
        nextRoute: "/",
      },
    }),
    [t] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const authContext = useContext(AuthContext);

  return (
    <div className="flex-row-center">
      <div className="col-lg-4 col-md-6 col-sm-9 col-xs-12">
        <BuildForm {...loginFormDescription}></BuildForm>
      </div>
    </div>
  );
};
