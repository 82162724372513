import {ModuleSettings} from "../../models/modules";

export interface FlowsTransferModuleSettings extends ModuleSettings {
  defaultOrgGroupType: string;
  defaultMediaGroupType: string;
  defaultOrganisations: string[];
  defaultMedia: string[];
  defaultOrgGroups: string[];
  defaultMediaGroups: string[];
}

export const defaultFlowsTransferModuleSettings: FlowsTransferModuleSettings = {
  enabled: false,
  defaultOrgGroupType: "membership",
  defaultMediaGroupType: "membership",
  defaultOrganisations: ["Bundeskanzleramt"],
  defaultMedia: [],
  defaultOrgGroups: [],
  defaultMediaGroups: [],
}
