import { TextField } from "@mui/material";
import { GroupType } from "../models/models";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Help, Render } from "./helper-components";
import MenuItem from "@mui/material/MenuItem";
import { getGroupTypesByType } from "../helpers/helpers";
import Chip from "@mui/material/Chip";
import Config from "../config/settings";

export type GroupTypeSelectorProps = {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  id: string;
  value: any;
  availableGroupTypes: GroupType[];
  type: "org" | "media";
};

export const GroupTypeSelector = ({
  id,
  value,
  onChange,
  availableGroupTypes,
  type,
}: GroupTypeSelectorProps) => {
  const { t } = useTranslation();
  const groupTypes = useMemo(
    () => getGroupTypesByType(availableGroupTypes, type),
    [type, availableGroupTypes]
  );
  return (
    <Render when={groupTypes.length > 0}>
      <label
        aria-label={
          groupTypes.length > 1
            ? `${type === "org"
              ? t("Group payers by")
              : t("Group beneficiaries by")
            } ${groupTypes.length} ${t("Options available")}`
            : type === "org"
              ? t("Group payers by")
              : t("Group beneficiaries by")
        }
      >
        <TextField
          id="select-groupings"
          select
          defaultValue=""
          label={
            groupTypes.length > 1 ? (
              <>
                {type === "org"
                  ? t("Group payers by")
                  : t("Group beneficiaries by")}{" "}
                <Chip
                  size="small"
                  label={`${groupTypes.length} ${t("Options available")}`}
                  variant="filled"
                />
              </>
            ) : type === "org" ? (
              t("Group payers by")
            ) : (
              t("Group beneficiaries by")
            )
          }
          aria-label={
            groupTypes.length > 1
              ? `${type === "org"
                ? t("Group payers by")
                : t("Group beneficiaries by")
              } ${groupTypes.length} ${t("Options available")}`
              : type === "org"
                ? t("Group payers by")
                : t("Group beneficiaries by")
          }
          variant={Config.input.labelVariant}
          fullWidth={true}
          value={value}
          helperText={
            <span>
              {t("Use pre-configured groupings")}{" "}
              <Help text="group_types_help" aria-label="Group types help" />
            </span>
          }
          onChange={onChange}
        >
          <MenuItem value="" aria-label={t("None")}>
            {t("None")}
          </MenuItem>
          {groupTypes.map((r, i) => (
            <MenuItem value={r._id} key={`groupType_${i}`} aria-label={t(r.name)}>
              {t(r.name)}
            </MenuItem>
          ))}
        </TextField>
      </label>
    </Render>
  );
};
