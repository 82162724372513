import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { isTouchSupported } from '../helpers/helpers';
import { useMediaQuery } from 'react-responsive';

/* eslint react-hooks/exhaustive-deps: 0 */

export const MTATooltip = ({ children, openLink }) => {
    const isMobileLandscape = useMediaQuery({ maxHeight: 575.98, orientation: 'landscape' })
    const isMobilePortrait = useMediaQuery({ maxWidth: 600 })
    const isMobile = isMobileLandscape || isMobilePortrait

    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [visible, setVisible] = useState(false);
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('');
    const [value, setValue] = useState(0);
    const [percent, setPercent] = useState(0);

    useEffect(() => { document['lastClick'] = Date.now() }, [])

    const handleClick = e => {
        if (isTouchSupported()) {
            const now = Date.now()
            const lastClick = document['lastClick']
            console.log('lastClick', lastClick)
            if (now - lastClick < 600) {
                return e.name.startsWith('Other') ? null : openLink(e.data)
            } else {
                document['lastClick'] = now
            }
        } else {
            return e.name.startsWith('Other') ? null : openLink(e.data)
        }
    }

    const chartWithTooltip = useMemo(() =>
        React.cloneElement(children as any, {
            onEvents: {
                'focus': p =>
                    console.log('focus', p),
                'mouseout': e => {
                    setVisible(false)
                },
                'mouseover': e => {
                    setX(e.event.offsetX)
                    setY(e.event.offsetY)
                    setVisible(true)
                    setValue(e.value)
                    setName(e.name)
                    setPercent(e.data.percent)
                },
                'mousemove': e => {
                    setX(e.event.offsetX)
                    setY(e.event.offsetY)
                },
                'click': handleClick
            }
        }) //: element
        , [children, openLink])

    const ttStyle: CSSProperties = useMemo(() => ({
        position: "absolute",
        display: "block",
        borderStyle: "solid",
        whiteSpace: isMobile ? "normal" : "nowrap",
        zIndex: 9999999,
        boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 10px",
        transition: "opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s",
        backgroundColor: "rgb(255, 255, 255)",
        borderWidth: 1,
        borderRadius: 4,
        color: "rgb(102, 102, 102)",
        font: "14px / 21px sans-serif",
        padding: "10px; top: 0px",
        left: 0,
        top: 150,
        transform: isMobile ? '' : `translate3d(${x}px, ${y}px, 0px)`,
        borderColor: "rgb(255, 255, 255)",
        pointerEvents: "none",
        visibility: visible ? 'visible' : 'hidden',
        fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--font-family'),
        opacity: 1,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        maxWidth: isMobile ? '300px' : 'auto',
        wordBreak: isMobile ? 'break-word' : 'normal'
    }), [visible, x, y, isMobile])



    return <div>
        {chartWithTooltip}
        <div style={ttStyle}>
            <span style={{
                marginLeft: isMobile ? 0 : 10,
                fontSize: 14,
                color: '#666',
                fontWeight: 900
            }}>{name === 'Others' ? t(name) : name}</span>
            <br />
            <span style={{
                float: 'right',
                marginLeft: 10,
                fontSize: 14,
                color: '#666',
                fontWeight: 600
            }}>{value.toLocaleString(i18n.language, {
                style: 'currency',
                currency: 'EUR',
            })} ({percent}%)</span>
        </div>
    </div>
}
