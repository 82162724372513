import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import {
  BaseField,
  CheckBoxFieldDescription,
  FieldDescriptionType,
  FormAction,
  FormDescription,
  HtmlEditorFieldDescription,
  MultiCheckBoxFieldDescription,
  MultiSelectFieldDescription,
  RadioButtonFieldDescription,
  SelectFieldDescription,
  TextFieldDescription,
} from "./field-types";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/auth-context";

import { faCheck, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { MultiSelect, Option } from "react-multi-select-component";
import DataTable, {
  ExpanderComponentProps,
} from "react-data-table-component-with-filter";
import moment from "moment";
import { useBeforeunload } from "react-beforeunload";
import Button from "@mui/material/Button";

import {
  DefaultValues,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import {
  CheckboxButtonGroup,
  FormContainer,
  useForm,
  TextFieldElement,
  CheckboxElement,
  RadioButtonGroup,
  SelectElement,
  useFormContext,
} from "react-hook-form-mui";
import Grid from "@mui/material/Unstable_Grid2";
import { zip } from "../../helpers/helpers";
import { t } from "i18next";
import Box from "@mui/material/Box";
import { Popup } from "../popup";

type FieldProps<T extends FieldValues, S extends BaseField<T>> = {
  description: S;
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  errors: any;
  initialState?: DefaultValues<T>;
  setShow?: (a: any) => any;
  setPending?: (a: any) => any;
  setHasErrors?: (a: any) => any;
  control: any;
};

const MultiCheckBoxField = <T extends FieldValues>({
  description,
  register: reg,
}: FieldProps<T, MultiCheckBoxFieldDescription<T>>) => {
  // useEffect(() => {
  //   setValue(description.name, initialState && initialState[description.name.toString()])
  // }, [description.name, initialState, setValue])

  return (
    <CheckboxButtonGroup
      key={description.name.toString()}
      label={description.label}
      row={description.inline ?? false}
      name={description.name.toString()}
      options={description.options.map((o) => ({ id: o, label: o }))}
    />
  );
  //  <FormControl>
  //   {description.label ? <InputLabel>{description.label}</InputLabel> : null}

  //     {description.options.map(o =>
  //       <FormControlLabel key={0} control={
  //         <Checkbox defaultChecked={initialState && initialState[description.name.toString()].includes(o)}
  //              {...reg(description.name)}/>
  //       } label={o} />
  //     )}

  //   </FormControl>
};

const HtmlEditorField = <T extends FieldValues>({
  description,
  register: reg,
  errors,
  initialState,
}: FieldProps<T, HtmlEditorFieldDescription<T>>) => {
  const { setValue } = useFormContext<T>();
  useEffect(() => {
    setValue(
      description.name,
      initialState && initialState[description.name.toString()]
    );
  }, [description.name, initialState, setValue]);
  const {
    onChange: onChange_,
    onBlur: onBlur_,
    ...rest
  } = reg(description.name);
  return (
    <div className="form-group">
      {description.label ? (
        <label>
          {description.label}{" "}
          {description.validators && "required" in description.validators ? (
            <sup>*</sup>
          ) : null}
        </label>
      ) : null}
      <div>
        <CKEditor
          editor={ClassicEditor}
          onChange={(event, editor) =>
            setValue(description.name, editor.getData())
          }
          onBlur={(event, editor) =>
            setValue(description.name, editor.getData())
          }
          {...rest}
          data={
            (initialState && initialState[description.name.toString()]) || ""
          }
          id={description.name.toString()}
        />
        {description.help ? (
          <small
            id={`${description.name}Help`}
            className="form-text text-muted"
          >
            {description.help}
          </small>
        ) : null}
        <ShowError errors={errors} field={description.name.toString()} />
      </div>
    </div>
  );
};

const PendingChangesModal = ({ show, pending, setShow, nextAction }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Popup
      show={show}
      setShow={setShow}
      title={t("Attention!")}
      description={
        <>
          <p>{t("The following entries have unsaved changes!")}</p>
          {pending.slice(0,10).map((change, idx) => (
            <p key={idx}>
              <strong>{change.name}</strong>
            </p>
          ))}
        </>
      }
      footer={
        <Box sx={{ display: "flex", gap: 1, marginBottom: 2 }}>
          {nextAction === "submit" ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                const formElem = document.getElementById(
                  "form"
                ) as HTMLFormElement;
                if (formElem) {
                  formElem.submit();
                }
              }}
              startIcon={<FontAwesomeIcon icon={faCheck} />}
            >
              {t("Confirm")}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate(nextAction)}
              startIcon={<FontAwesomeIcon icon={faCheck} />}
            >
              {t("Confirm")}
            </Button>
          )}
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setShow(false)}
            startIcon={<FontAwesomeIcon icon={faWindowClose} />}
          >
            {t("Cancel")}
          </Button>
        </Box>
      }
    />
  );
};

const MultiSelectField = <T extends FieldValues>({
  description,
  initialState,
  setShow,
  setPending,
  setHasErrors,
}: FieldProps<T, MultiSelectFieldDescription<T>>) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<T>();
  const zeroRow = useMemo(
    () => ({
      id: 0,
      name: "",
      data: [{ id: 0.1, from: null, to: null, percentage: 100 }],
    }),
    []
  );

  const customValueRenderer = () => t("Add");

  const [selectedValues, setSelectedValues] = useState<any[]>([zeroRow]);

  const initialMembers = useRef<any[]>();
  if (!initialMembers.current) {
    initialMembers.current = initialState?.members;
  }

  useBeforeunload(pendingChanges);

  function pendingChanges(event?: any) {
    if (event) {
      event.preventDefault();
    }
    if (initialMembers?.current) {
      const members = initialMembers?.current.map((member) =>
        JSON.stringify(member)
      );
      const result: any[] = selectedValues?.filter(
        (data) => !members.includes(JSON.stringify(data)) && data.id !== 0
      );
      if (result.length && setShow && setPending) {
        setPending(result);
        setTimeout(() => setShow(true), 0);
      }
    }
  }

  const [oldQuery, setOldQuery] = useState("");

  const onSearch = async (
    options: Option[],
    query: string
  ): Promise<Option[]> => {
    if (query.length >= 3 && query !== oldQuery) {
      setOldQuery(query);
      const results = await description.onSearch(query);
      return results
        .filter((res) => !selectedValues.map((val) => val.name).includes(res))
        .map((result) => ({ label: result, value: result } as Option));
    }
    return options;
  };

  useEffect(() => {
    if (initialState?.members) {
      setSelectedValues(() => {
        const concerningField = initialState[description.name];
        concerningField.sort((a, b) => a.name.localeCompare(b.name));
        setValue(description.name, concerningField);
        return [zeroRow, ...concerningField];
      });
    }
  }, [description.name, initialState, setValue, zeroRow]);

  function addEntry(data) {
    const newId = selectedValues.sort((a, b) => b.id - a.id)[0].id + 1;
    setSelectedValues((oldValues) => {
      const newVal = [
        {
          id: newId,
          name: data[0].value,
          data: [
            {
              id: Math.round((newId + 0.1) * 10) / 10,
              from: null,
              to: null,
              percentage: 100,
            },
          ],
        },
        ...oldValues.filter(({ id }) => id !== 0),
      ];
      // @ts-ignore
      setValue(description.name, newVal);
      return [...oldValues.filter(({ id }) => id === 0), ...newVal];
    });
    setOldQuery("");
    setCurrentEditRow(newId);
  }

  function changeData(row, field, expanded, data) {
    if (!expanded) {
      const index = selectedValues.findIndex((oldRow) => oldRow.id === row.id);
      const oldRow = selectedValues[index];
      oldRow.data[0][field] = data;
      setSelectedValues((oldData) => {
        oldData.splice(index, 1, oldRow);
        // @ts-ignor        setValue(description.name, oldData);
        return [...oldData];
      });
    } else {
      row[field] = data;
      const pointMap = selectedValues.map((dataPoint) => {
        const dataIndex = dataPoint.data.findIndex(
          (dataRow) => dataRow.id === row.id
        );
        dataPoint.data[dataIndex] = row;
        return dataPoint;
      });
      // @ts-ignore
      setValue(description.name, pointMap);
    }
  }

  const columns = [
    {
      name: t("Name"),
      selector: (row) => row.name,
      cell: (row) =>
        row.id === 0 ? (
          <MultiSelect
            options={[]}
            value={[]}
            onChange={addEntry}
            valueRenderer={customValueRenderer}
            labelledBy="Select"
            hasSelectAll={false}
            filterOptions={onSearch}
          />
        ) : row.data.length > 1 ? (
          <>
            <span className="position-absolute top-50 start-0 translate-middle badge rounded-pill bg-danger">
              {row.data.length}
            </span>
            <span className="tableText" title={row.name}>
              {row.name}
            </span>
            {row.dateNullError && (
              <span
                title={t("Two or more rows have an empty or overlapping date.")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="red"
                  className="bi bi-exclamation-triangle"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                  <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                </svg>
              </span>
            )}
            {row.dateOverlapError && (
              <span title={t("Two or more rows have an overlapping date.")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="red"
                  className="bi bi-exclamation-triangle"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                  <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                </svg>
              </span>
            )}
          </>
        ) : (
          <span className="tableText" title={row.name}>
            {row.name}
          </span>
        ),
      filterable: true,
      sortable: true,
      width: "22%",
      allowOverflow: true,
      wrap: true,
    },
    {
      name: t("from"),
      selector: (row) => row.from,
      cell: (row) => {
        const fromDate = row.data.sort((objA, objB) => {
          if (!objA.from && !objB.from) {
            return 0;
          }
          if (!objA.from) {
            return -1;
          }
          if (!objB.from) {
            return 1;
          }
          return moment(objB.from).diff(moment(objA.from));
        })[0].from;
        const momentDate = moment(fromDate);
        return row.data.length <= 1 && currentEditRow === row.id ? (
          <input
            defaultValue={momentDate.format("YYYY-MM-DD")}
            type={"date"}
            className="form-control"
            onChange={(event) =>
              changeData(row, "from", false, event.target.value)
            }
          />
        ) : (
          <span>
            {momentDate.isValid() ? momentDate.format("YYYY-MM-DD") : "-"}
          </span>
        );
      },
      sortable: true,
      width: "21%",
    },
    {
      name: t("to"),
      selector: (row) => row.to,
      cell: (row) => {
        const toDate = row.data.sort((objA, objB) => {
          if (!objA.to && !objB.to) {
            return 0;
          }
          if (!objA.to) {
            return -1;
          }
          if (!objB.to) {
            return 1;
          }
          return moment(objB.to).diff(moment(objA.to));
        })[0].to;
        const momentDate = moment(toDate);
        return row.data.length <= 1 && currentEditRow === row.id ? (
          <input
            defaultValue={momentDate.format("YYYY-MM-DD")}
            type={"date"}
            className="form-control"
            onChange={(event) =>
              changeData(row, "to", false, event.target.value)
            }
          />
        ) : (
          <span>
            {momentDate.isValid() ? momentDate.format("YYYY-MM-DD") : "-"}
          </span>
        );
      },
      sortable: true,
      width: "21%",
    },
    {
      name: t("Percentage"),
      selector: (row) => row.percentage,
      cell: (row) => {
        const perc = row.data.sort((objA, objB) => {
          if (!objA.to && !objB.to) {
            return 0;
          }
          if (!objA.to) {
            return -1;
          }
          if (!objB.to) {
            return 1;
          }
          return moment(objB.to).diff(moment(objA.to));
        })[0].percentage;
        return row.id === 0 ? (
          <span>-</span>
        ) : row.data.length <= 1 && currentEditRow === row.id ? (
          <input
            defaultValue={perc}
            type={"number"}
            step={1}
            min={0}
            max={100}
            className="form-control"
            onChange={(event) =>
              changeData(row, "percentage", false, event.target.value)
            }
          />
        ) : (
          <span>{perc}</span>
        );
      },
      width: "12.5%",
    },
    {
      name: t("Actions"),
      cell: (row) =>
        row.id !== 0 && (
          <div>
            {row.data.length <= 1 ? (
              <div className="btn-group" role="group">
                <Button
                  color="primary"
                  variant={"contained"}
                  onClick={() => addRow(row)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                  </svg>
                </Button>
                <Button
                  color="warning"
                  variant={"contained"}
                  onClick={() => editRow(row)}
                >
                  {currentEditRow === row.id ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>
                  )}
                </Button>
                <Button
                  color="error"
                  variant={"contained"}
                  onClick={() => removeRow(row)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </Button>
              </div>
            ) : (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => addRow(row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                  />
                </svg>
              </Button>
            )}
          </div>
        ),
      width: "20%",
    },
  ];

  const expandedColumns = [
    {
      width: "22%",
      cell: (row) => (
        <>
          {row.dateOrderError && (
            <span title={t("From and to-date are switched.")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="red"
                className="bi bi-exclamation-triangle"
                viewBox="0 0 16 16"
              >
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
              </svg>
            </span>
          )}
        </>
      ),
    },
    {
      selector: (row) => row.from,
      cell: (row) => {
        const momentDate = moment(row.from);
        return currentEditRow === row.id ? (
          <input
            defaultValue={momentDate.format("YYYY-MM-DD")}
            type={"date"}
            className="form-control"
            onChange={(event) => {
              changeData(row, "from", true, event.target.value);
            }}
          />
        ) : (
          <span>
            {momentDate.isValid() ? momentDate.format("YYYY-MM-DD") : "-"}
          </span>
        );
      },
      width: "21%",
    },
    {
      selector: (row) => row.to,
      cell: (row) => {
        const momentDate = moment(row.to);
        return currentEditRow === row.id ? (
          <input
            defaultValue={momentDate.format("YYYY-MM-DD")}
            type={"date"}
            className="form-control"
            onChange={(event) =>
              changeData(row, "to", true, event.target.value)
            }
          />
        ) : (
          <span>
            {momentDate.isValid() ? momentDate.format("YYYY-MM-DD") : "-"}
          </span>
        );
      },
      width: "21%",
    },
    {
      selector: (row) => row.percentage,
      cell: (row) =>
        currentEditRow === row.id ? (
          <input
            defaultValue={row.percentage}
            type={"number"}
            step={1}
            min={0}
            max={100}
            className="form-control"
            onChange={(event) =>
              changeData(row, "percentage", true, event.target.value)
            }
          />
        ) : (
          <span>{row.percentage}</span>
        ),
      width: "12.5%",
    },
    {
      cell: (row) => (
        <div className="btn-group" role="group">
          <Button
            color="warning"
            variant={"contained"}
            onClick={() => editRow(row)}
          >
            {currentEditRow === row.id ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil"
                viewBox="0 0 16 16"
              >
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
              </svg>
            )}
          </Button>
          <Button
            color="error"
            variant={"contained"}
            onClick={() => removeRow(row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </Button>
        </div>
      ),
      width: "20%",
    },
  ];

  const [currentEditRow, setCurrentEditRow] = useState(null);

  function addRow(row) {
    const index = selectedValues.findIndex((oldRow) => oldRow.id === row.id);
    const oldRow = selectedValues[index];
    if (oldRow) {
      const latestRow = oldRow.data.sort((a, b) => b.id - a.id)[0];
      const today = new Date();
      latestRow.to = today.setDate(today.getDate() - 1);
      const newId = Math.round((latestRow.id + 0.1) * 10) / 10;
      const newRow = {
        id: newId,
        from: new Date(),
        to: null,
        percentage: 100,
      };
      oldRow.data = [...oldRow.data, newRow];
      setSelectedValues((oldData) => {
        oldData.splice(index, 1, oldRow);
        // @ts-ignore
        setValue(description.name, oldData);
        return [...oldData];
      });
    }
    runValidators();
  }

  function removeRow(row) {
    setSelectedValues((oldData) => {
      let oldDataX = oldData;
      if (row.id.toString().includes(".")) {
        oldDataX = oldData.map((dataPoint) => {
          const oldDataPoint = dataPoint.data;
          dataPoint.data = oldDataPoint.filter(
            (dataRow) => dataRow.id !== row.id
          );
          return dataPoint;
        });
      }
      const filteredData = oldDataX.filter((data) => data.id !== row.id);
      // @ts-ignore
      setValue(description.name, filteredData);
      return filteredData;
    });
    runValidators();
  }

  function checkDateRangesOverlap(
    dateRanges: { from: any; to: any }[]
  ): boolean {
    return dateRanges.some((range1, i) => {
      const range1From =
        range1.from instanceof Date ? range1.from : new Date(range1.from);
      const range1To =
        range1.to instanceof Date ? range1.to : new Date(range1.to);
      return dateRanges.slice(i + 1).some((range2) => {
        const range2From =
          range2.from instanceof Date ? range2.from : new Date(range2.from);
        const range2To =
          range2.to instanceof Date ? range2.to : new Date(range2.to);
        return (
          (range1.to !== null &&
            range2.from !== null &&
            range1To >= range2From) ||
          (range2.to !== null && range1.from !== null && range2To >= range1From)
        );
      });
    });
  }

  function runValidators() {
    let hasErrors = false;
    selectedValues.forEach((value) => {
      value.data.forEach((entry) => {
        if (entry.from && entry.to) {
          const dateOrderError = new Date(entry.from) >= new Date(entry.to);
          entry.dateOrderError = dateOrderError;
          hasErrors = dateOrderError;
        } else {
          entry.dateOrderError = false;
          hasErrors = false;
        }
      });
      if (value.data.length >= 2) {
        const fromNum = value.data.filter((line) => !line.from).length;
        const toNum = value.data.filter((line) => !line.to).length;
        const dateNullError = fromNum > 1 || toNum > 1;
        value.dateNullError = dateNullError;
        hasErrors = dateNullError;

        const dates = value.data.map(({ from, to }) => ({ from, to }));
        const dateOverlapError = checkDateRangesOverlap(dates);
        value.dateOverlapError = dateOverlapError;
        hasErrors = dateOverlapError;
      }
    });
    if (setHasErrors) {
      setHasErrors(hasErrors);
    }
    return hasErrors;
  }

  function editRow(row) {
    if (currentEditRow === row.id) {
      setCurrentEditRow(null);
    } else {
      setCurrentEditRow(row.id);
    }
    runValidators();
  }

  const ExpandedComponent: React.FC<ExpanderComponentProps<any>> = ({
    data,
  }) => {
    return data.data.length > 1 ? (
      <DataTable
        columns={expandedColumns}
        data={data.data}
        noTableHead
        expandableRows
        expandableRowDisabled={() => true}
        striped
        responsive={false}
      />
    ) : (
      <></>
    );
  };

  const rowPreDisabled = (row) => row.data.length <= 1;
  return (
    <div className="form-group" key={description.name.toString()}>
      <div className="label">{description.label}</div>
      <DataTable
        title={t("Members")}
        columns={columns}
        data={selectedValues}
        pagination
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandableRowDisabled={rowPreDisabled}
        striped
        responsive={false}
        sortFunction={customSort}
      />
    </div>
  );

  function customSort(rows, selector, direction) {
    const rowI = rows.sort((a, b) => {
      const aField = selector(a);
      const bField = selector(b);

      let comparison = 0;

      if (typeof aField === "string" || typeof bField === "string") {
        comparison = aField.localeCompare(bField);
      } else {
        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
    return [
      ...rowI.filter(({ id }) => id === 0),
      ...rowI.filter(({ id }) => id !== 0),
    ];
  }
};

const SelectField = <T extends FieldValues>({
  description,
}: FieldProps<T, SelectFieldDescription<T>>) => {
  return (
    <SelectElement
      label={description.label}
      key={description.name}
      name={description.name}
      helperText={description.help}
      required={"required" in (description?.validators ?? {})}
      options={zip(
        description.options,
        description.labels
          ? description.labels
          : description.options.map((o) => t(o))
      ).map(([value, label], i) => ({ value, label }))}
      valueKey="value"
      labelKey="label"
    />
  );
};

const RadioButtonField = <T extends FieldValues>({
  description,
}: FieldProps<T, RadioButtonFieldDescription<T>>) => (
  <RadioButtonGroup
    options={description.options.map((o) => ({ label: t(o), id: o }))}
    name={description.name}
    helperText={description.help}
    disabled={description.disabled}
    required={"required" in (description?.validators ?? {})}
  />
);

const CheckBoxField = <T extends FieldValues>({
  description,
}: FieldProps<T, CheckBoxFieldDescription<T>>) => (
  <CheckboxElement
    label={description.label}
    key={description.name}
    name={description.name}
    helperText={description.help}
    required={"required" in (description?.validators ?? {})}
  />
);

const FormTextField = <T extends FieldValues>({
  description,
}: FieldProps<T, TextFieldDescription<T>>) => (
  <TextFieldElement
    fullWidth={description.fullWidth ?? true}
    label={description.name}
    key={description.name}
    name={description.name}
    type={description.type}
    helperText={description.help}
    required={"required" in (description?.validators ?? {})}
  />
);

export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type CreateProps<T extends FieldValues> = {
  description: FieldDescriptionType<T>;
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
  register: UseFormRegister<T>;
  errors: any;
  initialState?: DefaultValues<T>;
  setShow: (a: any) => any;
  setPending: (a: any) => any;
  setHasErrors?: (a: any) => any;
  control: any;
};

const Field = <T extends FieldValues>({
  description,
  ...props
}: CreateProps<T>) => {
  switch (description.type) {
    case "multicheckbox":
      return <MultiCheckBoxField description={description} {...props} />;
    case "checkbox":
      return <CheckBoxField description={description} {...props} />;
    case "radiobutton":
      return (
        <RadioButtonField
          description={description as RadioButtonFieldDescription<T>}
          {...props}
        />
      );
    case "multiselect":
      return (
        <MultiSelectField
          description={description as MultiSelectFieldDescription<T>}
          {...props}
        />
      );
    case "select":
      return <SelectField description={description} {...props} />;
    case "html":
      return (
        <HtmlEditorField
          description={description as HtmlEditorFieldDescription<T>}
          {...props}
        />
      );
    default:
      return <FormTextField description={description} {...props} />;
  }
};

function pendingChanges(
  initialMembers,
  setPending,
  setNextAction,
  setShow,
  getValues,
  nextAction,
  event,
  navigate?
) {
  if (event) {
    event.preventDefault();
  }
  if (initialMembers?.current) {
    const members = initialMembers?.current.map((member) =>
      JSON.stringify(member)
    );
    const result = getValues().members.filter(
      (data) => !members.includes(JSON.stringify(data)) && data.id !== 0
    );
    if (result.length) {
      setPending(result);
      setNextAction(nextAction);
      setTimeout(() => setShow(true), 0);
    } else {
      navigate(nextAction);
    }
  } else {
    navigate(nextAction);
  }
}

const Spinner: React.FC<{ visible: boolean; msg: string }> = ({
  visible,
  msg,
}) =>
  visible ? (
    <div className="alert alert-primary" role="alert">
      <div className="spinner-border" role="status">
        <span className="sr-only">{msg}</span>
      </div>
      {msg}
    </div>
  ) : null;

const ShowError: React.FC<{ errors: any; field: string }> = ({
  errors,
  field,
}) => {
  if (field in errors) {
    return (
      <p className="text-danger">
        {errors[field]?.message ?? errors[field]?.type}
      </p>
    );
  } else {
    return null;
  }
};

const ActionButton: React.FC<
  FormAction & {
    actionType: "submit" | "cancel";
    disabled: boolean;
    getValues: any;
    initialState: any;
    setPending: (a: any) => any;
    setShow: (a: any) => any;
    setNextAction: (a: any) => any;
    navigate?: any;
  }
> = ({
  label,
  icon,
  nextRoute,
  actionType,
  disabled,
  getValues,
  initialState,
  setPending,
  setShow,
  setNextAction,
  navigate,
}) => {
  const initialMembers = useRef<any[]>();
  if (!initialMembers.current) {
    initialMembers.current = initialState?.members;
  }

  if (actionType === "submit") {
    return (
      <Button
        type={"submit"}
        variant={"contained"}
        color={"primary"}
        startIcon={icon ? <FontAwesomeIcon icon={icon} /> : null}
        disabled={disabled}
      >
        {` ${label}`}
      </Button>
    );
  } else {
    return (
      <Button
        type="button"
        onClick={(e) => {
          pendingChanges(
            initialMembers,
            setPending,
            setNextAction,
            setShow,
            getValues,
            nextRoute === "back" ? -1 : nextRoute ?? "/",
            e,
            navigate
          );
        }}
        startIcon={icon ? <FontAwesomeIcon icon={icon} /> : null}
        variant={"contained"}
        color="secondary"
      >
        {label}
      </Button>
    );
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toast: React.FC<{ message: string; header?: string }> = ({
  message,
  header,
}) => {
  return (
    <div
      className="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <img src="..." className="rounded mr-2" alt="..." />
        <strong className="mr-auto">{header ?? "Info"}</strong>
        <small className="text-muted">11 mins ago</small>
        <button
          type="button"
          className="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body">{message}</div>
    </div>
  );
};

export const BuildForm = <R, T extends FieldValues>(
  descriptor: FormDescription<T> & { Presenter?: React.FC<R> }
) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { signout } = useContext(AuthContext);
  const [result, setResult] = useState<R | null>();
  const [isPending, setPending] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  let {
    fields,
    name,
    submitAction,
    cancelAction,
    Presenter,
    submitHandler,
    pendingMessage,
    initialState,
    hiddenFields,
  } = descriptor;
  const formContext = useForm<T>();
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    control,
    reset,
  } = useMemo(() => formContext, [formContext]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    descriptor.fields
      .filter((d) => d.type === "multiselect")
      .forEach((d) => register(d.name as any));
  }, [descriptor.fields, register]);
  useEffect(() => {
    hiddenFields?.forEach((f) => {
      if (initialState && initialState[f]) {
        setValue(f, initialState[f]);
      }
    });
  }, [initialState]); // eslint-disable-line react-hooks/exhaustive-deps
  const handler = (data: any) => {
    if (data.members) {
      const members = data.members.filter((entry) => entry.id !== 0);
      members.forEach((entry) => {
        delete entry.dateNullError;
        delete entry.dateOverlapError;
        entry.data.forEach((data) => {
          delete data.dateOrderError;
        });
      });
      data.members = members;
    }
    setResult(null);
    setPending(true);
    setSubmitError(null);
    console.log("Pending ON");
    return Promise.resolve()
      .then(() => submitHandler(data))
      .then((success) => {
        if (submitAction.nextRoute) {
          return submitAction.nextRoute === "back"
            ? navigate(-1)
            : navigate(submitAction.nextRoute ?? "/");
        } else {
          setResult(success as R);
        }
      })
      .catch((err) => {
        if (err?.message === "Request failed with status code 401") {
          signout().then(() => navigate("/login"));
        }
        setSubmitError(
          err?.response?.data?.message?.message ??
            err?.message ??
            err.toString()
        );
        console.log("Error!");
        console.log(JSON.stringify(err));
      })
      .finally(() => {
        setPending(false);
      });
  };

  const [pendingData, setPendingData] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [nextAction, setNextAction] = useState("");

  useEffect(() => {
    if (initialState) {
      Object.keys(initialState).forEach((key) => {
        setValue(key as any, initialState && initialState[key]);
      });
      reset(initialState);
    }
  }, [initialState, reset, setValue]);

  return (
    <>
      <div id={`${name.replace(" ", "_")}_form`}>
        <h1>{name}</h1>
        {result ? (
          <div className="alert alert-success" role="alert">
            {Presenter ? <Presenter {...result} /> : JSON.stringify(result)}
          </div>
        ) : null}
        {submitError ? (
          <div className="alert alert-danger" role="alert">
            {submitError}
          </div>
        ) : null}
        <Spinner visible={isPending} msg={pendingMessage ?? t("sending")} />
        <FormContainer
          defaultValues={initialState}
          onSuccess={handler}
          formContext={formContext}
        >
          {/*
          <pre>defaultValues: {JSON.stringify(initialState, null, 2)}</pre>
          <pre>Current State {JSON.stringify(getValues(), null, 2)}</pre>
      */}
          {initialState && (
            <input
              type="hidden"
              value={initialState._id}
              {...register("_id" as any)}
              key="test"
            />
          )}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {fields.map((f) => (
              <Grid {...(f.sizes ?? { xs: 12 })}>
                <Field
                  setValue={setValue}
                  getValues={getValues}
                  description={f}
                  register={register}
                  errors={errors}
                  initialState={initialState}
                  key={f.name.toString()}
                  setShow={setShow}
                  setPending={setPendingData}
                  control={control}
                  setHasErrors={setHasErrors}
                />
              </Grid>
            ))}

            {/*fields.filter(f => f.validators && 'required' in f.validators).length > 0 ?
            <p className="text-info"><small><sup>*</sup>{t("required")}</small></p> : null*/}
            {/*<p>isDirty={isDirty ? "Yes":"No"}, isValid={isValid ? "Yes":"No"}, isPending={isPending ? "Yes":"No"}</p> */}
            <Grid sx={{ mt: 1 }}>
              <ActionButton
                {...submitAction}
                actionType="submit"
                disabled={hasErrors || isPending}
                getValues={getValues}
                initialState={initialState}
                setShow={setShow}
                setPending={setPendingData}
                setNextAction={setNextAction}
              />

              {cancelAction ? (
                <span>
                  {" "}
                  <ActionButton
                    {...cancelAction}
                    actionType="cancel"
                    disabled={false}
                    getValues={getValues}
                    initialState={initialState}
                    setShow={setShow}
                    setPending={setPendingData}
                    setNextAction={setNextAction}
                    navigate={navigate}
                  />
                </span>
              ) : null}
            </Grid>
          </Grid>
        </FormContainer>
      </div>

      <PendingChangesModal
        show={show}
        setShow={setShow}
        pending={pendingData}
        nextAction={nextAction}
      />
    </>
  );
};
