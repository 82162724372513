import { faSearchPlus, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { TUploadResult } from "../../../server/models/types";

import { ModalLoader, Render } from "../components/helper-components";
import { fileUpload } from "../services/data-service";

import { AccordionDetails, AccordionSummary, Accordion, Box, Button, Alert } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import FileUploadMui from "react-material-file-upload";



interface FormInput {
  files: [];
}




const Result: React.FC<{ data: TUploadResult }> = ({
  data: { entries, savedEntries, errorEntries, transfersWithoutAddress },
}) => {
  const { t } = useTranslation();
  return (
    <>
      <FontAwesomeIcon icon={faThumbsUp} />

      <ul>
        <li>
          {t("Processed Entries")}: {entries}
        </li>
        <li>
          {t("Saved Entries")}: {savedEntries}
        </li>
        <li>
          {t("Number of Entries with Errors")}: {errorEntries?.length}
          <Accordion>
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faSearchPlus} />}
              aria-controls="error-entries-content"
              id="error-entries-header"
            >
              {t("Show Details")}
            </AccordionSummary>
            <AccordionDetails>
              <div className="card card-body">
                <ul>
                  {errorEntries?.map((e, index) => (
                    <li key={index}>{JSON.stringify(e)}</li>
                  ))}
                </ul>
              </div>
            </AccordionDetails>
          </Accordion>
        </li>

        <Render when={transfersWithoutAddress ? true : false}>
          <li>
            {t("Missing Organisation")}: {transfersWithoutAddress?.length}
            <Accordion>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faSearchPlus} />}
                aria-controls="missing-org-content"
                id="missing-org-header"
              >
                {t("Show Details")}
              </AccordionSummary>
              <AccordionDetails>
                <div className="card card-body">
                  <ul>
                    {transfersWithoutAddress?.map((e, index) => (
                      <li key={index}>{JSON.stringify(e)}</li>
                    ))}
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          </li>
        </Render>
      </ul>
    </>
  );
};

export const FileUpload = ({url , name}:{ url: string; name: string; })  => {
  const { control, handleSubmit } = useForm<FormInput>();
  const {t} = useTranslation();
  const [result, setResult] = React.useState<TUploadResult | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [pending, setPending] = React.useState<boolean>(false);

  const onSubmit = ({ files: file}: FormInput) => {
    setPending(true);
    setError(null);
    setResult(null);
    fileUpload(url)(file as any).then((result) => {
      setResult(result);
    }).catch((err) => {
      setError(err.message);
    }).finally(() => {
      setPending(false);
    })
  };

  return (
    <>
    <h1>{t(name)}</h1>
    <Box
      component="form"
      sx={{ paddingTop: 1 }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >

      <ModalLoader isPending={pending} />
      { result && <Result data={result} />}
      { error && <Alert severity="error">{error}</Alert> }
      <Controller
        render={({ field: { value, onChange } }) => (
          <FileUploadMui
            value={value}
            onChange={onChange}
            multiple={false}
            accept={["text/csv"]}
            title={t("Click to select or drag and drop a CSV file. (Max size: 50MB)")}
            buttonText={t("Select a File")}
            maxSize={52428800}
            buttonProps={{
              variant: "outlined",
              sx: {backgroundColor: 'var(--mui-palette-primary-main)'}
            }}
            typographyProps={{
              variant: "body2",
              color: "textPrimary"
            }}
          />
        )}
        control={control}
        name="files"
      />
      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </Box>
    </>
  );
};



export default FileUpload;
