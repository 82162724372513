import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {faNewspaper} from "@fortawesome/free-solid-svg-icons";
import "./blog.css";
import {
  addBlog,
  deleteBlog,
  getBlogData,
  updateBlog,
} from "../../services/data-service";
import { FormDescription } from "../../components/form-builder/field-types";
import { BuildForm } from "../../components/form-builder/mui-element-factory";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  ButtonGroup,
  Button,
  Divider,
} from "@mui/material";
import { SwitchButton } from "../../components/switch-button/switchButton";

export type Blog = {
  _id?: string;
  titleGerman: string;
  titleEnglish: string;
  contentGerman: string;
  contentEnglish: string;
  createDate: Date;
  isActive: boolean;
};

export type TBlogResult = Blog[];

const Spinner: React.FC<{ visible: boolean; msg: string }> = ({
  visible,
  msg,
}) =>
  visible ? (
    <div className="alert alert-primary" role="alert">
      <div className="spinner-border" role="status">
        <span className="sr-only">{msg}</span>
      </div>{" "}
      {msg}
    </div>
  ) : null;

export const BlogPage = () => {
  const { t, i18n } = useTranslation();
  const [editorData, setEditorData] = useState<Blog>({
    isActive: false,
  } as Blog);
  const [existigBlogData, setExistingBlogData] = useState<TBlogResult>();
  const [isPending, setIsPending] = useState(false);

  const load = () => {
    setIsPending(true);
    getBlogData()
      .then((entries) => setExistingBlogData(entries))
      .finally(() => {
        setIsPending(false);
        setEditorData({ isActive: false } as Blog);
      });
  };

  const editBlogFormDecription = useMemo<FormDescription<Blog>>(
    () => ({
      fields: [
        {
          name: "titleGerman",
          type: "text",
          label: t("Title (German)"),
          validators: { required: "required" },
        },
        {
          name: "contentGerman",
          type: "html",
          label: t("Content (German)"),
          validators: { required: "required" },
        },
        {
          name: "titleEnglish",
          type: "text",
          label: t("Title (English)"),
          validators: { required: "required" },
        },
        {
          name: "contentEnglish",
          type: "html",
          label: t("Content (English)"),
          validators: { required: "required" },
        },
        {
          name: "isActive",
          type: "checkbox",
          label: t("is active?"),
        },
      ],
      name: t(editorData._id ? "Edit Blog Entry" : "New Blog Entry"),
      submitHandler: (blog_) =>
        (blog_._id ? updateBlog(blog_) : addBlog(blog_)).then(load),
      submitAction: {
        label: editorData._id ? t("Edit Blog Entry") : t("Create Blog Entry"),
        icon: faNewspaper,
      },
      hiddenFields: ["_id"],
    }),
    [editorData, t]
  );

  useEffect(load, []);

  const onDeleteBlog = (id: string) => {
    deleteBlog(id.toString()).then(load);
  };

  return (
    <>
      <Spinner visible={isPending} msg={t("Loading Data")} />
      <Card>
        <CardHeader title="Blogs & News" />
        <CardContent>
          <BuildForm
            {...editBlogFormDecription}
            initialState={editorData}
          ></BuildForm>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Bestehende Einträge" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} md={6}>
              <h5>Deutsch</h5>
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <h5>English</h5>
            </Grid>
          </Grid>
          {existigBlogData?.map((val) => (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={5} md={5}>
                  <Card>
                    <CardHeader
                      title={
                        new Date(val.createDate).toLocaleDateString(
                          i18n.language,
                          {}
                        ) +
                        " | " +
                        val.titleGerman
                      }
                    />
                    <CardContent>
                      <div
                        dangerouslySetInnerHTML={{ __html: val.contentGerman }}
                      ></div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={5} md={5}>
                  <Card>
                    <CardHeader
                      title={
                        new Date(val.createDate).toLocaleDateString("en", {}) +
                        " | " +
                        val.titleEnglish
                      }
                    />
                    <CardContent>
                      <div
                        dangerouslySetInnerHTML={{ __html: val.contentEnglish }}
                      ></div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={2} md={2}>
                  <ButtonGroup
                    sx={{ marginBottom: ".5em" }}
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      color="error"
                      onClick={() => onDeleteBlog(val?._id ?? "")}
                    >
                      Delete
                    </Button>
                    <Button
                      color="warning"
                      onClick={() => {
                        setEditorData(val);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Edit
                    </Button>
                  </ButtonGroup>
                  <SwitchButton
                    selValue={val.isActive}
                    onlabel={t("Enabled")}
                    offlabel={t("Disabled")}
                    onChange={(enabled: boolean) => {
                      updateBlog({ ...val, isActive: enabled }).then(load);
                    }}
                  ></SwitchButton>
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: 2, marginBottom: 4 }} />
            </>
          ))}
        </CardContent>
      </Card>
    </>
  );
};
