import {
  faUser,
  faSignOutAlt,
  faExclamationTriangle,
  faQuestionCircle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {ReactNode, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../context/auth-context';

import {useTranslation} from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import {Box, Button, CardContent, CardHeader, LinearProgress, Modal, Tooltip, Typography} from '@mui/material';
import {Modules} from "../models/modules";
import {useSettingsStore} from "../context/SettingsStore";
import {ModuleNotFound} from "../helpers/errorCards";


export const ShowError = ({error, onClose, header = 'Error'}) =>
  error ? <Snackbar open={true} autoHideDuration={6000} onClose={onClose}>
    <Alert onClose={onClose} severity="error" sx={{width: '100%'}}>
      {header}: {error}
    </Alert>
  </Snackbar> : null


export const LoginButton: React.FC<{ logoutText: string, loginText: string }> = (texts = {
  logoutText: 'Logout',
  loginText: 'Login'
}) => {
  const {user, isLoggedIn, signout} = useContext(AuthContext);
  const navigate = useNavigate();
  if (isLoggedIn()) {
    return <>
      <ul className="navbar-nav ms-auto">
        <li className="nav-item user-info">
          <span className="navbar-text"><FontAwesomeIcon icon={faUser}/>{user?.username}</span>
        </li>
        <li className="nav-item">
          <span className="nav-link" onClick={() => {
            signout();
            navigate('/')
          }}><FontAwesomeIcon icon={faSignOutAlt}/> {texts.logoutText} </span>
        </li>
      </ul>
    </>
  } else {
    return <>{/*<ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <Link className="nav-link" to="/login">
          <FontAwesomeIcon icon={faSignInAlt} /> {texts.loginText}
        </Link>
      </li>
  </ul>*/}</>
  }
}

export const ErrorCard: React.FC<{ header: string, body: string }> = ({header, body}) => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  return <Card>
    <CardHeader title={(<><FontAwesomeIcon icon={faExclamationTriangle}/> {header}</>)}/>
    <CardContent>
      <p>{body}</p>
      <Button variant="contained" onClick={()=>navigate("/")}>
        {t("error_goToHome")}
      </Button>
    </CardContent>
  </Card>
}

const FallbackError = (errorMessage?: string) => {
  const {t} = useTranslation();
  return <ErrorCard header={t("error_fallback_header")} body={errorMessage ?? t("error_fallback_body")}/>
}

export const IfNoError: React.FC<{ error: string, children: React.ReactNode }> = ({children, error}) => {
  return !error
    ? <>{children}</>
    : (()=> { switch(error) {
        case "Module not available":
          return <ModuleNotFound/>;
        default:
          return FallbackError(error);
      }
    }
)()
}

interface ErrorBoundaryProps {
  fallback?: (error: any) => ReactNode;
  children: ReactNode;
}

interface ErrorBoundaryState {
  error: undefined | any;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {error: undefined};
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {error};
  }

  render() {
    if (this.state.error) {
      if (this.props.fallback) {
        return this.props.fallback(this.state.error);
      } else {
        if (this.state.error.message === "Module not available") {
          return <ModuleNotFound/>
        } else {
          console.error("An unhandled error was caught:", this.state.error)
          return FallbackError(this.state.error.message);
        }
      }
    }

    return this.props.children;
  }
}

export const Render: React.FC<{ when: boolean, children: React.ReactNode }> = ({children, when}) =>
  <>{when ? children : null}</>

export const RenderModule: React.FC<{ module?: Modules, modules?: Modules[], children: React.ReactNode }> = ({children, module, modules}) => {
  const settings = useSettingsStore();

  modules = modules !== undefined ? modules : module !== undefined ? [module] : []
  const enabled = modules.some((module) => settings.isModuleEnabled(module));

  if (!enabled) {
    return <ModuleNotFound/>
  }

  return <>{children}</>
}

export const ModalLoader = ({isPending}) => {
  const {t} = useTranslation()
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #444',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>

      <Modal
        open={isPending}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('Loading Data')}...
          </Typography>
          <LinearProgress/>

        </Box>
      </Modal>
    </div>
  );
}

export type HelpProps = {
  text?: string;
  translatedText?: string;
  arrow?: boolean;
  placement?: "top" | "bottom" | "left" | "right";
}

export const Help = ({text, translatedText, placement = "bottom", arrow = true}: HelpProps) => {
  const {t} = useTranslation()
  return <Tooltip title={translatedText ? translatedText : t(text)}
                  placement={placement}
                  arrow={arrow}
  >
    <span className="d-inline-block">
      <FontAwesomeIcon icon={faQuestionCircle}/>
    </span>
  </Tooltip>
}

export const Info = ({text}) => {
  return <div className="text-right info">
    <FontAwesomeIcon icon={faInfoCircle}/> {text}
  </div>
}

export const InfoTooltip = ({text}) => {
  const {t} = useTranslation()
  return <Tooltip title={t(text)} placement="bottom">
    <span className="d-inline-block">
      <FontAwesomeIcon icon={faInfoCircle}/>
    </span>
  </Tooltip>
}

