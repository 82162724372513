import Config from "../config/settings";
import { getOtherMediaName, getOtherMediaNameForMedia, getOtherOrgName, getOtherOrgNameForOrg } from "../pages/flows/flows-transfers";

export const summariseTransfers = (links: any[], rawNodes: {}, nodes: any[],
  payerSumMap: Map<string, number>) => {
  const sumLinks: any[] = [];
      for(const link of links.sort((a, b) => b.value - a.value)) {
        const targetIsGroup = rawNodes[link.target] === "orgGroup" || rawNodes[link.target] === "mediaGroup";

        const amountSum = payerSumMap.get(targetIsGroup ? link.target : link.source);
        if(targetIsGroup && amountSum) {
          if((link.value / amountSum) > Config.flows.summarise.percentThreshold
          || Config.flows.summarise.minAmountOfFlows > sumLinks.filter(i => i.target === link.target).length) {
            sumLinks.push(link);
          } else {
            const otherIndex = sumLinks.findIndex(i => i.source === getOtherOrgNameForOrg(link.target));
            if (otherIndex > -1) {
              sumLinks[otherIndex].value += link.value;
            } else {
              link.lineStyle.color = Config.flows.sankey.othersFlowColor;
              sumLinks.push({
                ...link,
                source: getOtherOrgNameForOrg(link.target),
              });
            }
          }
        } else if(amountSum) {
          if((link.value / amountSum) > Config.flows.summarise.percentThreshold
          || Config.flows.summarise.minAmountOfFlows > sumLinks.filter(i => i.source === link.source).length) {
            sumLinks.push(link);
          } else {
            const otherIndex = sumLinks.findIndex(i => i.target === getOtherMediaNameForMedia(link.source)
            && i.source === link.source);
            if (otherIndex > -1) {
              sumLinks[otherIndex].value += link.value;
            } else {
              link.lineStyle.color = Config.flows.sankey.othersFlowColor;
              sumLinks.push({
                ...link,
                target:  getOtherMediaNameForMedia(link.source),
              });
            }
          }
        }
      }
      const sumNodes: any[] = nodes.filter(n => sumLinks.find(i => i.source === n.name || i.target === n.name));
      const otherOrgs = sumLinks.filter(s => s.source.startsWith(getOtherOrgName())).map(it => {
          return {
            type: "org", name: it.source, label: {}, itemStyle: {
            color: "rgb(81, 146, 202)",
            borderColor: "rgb(81, 146, 202)",}
          };
      });
      if(otherOrgs.length > 0) {
        sumNodes.push(...otherOrgs);  
      }
      const otherMedias = sumLinks.filter(s => s.target.startsWith(getOtherMediaName())).map(it => {
        return {
          type: "media", name: it.target, label: { position: 'insideRight', offset: [-20, 0]}, itemStyle: {
          color: "rgb(81, 146, 202)",
          borderColor: "rgb(81, 146, 202)",}
        };
        });
        if(otherMedias.length > 0) {
          sumNodes.push(...otherMedias);
        }
      return {
        sumLinks,
        sumNodes
      }
}