import { TextField, Autocomplete, Chip, Checkbox } from "@mui/material"
import { useEffect, useState } from "react";
import Config from '../../config/settings';

export type MultiSelectCompProps = {
  options: string[];
  value: string[];
  placeholder: string;
  label: string;
  noOptionsText?: string;
  onChange: (v: string[]) => void
  onSearch?: (v: string) => void;
}

export const MultiSelectComp = ({ options, value, placeholder, label, noOptionsText, onChange, onSearch }: MultiSelectCompProps) => {
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [selectedItems, setSelectedItems] = useState(value);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onFilterOptions = (e: any) => {
    const text = e.target.value;
    if (onSearch) {
      onSearch(text);
    } else {
      const filtered = options.filter(f => f.toLowerCase().includes(text.toLowerCase()));
      setFilteredOptions(filtered);
    }
  }

  useEffect(() => {
    if (onSearch) {
      setFilteredOptions(options);
    }
  }, [options, onSearch])
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      noOptionsText={noOptionsText}
      options={filteredOptions}
      value={selectedItems}
      fullWidth={true}
      onChange={(event, newValue) => {
        setSelectedItems(newValue);
        if (!isDropdownOpen) onChange(newValue)
      }}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => {
        setIsDropdownOpen(false);
        onChange(selectedItems)
      }}
      renderTags={renderAutocompleteTags}
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
        // <MenuItem
        //     key={option}
        //     value={option}
        //     //sx={{ justifyContent: "space-between" }}
        //     {...props}
        // >

        //   <Checkbox checked={selected} size="small" sx={{padding: '5px'}} />
        //   <ListItemText primary={option}  />

        // </MenuItem>
      )}
      renderInput={(params) =>
      (<TextField {...params}
        onChange={(e) => onFilterOptions(e)}
        InputLabelProps={params.InputLabelProps as any}
        InputProps={{ ...params.InputProps }}
        placeholder={placeholder} variant={Config.input.labelVariant}
        label={label} />

      )}
    />)
}

export const renderAutocompleteTags = (value, getTagProps) =>
  value.map((option, index) => (
    <Chip
      sx={{ height: '30px' }}
      size="small"
      label={option}
      color="primary"
      {...getTagProps({ index })}
    />
  ))
