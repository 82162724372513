import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {TabSegmentLabel, TabSegments} from "../../components/tab-segments/tabSegments";
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import {useDispatch, useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Render} from "../../components/helper-components";
import {commonChartOptions} from "../../helpers/helpers";
import {DownloadIcon} from "../../components/icons";
import {useSettingsStore} from "../../context/SettingsStore";
import {Modules} from "../../models/modules";

type TTabs = 'transfers' | 'fundings' | 'mixed'
type TSubTabs = 'flows' | 'timeline' | 'ads'

interface IFlowState {
  activeFlow: 0,
  1,
  2;
  activeSubTabs: {
    transfers: TSubTabs;
    fundings: TSubTabs;
    mixed: TSubTabs;
  }
}

export const defaultBaseModel = (isMobile: boolean, tooltipFormatter: (p: any) => string,
                                 text: string, subtext: string, showMagic = false) => ({
  ...commonChartOptions(isMobile),
  tooltip: {
    trigger: "item",
    triggerOn: "mousemove",
    confine: isMobile,
    formatter: tooltipFormatter,
    textStyle: {
      fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--font-family'),
    },
  },
  title: {
    ...commonChartOptions(isMobile).title,
    text,
    subtext,
  },
  toolbox: {
    show: true,
    orient: "horizontal",
    left: "center",
    top: "bottom",
    feature: {
      mark: {show: true},
      dataView: {show: false, readOnly: false},
      magicType: {show: showMagic, type: ["line", "bar", "stack"]},
      restore: {show: false},
      saveAsImage: {
        show: true,
        type: "png",
        icon: DownloadIcon,
      },
    },
  }
})

export const defaultBaseTimelineModel = (isMobile: boolean, tooltipFormatter: (p: any) => string,
                                         text: string, subtext: string, showMagic = false) => ({
  ...defaultBaseModel(isMobile, tooltipFormatter, text, subtext, showMagic),
  legend: {
    top: 135
  },
  grid: {
    show: true,
    top: 160,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
    formatter: tooltipFormatter,
  },
})

export const defaultSankeyOptions = (isMobile: boolean, tooltipFormatter: (p: any) => string,
                                     text: string, subtext: string, chartModel: any) => ({
  ...defaultBaseModel(isMobile, tooltipFormatter, text, subtext),
  series: {
    label: {
      fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--font-family'),
    },
    type: "sankey",
    top: "140px",
    bottom: "40px",
    layout: "none",
    emphasis: {
      focus: "adjacency",
    },
    ...chartModel,
  },

})

export const flowSlice = createSlice({
  name: 'top',
  initialState: {
    activeFlow: 0,
    activeSubTabs: {
      transfers: 'flows',
      fundings: 'flows',
      mixed: 'flows',
    },
  } as IFlowState,
  reducers: {
    setActiveFlow: (state, action) => {
      state.activeFlow = action.payload;
    },
    setActiveSubTab: (state, action: PayloadAction<{ key: TTabs, value: TSubTabs }>) => {
      state.activeSubTabs[action.payload.key] = action.payload.value;
    }
  }
});

const {setActiveFlow, setActiveSubTab} = flowSlice.actions;

const tabIndex = {
  "transfers": 0, "fundings": 1, "mixed": 2,
  0: "transfers", 1: "fundings", 2: "mixed"
};

export const Flows = () => {
  const {t} = useTranslation();
  const settings = useSettingsStore();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {tab} = useParams()
  const {activeFlow, activeSubTabs} = useSelector((state: any) => state.flowRoot);

  const enabledTabs = [
    settings.isModuleEnabled(Modules.Flows_Transfer),
    settings.isModuleEnabled(Modules.Flows_Fundings),
    settings.isModuleEnabled(Modules.Flows_Mixed)
  ];
  const enabledTabsIndices = enabledTabs.map((v, i) => v ? i : -1).filter(v => v !== -1);
  const tabEnabled = enabledTabs.filter(Boolean).length > 1;

  // useEffect(() => {
  //     const activeSubTab = activeSubTabs[tabIndex[activeFlow]];
  //     navigate(`/flows/${tabIndex[activeFlow]}/${activeSubTab}`);
  // }, [activeFlow]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const path = location.pathname.split("/");
    console.log("path", path);
    const activeSubTab = activeSubTabs[tabIndex[activeFlow]];
    let subTab = tab === undefined || tab === 'undefined' || (path.length > 2 && path[2] !== tabIndex[activeFlow]) ? activeSubTab : tab;
    if (subTab !== activeSubTab && path.length > 2 && path[2] === tabIndex[activeFlow]) {
      dispatch(setActiveSubTab({key: tabIndex[activeFlow], value: subTab}));
    }
    if (path.length < 2 || path[2] !== tabIndex[activeFlow]) {
      navigate(`/flows/${tabIndex[activeFlow]}/${subTab}`,{replace: true});
    } else {
      navigate(`/flows/${tabIndex[activeFlow]}/${subTab}${location.search}`, {replace: true});
    }
  }, [tab, activeFlow]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const path = location.pathname.split("/");
    if (path.length > 2 && tabIndex[path[2]] !== activeFlow) {
      dispatch(setActiveFlow(tabIndex[path[2]]))
    } else {
      //dispatch(setActiveFlow(ac))
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps


  const onTabSegmentChanged = (v: any) => {
    dispatch(setActiveFlow(v));
    //navigate(v === 0 ? `/flows/transfers/${tab}` : v === 1 ? `/flows/fundings/${tab}` : `/flows/mixed/${tab}`);
  }

  const tabLabels: TabSegmentLabel[] = [{title: t("Media Transparency"), tooltip: "about_MT"}
    , {title: t("Fundings"), tooltip: "about_PF"}, {title: t("Mixed"), tooltip: "about_PF_MT"}];
  const tabMobileLabels: TabSegmentLabel[] = [{title: t("Medias"), tooltip: "about_MT"}
    , {title: t("Funding"), tooltip: "about_PF"}, {title: t("Mixed"), tooltip: "about_PF_MT"}];

  return (
    <>
      <Render when={tabEnabled}>
        <TabSegments selValue={activeFlow} onChange={pT => onTabSegmentChanged(pT)}
                     labels={enabledTabsIndices.map(i => tabLabels[i])}
                     mobileLabels={enabledTabsIndices.map(i => tabMobileLabels[i])}/>
      </Render>
      <Card className="values-body-segment">
        <CardContent>
          <Outlet/>
        </CardContent>
      </Card>
    </>
  );
};
