export type LoginInformation = {
  email: string;
  password: string;
};
export interface User {
  _id?: string;
  username: string;
  email: string;
  password: string;
  roles?: Array<string>;
}
export const federalStates = [
  "AT-1",
  "AT-2",
  "AT-3",
  "AT-4",
  "AT-5",
  "AT-6",
  "AT-7",
  "AT-8",
  "AT-9",
] as const;

export const regions = ["AT", ...federalStates] as const;

export type Region = typeof regions[number];
export type FederalStateCode = typeof federalStates[number];

export const transferTypes = [2, 4, 31];
export interface ErrorMessage {
  message: string;
}

export interface GroupType {
  _id?: string;
  name: string;
  isActive?: boolean;
  type: 'P' | 'A' | 'B'
}
