import React from 'react';
import {useTranslation} from "react-i18next";
import {ErrorCard} from "../components/helper-components";

const NotFound = () => {
  const { t } = useTranslation();
  return <ErrorCard header={t("error_notFound_header")} body={t("error_notFound_body")}/>
};

const ModuleNotFound = () => {
  const { t } = useTranslation();
  return <ErrorCard header={t("error_moduleNotFound_header")} body={t("error_moduleNotFound_body")}/>
};

const NoPermission = () => {
  const { t } = useTranslation();
  return <ErrorCard header={t("error_noPermission_header")} body={t("error_noPermission_body")}/>
};

export {NotFound, ModuleNotFound, NoPermission}
