import { useMemo, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import DataTable, { TableColumn } from 'react-data-table-component-with-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../index';
import { IfNoError } from '../components/helper-components';
import { countEntities, getTransfers } from '../services/data-service';
import { IStoredTransfer } from '../../../server/models/transfer';
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';



type TransfersQuery = {
  organisation?: string;
  media?: string;
  period?: number;
  transferType?: number
}

interface TransfersState {
  transfers: IStoredTransfer[];
  needsUpdate: boolean;
  pending: boolean;
  page: number;
  size: number;
  count: number;
  query: TransfersQuery;
}

export const transfersSlice = createSlice({
  name: 'transfers',
  initialState: {
    transfers: [],
    needsUpdate: true,
    page: 1,
    pending: false,
    size: 10,
    count: 0,
    query: {}
  } as TransfersState,
  reducers: {
    setTransfersList: (state, action: PayloadAction<IStoredTransfer[]>) => (
      { ...state, transfers: action.payload, needsUpdate: false }
    ),
    setNeedsUpdate: (state, action: PayloadAction<void>) => ({ ...state, needsUpdate: true }),
    setPage: (state, action: PayloadAction<number>) => ({ ...state, page: action.payload, needsUpdate: true }),
    setSize: (state, action: PayloadAction<number>) => ({ ...state, size: action.payload, needsUpdate: true }),
    setCount: (state, action: PayloadAction<number>) => ({ ...state, count: action.payload }),
    setQuery: (state, action: PayloadAction<TransfersQuery>) => ({ ...state, query: action.payload }),
    setPending: (state, action: PayloadAction<boolean>) => ({ ...state, pending: action.payload })
  }
})

export const { setTransfersList, setNeedsUpdate,
  setPage, setSize, setCount, setPending, setQuery } = transfersSlice.actions;


export const ListTransfers = () => {
  const { transfers, needsUpdate, page, size, count, pending, query } =
    useSelector<AppState, TransfersState>(state => state.transfers)
   const [error, setError] = useState<string>("");
  const [sortBy, setSortBy] = useState<string | null>(null)
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('asc')
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const columns = useMemo<TableColumn<IStoredTransfer>[]>(() => [
    {
      name: t('Organisation'),
      selector: row => row['organisation'],
      sortable: true,
      filterable: true
    },
    {
      name: t('Media'),
      selector: row => row['media'],
      sortable: true,
      maxWidth: "300px",
      filterable: true
    },
    {
      name: t('Period'),
      selector: row => row['period'],
      sortable: true,
      right: false,
      width: "170px",
      filterable: true
    },
    {
      name: t('Transfer Type'),
      selector: row => row['transferType'],
      sortable: true,
      right: false,
      width: "150px",
      filterable: true
    },
    {
      name: t('Federal State'),
      selector: row => row['federalState'],
      sortable: true,
      right: false,
      width: "150px",
      filterable: true
    },
    {
      name: t('Actions'),
      //width: "100px",
      cell: row => <span>
        <Button  aria-label={t('Edit')}
          variant="contained" color="primary" size="small" href={`/transfer/${row._id}`}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
       </span>
    },
  ], [t]);
  const namesToColumns = useMemo(() => ({
    [t("Organisation")]: "organisation",
    [t("Media")]: "media",
    [t("Period")]: "period",
    [t("Transfer Type")]: "transferType",
    [t("Federal State")]: "federalState",
  }), [t])
  /*
  useEffect(()=>{
    countEntities('/zipcodes/count').then(c=>dispatch(setCount(c)))
  },[])
  */
  useEffect(() => {
    if (transfers.length === 0 || needsUpdate) {
      dispatch(setPending(true))
      Promise.all([countEntities('/transfers/count', query),
      getTransfers(page, size, sortBy ?? '', sortOrder, query)])
        .then(([count, transfers]) => {
          dispatch(setCount(count))
          dispatch(setTransfersList(transfers))
        }).catch(err => {
          err.message ? setError(err.message) : setError(err)
        }).finally(() => dispatch(setPending(false)))
    }
  }, [needsUpdate, dispatch, page, query, size, sortBy, sortOrder, transfers.length])


  return <>
    <IfNoError error={error}>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <Button href="/transfer/upload" 
          variant='contained'
          startIcon={<FontAwesomeIcon icon={faFileUpload} />}>{t("Transfer Upload")}</Button>
      </Box>

      <Card>
        <CardHeader title={t("Transfers")}/>
        <CardContent className='no-padding'>
          <DataTable
            columns={columns}
            data={transfers}
            pagination={true}
            paginationServer={true}
            onChangePage={e => dispatch(setPage(e))}
            onChangeRowsPerPage={e => dispatch(setSize(e))}
            paginationTotalRows={count}
            progressPending={pending}
            onFilter={e => {
              dispatch(setQuery({
                ...Object.values(e).reduce((acc, { column, value }) =>
                  ({ ...acc, [namesToColumns[column.name?.toString() ?? "test"]]: value }), {})
              }))
              dispatch(setNeedsUpdate())
            }}
            sortServer={true}
            onSort={(column, sortDirection) => {
              setSortOrder(sortDirection)
              setSortBy(namesToColumns[column.name?.toString() ?? ""])
              dispatch(setNeedsUpdate())
              //console.log(column, sortDirection)
            }}
          />
        </CardContent>
      </Card>
    </IfNoError>
  </>


}

