import {useEffect} from "react";

import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {AppState} from "../..";
import {topSlice, TopTransfers} from "./top-transfers";
import {TopFundings} from "./top-fundings";
import {TabSegmentLabel, TabSegments,} from "../../components/tab-segments/tabSegments";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {useDispatch, useSelector} from "react-redux";
import {Render} from "../../components/helper-components";
import {useSettingsStore} from "../../context/SettingsStore";
import {Modules} from "../../models/modules";

const {setActiveTab} = topSlice.actions;

export const Top = () => {
  const {t} = useTranslation();
  const settings = useSettingsStore();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {activeTab} = useSelector<AppState, any>((state) => state.top);
  useSelector<AppState, any>((state) => state.topFundings);

  useEffect(() => {
    dispatch(setActiveTab(settings.isModuleEnabled(Modules.Top_Transfer) ? 0 : 1));
  }, [settings, dispatch]);

  useEffect(() => {
    const path = location.pathname.split("/");
    if (path.length > 2) {
      dispatch(setActiveTab(path[2] === "fundings" ? 1 : 0));
    } else {
      navigate(activeTab === 0 ? `/top/transfers` : `/top/fundings`, {replace: true});
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTabSegmentChanged = (v: any) => {
    dispatch(setActiveTab(v));
    navigate(v === 0 ? `/top/transfers` : `/top/fundings`);
  };

  const tabLabels: TabSegmentLabel[] = [
    {title: t("Media Transparency"), tooltip: "about_MT"},
    {title: t("Fundings"), tooltip: "about_PF"},
  ];
  const tabMobileLabels: TabSegmentLabel[] = [
    {title: t("Medias"), tooltip: "about_MT"},
    {title: t("Funding"), tooltip: "about_PF"},
  ];

  return (
    <>
      <Render when={settings.isModuleEnabled(Modules.Top_Transfer) && settings.isModuleEnabled(Modules.Top_Fundings)}>
        <TabSegments
          selValue={activeTab}
          onChange={(pT) => onTabSegmentChanged(pT)}
          labels={tabLabels}
          mobileLabels={tabMobileLabels}
        />
      </Render>
      <Card className="values-body-segment">
        <CardContent>
          {activeTab === 0 ? <TopTransfers/> : <TopFundings/>}
        </CardContent>
      </Card>
    </>
  );
};
