import { register } from "../services/security";
import { FormDescription } from "./form-builder/field-types";
import { BuildForm, email } from "./form-builder/mui-element-factory";
import "./register_form.css";
import { User } from "../models/models";
import {
  faArrowCircleLeft,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

const submitHandler = (user: User) => register(user);

const registerFormDescription: FormDescription<User> = {
  fields: [
    {
      name: "username",
      type: "text",
      label: "Username",
      validators: { required: "required" },
      help: "Pick your own username",
    },
    {
      name: "email",
      type: "email",
      label: "Email Address",
      help: "Provide your email address",
      validators: {
        required: "required",
        pattern: { value: email, message: "incorrect_email" },
      },
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      help: "Pick your password",
      validators: {
        required: "required",
        minLength: { value: 8, message: "password_too_short" },
      },
    },
  ],
  name: "Registration",
  submitHandler: submitHandler,
  submitAction: {
    label: "Register new Account",
    icon: faUserPlus,
    nextRoute: "/login_",
  },
  cancelAction: { label: "Cancel", icon: faArrowCircleLeft },
};

//const LoginForm = BuildForm(loginFormDescription)

export const Register = () => (
  <div className="flex-row-center">
    <div className="col-lg-4 col-md-6 col-sm-9 col-xs-12">
      <BuildForm {...registerFormDescription}></BuildForm>
    </div>
  </div>
);
