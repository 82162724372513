import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { IfNoError } from "../components/helper-components";
import { BuildForm } from "../components/form-builder/mui-element-factory";
import { faArrowCircleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FormDescription } from "../components/form-builder/field-types";
import { useDispatch } from "react-redux";
import { setNeedsUpdate } from "./list-users";
import { updateOrganisation, getOrganisation } from "../services/data-service";
import { IStoredOrganisation } from "../../../server/models/organisations";
import { orgTypes } from "./organisation-new";

export const EditOrganisation: React.FC = () => {
  const { id } = useParams();
  const [organisation, setOrganisation] =
    useState<IStoredOrganisation | undefined>(undefined);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editOrgFormDescription = useMemo<FormDescription<IStoredOrganisation>>(
    () => ({
      fields: [
        {
          name: "name",
          type: "text",
          label: t("Name"),
          validators: { required: "required" },
        },
        {
          name: "type",
          type: "select",
          label: t("Type"),
          help: t("Select the type of this organisation"),
          options: orgTypes,
          disabled: false,
        },
        {
          name: "zipCode",
          type: "text",
          label: t("Zip Code"),
          validators: { required: "required" },
        },
        {
          name: "city_de",
          type: "text",
          label: t("City"),
          validators: { required: "required" },
        },
        {
          name: "federalState",
          type: "select",
          label: t("Federal State"),
          options: [
            "EU",
            "AT",
            "AT-1",
            "AT-2",
            "AT-3",
            "AT-4",
            "AT-5",
            "AT-6",
            "AT-7",
            "AT-8",
            "AT-9",
          ],
          labels: [
            t("EU"),
            t("AT"),
            t("AT-1"),
            t("AT-2"),
            t("AT-3"),
            t("AT-4"),
            t("AT-5"),
            t("AT-6"),
            t("AT-7"),
            t("AT-8"),
            t("AT-9"),
          ],
          disabled: false,
        },
        {
          name: "country_de",
          type: "text",
          label: t("Country"),
          validators: { required: "required" },
        },
      ],
      name: "Edit Organisation",
      submitHandler: (organisation_) => {
        updateOrganisation(organisation_).then(() =>
          dispatch(setNeedsUpdate())
        );
      },
      submitAction: {
        label: t("Update Organisation"),
        icon: faSave,
        nextRoute: "/organisation/list",
      },
      cancelAction: {
        label: t("Cancel"),
        icon: faArrowCircleLeft,
        nextRoute: "back",
      },
      hiddenFields: ["_id"],
    }),
    [dispatch, t]
  );
  useEffect(() => {
    getOrganisation(id || "")
      .then(setOrganisation)
      .catch((err) => (err.message ? setError(err.message) : setError(err)));
  }, [id]);
  return (
    <IfNoError error={t(error)}>
      <div className="flex-row-center">
        <div className="col-lg-4 col-md-6 col-sm-9 col-xs-12">
          {organisation && (
            <BuildForm
              {...editOrgFormDescription}
              initialState={organisation}
            ></BuildForm>
          )}
        </div>
      </div>
      <pre>{/*JSON.stringify(group,null,2)*/}</pre>
    </IfNoError>
  );
};
