import React, { useMemo } from "react";
import Slider from '@mui/material/Slider';
import './rangeSlider.scss'
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Render } from "../helper-components";
import FormLabel from '@mui/material/FormLabel';
import slugify from "slugify";

export type RangeSliderProps = {
  min?: number,
  max?: number,
  step?: number,
  periods: number[],
  startPeriod: number,
  endPeriod: number,
  id?: string,
  label: string
  labelFormater?: (period: number) => string,
  onChange: (startPeriod: number, endPeriod: number) => void
  marksFilter?: (period: String) => boolean
}

export const PeriodSlider = ({ startPeriod, endPeriod, step, onChange,
  periods, labelFormater, marksFilter, id, label }: RangeSliderProps) => {
  const [value, setValue] = React.useState([periods.indexOf(startPeriod), periods.indexOf(endPeriod)]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as [number, number]);
  };

  const baseId = useMemo(() => id ? id : slugify(label), [id, label])

  return (
    <Box sx={{ marginTop: "1em" }}>
      <Render when={periods.length > 1}>
        <FormLabel id={`${baseId}-label`}>{label}</FormLabel>
        <Slider
          getAriaLabel={() => 'Period range'}
          value={value}
          min={0}
          max={periods.length - 1}
          step={step}
          aria-labelledby={`${baseId}-label`}
          aria-label={label}
          onChange={handleChange}
          onChangeCommitted={(e, v) => onChange(periods[v[0]], periods[v[1]])}
          valueLabelDisplay="auto"
          valueLabelFormat={idx => labelFormater ? labelFormater(periods[idx]) : periods[idx]}
          getAriaValueText={() => "test"}
          marks={periods.map((p, i) => (
            { value: i, label: labelFormater ? labelFormater(p) : p.toString() })
          ).filter(({ label }) => marksFilter ? marksFilter(label) : true)}
        />
      </Render>
    </Box>
  );
}

export type AmountSliderProps = {
  availableAmounts: [number, number],
  value: [number, number],
  onChange: (value: [number, number]) => void
  label: string,
  id?: string
}


const createLabels = (min: number, max: number, i18n) => {
  const le = Math.round(Math.log10(min))
  const ue = Math.round(Math.log10(max))
  const lb = Math.round(min / Math.pow(10, le - 1)) * Math.pow(10, le - 1)
  const ub = Math.round(max / Math.pow(10, ue - 1)) * Math.pow(10, ue - 1)
  const delta = (ub - lb) / 10

  const labels = [] as { value: number, label: string }[]
  let start = ub
  for (let i = 0; i < 10; i++) {
    labels.push({
      value: start, label: start.toLocaleString(i18n.language, {
        style: 'currency',
        currency: 'EUR',
      })
    })
    start -= delta
  }
  return labels
}



export const AmountSlider = ({ availableAmounts, value, onChange, label, id }: AmountSliderProps) => {
  const [localValue, setLocalValue] = React.useState(value);
  const { i18n } = useTranslation()

  const marks = useMemo(() => createLabels(availableAmounts[0], availableAmounts[1], i18n), [availableAmounts, i18n])

  const valuetext = (value: number) => value.toLocaleString(i18n.language, {
    style: 'currency',
    currency: 'EUR',
  })

  const baseId = useMemo(() => id ? id : slugify(label), [id, label])

  return <Box sx={{ marginTop: "1em" }}>
    <Render when={availableAmounts[1] > 0 && availableAmounts[1] > availableAmounts[0]}>

      <FormLabel id={`${baseId}-label`}>{label}</FormLabel>
      <Slider
        getAriaLabel={valuetext}
        aria-labelledby={`${baseId}-label`}
        aria-label={label}
        min={availableAmounts[0]}
        max={availableAmounts[1]}
        value={localValue}
        marks={marks}
        onChange={(_, v) => setLocalValue(v as [number, number])}
        onChangeCommitted={(_, v) => onChange(v as [number, number])}
        valueLabelDisplay="auto"
        valueLabelFormat={valuetext}
        getAriaValueText={valuetext}
      />

    </Render>
  </Box>
}
