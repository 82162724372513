import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useEffect, useMemo, useState } from "react";
import {
  TOverViewResult,
  TOverViewTransferResult,
  TQuarterlyInfo,
} from "../../../server/controllers/transfer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../index";
import { getOverview } from "../services/data-service";
import { transferTypes } from "../models/models";
import { useTranslation } from "react-i18next";
import DataTable, { TableStyles } from "react-data-table-component-with-filter";
import { getColor, getColorRTR, getPFColor } from "../colors";
import {
  Help,
  Info,
  ModalLoader,
  Render,
  ShowError,
} from "../components/helper-components";

import ReactECharts from "echarts-for-react";
import { ExportToExcel } from "../components/excel-exporter";
import { TOverViewFundingResult } from "../../../server/controllers/funding";

import {
  fundingTypes,
  fundingTypesIndices,
  getFundingValueFromName,
  range,
} from "../helpers/helpers";
import { SwitchButton } from "../components/switch-button/switchButton";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useMediaQuery } from "react-responsive";
import Config from "../config/settings";
import { Fold } from "../components/settings-viewer";
import { DownloadIcon } from "../components/icons";
import { useSettingsStore } from "../context/SettingsStore";
import { Modules } from "../models/modules";

/* eslint react-hooks/exhaustive-deps: 0 */

const toEChartsModel = (data_: TOverViewTransferResult) => [
  ...missingQuarters,
  ...data_
    .map((e) => e.quarters.map((q) => ({ year: e.year, ...q })))
    .reduce((acc, c) => [...acc, ...c], [])
    .sort((a, b) => a.year - b.year),
];

const toYearEChartsModel = (data_: TOverViewTransferResult) =>
  data_
    .map((e) => ({
      year: e.year,
      ...e.quarters.reduce(
        (acc, q) => ({
          ...acc,
          [q.transferType]: acc[q.transferType] + q.total,
          prediction: q.prediction || acc.prediction,
        }),
        { 2: 0, 4: 0, 31: 0, prediction: false }
      ),
    }))
    .sort((a, b) => a.year - b.year);

const toFundingYearEChartsModel = (data_: TOverViewFundingResult) =>
  data_
    .map((item) => {
      return {
        year: item.year,
        ...item.yearsData.reduce(
          (acc, q) => ({
            ...acc,
            [getFundingValueFromName(q.name)]: q.total,
          }),
          fundingTypes.reduce((acc, _, idx) => ({ ...acc, [idx]: 0 }), {})
        ),
      };
    })
    .sort((a, b) => a.year - b.year);

const toTableData = (o: TOverViewTransferResult) =>
  transferTypes.map((tt) => ({
    transferType: tt,
    data: o
      .map((e) => ({
        year: e.year,
        ...e.quarters
          .filter((q) => q.transferType === tt)
          .reduce((acc, q) => ({ ...acc, [`Q${q.quarter}`]: q.total }), {}),
      }))
      .sort((a, b) => a.year - b.year),
  }));

type TTableModel = ReturnType<typeof toTableData>;
type TEChartsModel = ReturnType<typeof toEChartsModel>;
type TYearEChartsModel = ReturnType<typeof toYearEChartsModel>;
type TFundingYearEChartsModel = ReturnType<typeof toFundingYearEChartsModel>;

const missingQuarters =
  Config.skin === "rtr"
    ? []
    : [
        {
          year: 2012,
          quarter: 1,
          transferType: 31,
          entries: 0,
          total: 0,
        },
        {
          year: 2012,
          quarter: 1,
          transferType: 2,
          entries: 0,
          total: 0,
        },
        {
          year: 2012,
          quarter: 1,
          transferType: 4,
          entries: 0,
          total: 0,
        },
        {
          year: 2012,
          quarter: 2,
          transferType: 31,
          entries: 0,
          total: 0,
        },
        {
          year: 2012,
          quarter: 2,
          transferType: 2,
          entries: 0,
          total: 0,
        },
        {
          year: 2012,
          quarter: 2,
          transferType: 4,
          entries: 0,
          total: 0,
        },
      ];

type TOverviewState = {
  echartsModel?: TEChartsModel;
  tableModel: TTableModel;
  overview: TOverViewResult;
  forecastData: TOverViewTransferResult;
  fundingForecastData: TOverViewTransferResult;
  yearChartModel?: TYearEChartsModel;
  fundingYearChartModel?: TFundingYearEChartsModel;
  set: string;
  pending: boolean;
  yearsWithForecast: boolean;
  quartersWithForecast: boolean;
  forcastOnLast: number;
  enablePredictions: boolean;
};

const sortData = (data) =>
  data
    .sort((a, b) => a.year - b.year)
    .map(({ year, quarters }) => ({
      year,
      quarters: quarters.map((q) => ({ ...q, prediction: false })),
    }));

const getRelevantData = (
  data: TOverViewTransferResult,
  consider: number = 4
) => {
  let relevant = data.slice(data.length - consider - 1);
  const needsPrediction =
    relevant[0].quarters.length !==
    relevant[relevant.length - 1].quarters.length;
  if (needsPrediction) {
    relevant = relevant.slice(0, relevant.length - 1);
  }
  return relevant;
};

const getFractions = (data: TOverViewTransferResult, consider: number = 4) => {
  const relevant = getRelevantData(data, consider);
  const init = {
    2: { 1: 0, 2: 0, 3: 0, 4: 0, total: 0 },
    4: { 1: 0, 2: 0, 3: 0, 4: 0, total: 0 },
  };
  const relevantTransferTypes: number[] = [2, 4];
  const sums = relevant.reduce(
    (acc, { quarters: qs }) =>
      qs.reduce(
        (ac, q) =>
          relevantTransferTypes.includes(q.transferType)
            ? {
                ...ac,
                [q.transferType]: {
                  ...ac[q.transferType],
                  [q.quarter]: ac[q.transferType][q.quarter] + q.total,
                  total: ac[q.transferType].total + q.total,
                },
              }
            : ac,
        acc
      ),
    init
  );
  return Object.entries(sums).reduce(
    (ac, [k, v]) => ({
      ...ac,
      [k]: {
        ...[1, 2, 3, 4].reduce(
          (acc, q) => ({ ...acc, [q]: v[q] / v["total"] }),
          {}
        ),
      },
    }),

    {}
  );
};

const addTransferPredictions = (dataSorted, consider: number = 4) => {
  if (
    dataSorted[dataSorted.length - 2].quarters.length !==
    dataSorted[dataSorted.length - 1].quarters.length
  ) {
    const fractions = getFractions(dataSorted, consider);
    const currentYear = dataSorted[dataSorted.length - 1];
    const typePredictions: TQuarterlyInfo[] = [];
    [2, 4].forEach((tType) => {
      const existing = currentYear.quarters.filter(
        ({ transferType: t }) => t === tType
      );
      const runningSums = existing.reduce(
        ({ sum, percentage }, { total, quarter }) => ({
          sum: sum + total,
          percentage: percentage + fractions[tType][quarter],
        }),
        { sum: 0, percentage: 0 }
      );
      const onePercent = runningSums.sum / runningSums.percentage;

      for (let q = existing.length + 1; q < 5; q++) {
        typePredictions.push({
          quarter: q,
          transferType: tType,
          entries: 0,
          total: onePercent * fractions[tType][q],
          prediction: true,
        });
      }
    });
    currentYear.quarters = currentYear.quarters.concat(typePredictions);
    dataSorted[dataSorted.length - 1] = currentYear;
  }
  return dataSorted;
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState: {
    echartsModel: [],
    tableModel: [],
    overview: [] as any,
    forecastData: [] as any,
    fundingForecastData: [] as any,
    yearChartModel: [],
    fundingYearChartModel: [],
    set: "No",
    pending: true,
    yearsWithForecast: false,
    quartersWithForecast: false,
    forcastOnLast: 4,
    enablePredictions: false,
  } as TOverviewState,
  reducers: {
    setOverview: (state, action: PayloadAction<TOverViewResult>) => {
      //console.log("Setting State: ")
      //console.log(action.payload)

      const dataWithPredictions = addTransferPredictions(
        sortData(action.payload.transfers),
        state.forcastOnLast
      );
      return {
        ...state,
        overview: action.payload,
        enablePredictions:
          dataWithPredictions.length > 1 &&
          dataWithPredictions[dataWithPredictions.length - 1].quarters.filter(
            (p) => p.prediction
          ).length > 0,
        forecastData: dataWithPredictions,
        tableModel: toTableData(action.payload.transfers),
        echartsModel: toEChartsModel(
          state.quartersWithForecast
            ? dataWithPredictions
            : action.payload.transfers
        ),
        yearChartModel: toYearEChartsModel(
          state.yearsWithForecast
            ? dataWithPredictions
            : action.payload.transfers
        ),
        fundingYearChartModel: toFundingYearEChartsModel(
          action.payload.fundings
        ),
        pending: false,
        set: "Yes",
      };
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setForecastYears: (state, action: PayloadAction<boolean>) => {
      state.yearsWithForecast = action.payload;
      state.yearChartModel = toYearEChartsModel(
        action.payload ? state.forecastData : state.overview.transfers
      );
    },
    setForecastQuarters: (state, action: PayloadAction<boolean>) => {
      state.quartersWithForecast = action.payload;
      state.echartsModel = toEChartsModel(
        action.payload ? state.forecastData : state.overview.transfers
      );
    },
  },
});

const { setOverview, setPending, setForecastQuarters, setForecastYears } =
  overviewSlice.actions;

export const styles: TableStyles = {
  table: {
    style: {
      background: "inherit",
    },
  },
  rows: {
    style: {
      background: "inherit",
    },
  },
  header: {
    style: {
      display: "inherit",
      background: "inherit",
    },
  },
  headCells: {
    style: {
      fontWeight: "bold",
    },
  },
  headRow: {
    style: {
      background: "inherit",
    },
  },
};

export const Overview = () => {
  const {
    tableModel,
    pending,
    echartsModel,
    yearChartModel,
    fundingYearChartModel,
    yearsWithForecast,
    quartersWithForecast,
    enablePredictions,
    overview,
  } = useSelector<AppState, TOverviewState>((state) => state.overview);
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const settings = useSettingsStore();
  useEffect(() => {
    if (Object.keys(overview).length > 0) return;
    dispatch(setPending(true));
    getOverview()
      .then((d) => dispatch(setOverview(d)))
      .catch((err) => {
        setError(err?.response?.data ?? err.message);
      })
      .finally(() => {
        dispatch(setPending(false));
      });
  }, []);
  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobile = isMobileLandscape || isMobilePortrait;

  const defaultOptions = useMemo(
    () => ({
      legend: {
        top: 135,
      },
      textStyle: {
        fontFamily: getComputedStyle(document.documentElement).getPropertyValue(
          "--font-family"
        ),
        color: getComputedStyle(document.documentElement).getPropertyValue(
          "--charts-text-color"
        ),
      },
      grid: { top: 160 },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: (params) => {
          params = params instanceof Array ? params : [params];
          const includesForecast =
            params.filter((e) => e.data.prediction).length > 0;
          const fmt = new Intl.NumberFormat(i18n.language, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          });
          let d = params
            .map(
              (p) =>
                `${p.marker} ${p.seriesName}${
                  p.data["prediction"] ? "*" : ""
                } <span style="float:right;margin-left:20px;font-size:14px;color:${
                  p.data["prediction"] ? "#aaa" : "#666"
                };font-weight:900">${fmt.format(
                  p.data[p.dimensionNames[p.encode.y]]
                )}</span>`
            )
            .join("<br/>");
          if (params.length > 1) {
            if (!includesForecast) {
              const s = params
                .filter((e) => !e.data.prediction)
                .reduce(
                  (acc, p) => acc + p.data[p.dimensionNames[p.encode.y]],
                  0
                );
              d = `${d}<br/><span style="font-weight:900">${t(
                "Total"
              )}</span><span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${fmt.format(
                s
              )}</span>`;
            } else {
              const s = params.reduce(
                (acc, p) => acc + p.data[p.dimensionNames[p.encode.y]],
                0
              );
              d = `${d}<br/>${t(
                "Total"
              )}*<span style="float:right;margin-left:20px;font-size:14px;color:#aaa;font-weight:900">${fmt.format(
                s
              )}</span>`;
              d += `<br>*) ${t("Forecast")}`;
            }
          } else if (includesForecast) {
            d += `<br>*) ${t("Forecast")}`;
          }
          return `${params[0].name}<br/>${d}`;
        },
      },
      title: {
        show: true,
        textStyle: {
          fontSize: isMobile
            ? Config.mobile.charts.title_font_size
            : Config.desktop.charts.title_font_size,
          height: 80,
          fontWeight: isMobile
            ? Config.mobile.charts.title_font_weight
            : Config.desktop.charts.title_font_weight,
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
          overflow: "truncate",
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--charts-title-color"
          ),
        },
        subtextStyle: {
          fontSize: 14,
          height: 40,
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
          overflow: "truncate",
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--charts-subtitle-color"
          ),
        },
        // padding: [-80,5,5,5],
      },
      toolbox: {
        show: true,
        orient: "horizontal",
        left: "center",
        top: "bottom",
        feature: {
          mark: { show: true },
          dataView: { show: false, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: false },
          saveAsImage: {
            show: true,
            icon: DownloadIcon,
          },
        },
      },
      xAxis: { type: "category" },
      yAxis: {
        axisLabel: {
          formatter: (value) => `${value / 1000000} Mio.`,
        },
      },
      animation: process.env.NODE_ENV !== "development",
    }),
    [t]
  );

  const toCurrency = (field: string) => (row: any) =>
    row[field]?.toLocaleString(i18n.language, {
      style: "currency",
      currency: "EUR",
    });

  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const colorsFromCSS = range(3,6)
      .map(i =>       getComputedStyle(document.documentElement)
      .getPropertyValue(`--echart-color-${i}`)
      .trim(),).filter(c => c.length > 0)
    setColors(colorsFromCSS);
  }, []);

  const getColorFromIndex = (idx: number, count: number) => {
    return colors.length > 0 ? getColorRTR(idx, count, colors, 0.2) : getColor(idx, count); // Return color if available, otherwise empty string
  };

  const options = useMemo(
    () => (tType) => ({
      ...defaultOptions,
      title: {
        ...defaultOptions.title,
        text: t("overview_tranfser_type", { transfer_type: t(`§${tType}`) }),
        subtext:
          t("overview_transfer_subtext") +
          (quartersWithForecast ? " (" + t("includes_forecasts") + ")" : "") +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
      },
      dataset: [
        {
          dimensions: ["year", "total", "quarter", "transferType"],
          source: echartsModel,
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "quarter", "=": 1 },
                { dimension: "transferType", "=": tType },
              ],
            },
            print: false,
          },
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "quarter", "=": 2 },
                { dimension: "transferType", "=": tType },
              ],
            },
            print: false,
          },
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "quarter", "=": 3 },
                { dimension: "transferType", "=": tType },
              ],
            },
            print: false,
          },
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [
                { dimension: "quarter", "=": 4 },
                { dimension: "transferType", "=": tType },
              ],
            },
            print: true,
          },
        },
      ],
      series: [
        {
          type: "bar",
          datasetIndex: 1,
          name: "Q1",
          emphasis: { focus: "series" },
          color: getColorFromIndex(1, 4),
        },
        {
          type: "bar",
          datasetIndex: 2,
          name: "Q2",
          emphasis: { focus: "series" },
          color: getColorFromIndex(2, 4),
        },
        {
          type: "bar",
          datasetIndex: 3,
          name: "Q3",
          emphasis: { focus: "series" },
          color: getColorFromIndex(3, 4),
        },
        {
          type: "bar",
          datasetIndex: 4,
          name: "Q4",
          emphasis: { focus: "series" },
          color: getColorFromIndex(4, 4),
        },
      ],
    }),
    [echartsModel, colors, i18n.language, i18n.language, t, quartersWithForecast]
  );

  const yearsOptions = useMemo(
    () => ({
      ...defaultOptions,
      title: {
        ...defaultOptions.title,
        text: t("Timeline"),
        subtext:
          t("overview_timeline_subtext") +
          (yearsWithForecast ? " (" + t("includes_forecasts") + ")" : "") +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
      },

      dataset: [
        {
          dimensions: ["year", "2", "4"],
          source: yearChartModel,
        },
      ],
      series: [
        {
          type: "bar",
          name: t("§2"),
          emphasis: { focus: "series" },
          color: getColorFromIndex(1, 2),
        },
        {
          type: "bar",
          name: t("§4"),
          emphasis: { focus: "series" },
          color: getColorFromIndex(2, 2),
        },
      ],
    }),
    [yearChartModel, t, colors, i18n.language]
  );

  const fundingYearsOptions = useMemo(
    () => ({
      ...defaultOptions,
      title: {
        ...defaultOptions.title,
        text: t("TimelinePF"),
        subtext:
          t("overview_general_intro_pf") +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
      },
      dataset: [
        {
          dimensions: ["year", ...fundingTypesIndices()],
          source: fundingYearChartModel,
        },
      ],
      series: fundingTypes.map((ft, i) => ({
        type: "bar",
        name: t(ft),
        emphasis: { focus: "series" },
        color: getPFColor(i + 1, fundingTypes.length),
      })),
    }),
    [fundingYearChartModel, t, i18n.language]
  );
  const fundingYearColums = useMemo(
    () => [
      {
        name: t("Year"),
        selector: (row) => row["year"],
        sortable: true,
        width: "5em",
      },
      ...fundingTypes.map((ft, i) => ({
        name: t(ft),
        selector: (row) => row[i],
        sortable: true,
        format: toCurrency(i.toString()),
      })),

      {
        name: t("Total"),
        cell: (row: any) => (
          <span>
            {fundingTypesIndices()
              .reduce((acc, idx) => acc + row[idx], 0)
              .toLocaleString(i18n.language, {
                style: "currency",
                currency: "EUR",
              })}
          </span>
        ),
      },
    ],
    [i18n.language, t]
  );
  const yearColums = useMemo(
    () => [
      {
        name: t("Year"),
        selector: (row) => row["year"],
        sortable: true,
        width: "5em",
      },
      {
        name: t("§2"),
        selector: (row) => row["2"],
        format: toCurrency("2"),
        right: true,
      },
      {
        name: t("§4"),
        selector: (row) => row["4"],
        format: toCurrency("4"),
        right: true,
      },
      {
        name: t("Total"),
        cell: (row: any) => (
          <span>
            {((row["2"] ?? 0) + (row["4"] ?? 0)).toLocaleString(i18n.language, {
              style: "currency",
              currency: "EUR",
            })}
          </span>
        ),
        right: true,
      },
    ],
    [i18n.language, t]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => [
      {
        name: t("Year"),
        selector: (row) => row["year"],
        sortable: true,
        width: "5em",
      },
      {
        name: t("Q1"),
        selector: (row) => row["Q1"],
        format: toCurrency("Q1"),
        right: true,
      },
      {
        name: t("Q2"),
        selector: (row) => row["Q2"],
        format: toCurrency("Q2"),
        right: true,
      },
      {
        name: t("Q3"),
        selector: (row) => row["Q3"],
        format: toCurrency("Q3"),
        right: true,
      },
      {
        name: t("Q4"),
        selector: (row) => row["Q4"],
        format: toCurrency("Q4"),
        right: true,
      },
      {
        name: "Total",
        cell: (row: any) => (
          <span>
            {(
              (row.Q1 ?? 0) +
              (row.Q2 ?? 0) +
              (row.Q3 ?? 0) +
              (row.Q4 ?? 0)
            ).toLocaleString(i18n.language, {
              style: "currency",
              currency: "EUR",
            })}
          </span>
        ),
        right: true,
      },
    ],
    [i18n.language, t]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ShowError error={error} onClose={() => setError("")} />
      <ModalLoader isPending={pending} />
      {/*<pre>{JSON.stringify(echartsModel,null,2)}</pre>*/}

      {/*<pre>{JSON.stringify(echartsModel?.filter(v => v.quarter === 1 && v.transferType === 2), null, 2)}</pre>*/}

      <Card data-test-id="timelineChart" className="card-framed">
        <CardContent>
          <Render when={enablePredictions && Config.overview.forecastEnabled}>
            <Grid
              container
              justifyContent="flex-end"
              data-test-id="timelineForecastToggle"
            >
              <SwitchButton
                simple={true}
                selValue={yearsWithForecast}
                label={
                  <span>
                    {t("Forecast")}{" "}
                    <Help
                      text={t("info_forecast")}
                      aria-label={t("info_forecast")}
                    />
                  </span>
                }
                onChange={(checked: boolean) => {
                  dispatch(setForecastYears(checked));
                }}
                aria-label={t("Forecast")}
              ></SwitchButton>
            </Grid>
          </Render>

          <ReactECharts
            option={yearsOptions}
            style={{
              height: isMobile
                ? Config.mobile.charts.height
                : Config.desktop.charts.height,
            }}
            opts={{ locale: i18n.language.split("-")[0] }}
            aria-label={t("Forecast") + " chart"}
          />
          <Info
            text={t(
              "You can change the diagram by clicking on the toolbar beneath the chart"
            )}
          />
          <Render when={!isMobilePortrait}>
            <Fold
              id={`yearly-overview-table`}
              header={t("Raw Data")}
              fold={true}
              open={false}
              icon={<></>}
              aria-label={t("Raw Data")}
            >
              <DataTable
                title={
                  t("overview_timeline_subtext") +
                  (yearsWithForecast
                    ? " (" + t("includes_forecasts") + ")"
                    : "") +
                  `, ${t("Source")}: KommAustria, ${t(
                    "Date"
                  )}: ${new Date().toLocaleString()}`
                }
                customStyles={styles}
                columns={yearColums}
                data={yearChartModel ?? []}
                actions={
                  <ExportToExcel
                    columns={yearColums}
                    data={yearChartModel ?? []}
                    fileName={`Yearly-Overview}`}
                  />
                }
              />
            </Fold>
          </Render>
        </CardContent>
      </Card>
      <Render when={settings.isModuleEnabled(Modules.Flows_Fundings)}>
        <Card className="card-framed">
          <CardContent>
            <ReactECharts
              option={fundingYearsOptions}
              style={{
                height: isMobile
                  ? Config.mobile.charts.height
                  : Config.desktop.charts.height,
              }}
              opts={{ locale: i18n.language.split("-")[0] }}
              aria-label={t("TimelinePF") + " chart"}
            />
            <Info
              text={t(
                "You can change the diagram by clicking on the toolbar beneath the chart"
              )}
            />
            <Render when={!isMobilePortrait}>
              <Fold
                id={`yearly-overview-table`}
                header={t("Raw Data")}
                fold={true}
                open={false}
                icon={<></>}
                aria-label={t("Raw Data")}
              >
                <DataTable
                  title={t("TimelinePF")}
                  customStyles={styles}
                  columns={fundingYearColums as any}
                  data={fundingYearChartModel ?? []}
                  actions={
                    <ExportToExcel
                      columns={fundingYearColums}
                      data={fundingYearChartModel ?? []}
                      fileName={`Yearly-Overview}`}
                    />
                  }
                />
              </Fold>
            </Render>
          </CardContent>
        </Card>
      </Render>

      {transferTypes.map((tt) => (
        <Card
          key={tt}
          data-test-id={`paymentChart-${tt}`}
          className="card-framed"
        >
          <CardContent>
            <Render when={enablePredictions && Config.overview.forecastEnabled}>
              <Grid
                container
                justifyContent="flex-end"
                data-test-id="timelineForecastToggle"
              >
                <SwitchButton
                  simple={true}
                  selValue={quartersWithForecast}
                  label={
                    <span>
                      {t("Forecast")}{" "}
                      <Help
                        text={t("info_forecast")}
                        aria-label={t("info_forecast")}
                      />
                    </span>
                  }
                  onChange={(checked: boolean) => {
                    dispatch(setForecastQuarters(checked));
                  }}
                ></SwitchButton>
              </Grid>
            </Render>

            <ReactECharts
              option={options(tt)}
              style={{
                height: isMobile
                  ? Config.mobile.charts.height
                  : Config.desktop.charts.height,
              }}
              opts={{ locale: i18n.language.split("-")[0] }}
            />
            <Info
              text={t(
                "You can change the diagram by clicking on the toolbar beneath the chart"
              )}
            />
            <Render when={!isMobilePortrait}>
              <Fold
                id={`paymentTable-${tt}`}
                header={t("Raw Data")}
                fold={true}
                open={false}
                icon={<></>}
              >
                <DataTable
                  title={
                    t("overview_transfer_subtext") +
                    ` ${t(`§${tt}`)} ` +
                    (quartersWithForecast
                      ? " (" + t("includes_forecasts") + ")"
                      : "") +
                    `, ${t("Source")}: KommAustria, ${t(
                      "Date"
                    )}: ${new Date().toLocaleString()}`
                  }
                  customStyles={styles}
                  columns={columns}
                  data={
                    tableModel.find((e) => e.transferType === tt)?.data || []
                  }
                  actions={
                    <ExportToExcel
                      columns={columns}
                      data={
                        tableModel.find((e) => e.transferType === tt)?.data ||
                        []
                      }
                      fileName={`Spendings_${tt}`}
                    />
                  }
                />
              </Fold>
            </Render>
          </CardContent>
        </Card>
      ))}
    </>
  );
};
