/**
 * General settings
 */
export interface GeneralSettings {
  appName: string;
  appOperator: string;
  supportedLanguages: string[];
}
export const defaultGeneralSettings: GeneralSettings = {
  appName: 'Medientransparenz',
  appOperator: 'FH JOANNEUM Gesellschaft mbH',
  supportedLanguages: ['de', 'en']
}
