import "./ads.scss";
import { IfNoError, ModalLoader, Render, } from "../../components/helper-components";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { getAdsList } from "../../services/data-service";
import { Button, Dialog, DialogTitle, SvgIcon } from "@mui/material";
import VideoFileIcon from '@mui/icons-material/VideoFile';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LaunchIcon from '@mui/icons-material/Launch';
import { periodToString } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component-with-filter";
import Config from "../../config/settings";
import { Document, Page, pdfjs } from 'react-pdf';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import { infoSlice, TInfoState } from "../../App";
import { useMediaQuery } from 'react-responsive'
import {
  AdContentType,
  AdsState,
  convertContentTypes,
  FilterType,
  IAdsSortFilter,
  initialState,
  titleCase
} from "./ads-helper";
import { useLocation, } from "react-router-dom";

export const adsSlice = createSlice({
  name: 'ads',
  initialState: initialState,
  reducers: {
    setData: (state: AdsState, action: PayloadAction<any>) => ({
      ...state, data: action.payload,
    }),
    setSortFilter: (state: AdsState, action: PayloadAction<IAdsSortFilter>) => ({
      ...state, sortFilter: action.payload
    }),
    setPending: (state: AdsState, action: PayloadAction<boolean>) => ({
      ...state, pending: action.payload
    }),
    setPage: (state, action: PayloadAction<number>) => ({
      ...state, sortFilter: { ...state.sortFilter, page: action.payload }
    }),
    setSize: (state, action: PayloadAction<number>) => ({
      ...state, sortFilter: { ...state.sortFilter, size: action.payload }
    }),
    setTotalCount: (state, action: PayloadAction<number>) => ({
      ...state, totalCount: action.payload
    }),
    setNeedsUpdate: (state: AdsState) => ({
      ...state, needsUpdate: true
    }), clearNeedsUpdate: (state) => ({
      ...state, needsUpdate: false
    }),
  }
})

const { setData, setPending, setNeedsUpdate,
  clearNeedsUpdate, setPage, setSize, setTotalCount,
  setSortFilter } = adsSlice.actions

const { setAdsCount, setAdsSeen } = infoSlice.actions

export interface AdsProps {
  org?: string[],
  media?: string[];
  from?: string;
  to?: string;
  orgGroupType?: string;
  orgGroups?: string[];
  mediaGroupType?: string;
  mediaGroups?: string[]
}

export const Ads = (props: AdsProps) => {
  const { pending, needsUpdate, data, totalCount, sortFilter } = useSelector<AppState, AdsState>(state => state.ads);
  const [error, setError] = useState('')
  const { t, i18n } = useTranslation()
  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedAd, setSelectedAd] = useState({} as any)
  const dispatch = useDispatch();
  const [adUrl, setAdUrl] = useState('');
  const [lastQuery, setLastQuery] = useState('');
  const [lastProps, setLastProps] = useState('');
  const [currentFilter, setCurrentFilter] = useState(new Map<string, string>())
  const [numPages, setNumPages] = useState(null);
  const [contentTypes, setContentTypes] = useState([] as AdContentType[]);
  const { periods
  } = useSelector<AppState, TInfoState>(state => state.info);

  useEffect(() => {
    if ((props.org || props.media || props.mediaGroups || props.orgGroups) && (JSON.stringify(props) !== lastProps)) {
      setLastProps(JSON.stringify(props));
      if (lastQuery) {
        dispatch(setNeedsUpdate());
      }
    }
  }, [props])// eslint-disable-line react-hooks/exhaustive-deps

  pdfjs.GlobalWorkerOptions.workerSrc = `/pdfjs/build/pdf.worker.js`;

  const handleFilter = (e: any, name: string) => {
    const value = e.target.value;
    currentFilter.set(name, value);
    setCurrentFilter(currentFilter);
    dispatch(setSortFilter({
      ...sortFilter,
      givenFileName: name === t('name') ? value : currentFilter.get(t('name')) ?? "",
      priceFrom: name === "priceFrom" ? value : currentFilter.get("priceFrom") ?? "",
      priceTo: name === "priceTo" ? value : currentFilter.get("priceTo") ?? "",
      contentType: name === t('Type') ? value === t('All') ? "" :
        contentTypes.find(it => it.text === value)?.cType ?? "" : currentFilter.get(t('Type')) ?? "",
      organisation: name === t('Payer') ? value : currentFilter.get(t('Payer')) ?? "",
      media: name === t('Beneficiary') ? value : currentFilter.get(t('Beneficiary')) ?? "",
      from: name === "from" ? value : currentFilter.get("from") ?? "",
      to: name === "to" ? value : currentFilter.get("to") ?? "",
    }));
    dispatch(setNeedsUpdate());
  }

  const fmt = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const keyPress = (e, name) => {
    if (e.keyCode === 13) {
      e.stopPropagation();
      handleFilter(e, name);
    }
  }

  const headerItem = (name: string, filterType: FilterType = FilterType.string,
    contentTypes: AdContentType[] = [], listFilterWidth: string = "100%") => {
    return (
      <div className="ads-table-header-item">
        <div className="ads-table-header-item-title">{name}</div>
        <Render when={filterType === FilterType.string}>
          <label aria-label={t('name')}>
            <TextField className="ads-table-header-item-filter" size="small"
              onClick={(e) => e.stopPropagation()}
              onBlur={(e) => handleFilter(e, name)}
              defaultValue={currentFilter.get(name)}
              onKeyDown={(e) => keyPress(e, name)}
              placeholder={'Filter'} variant={Config.input.labelVariant}
              aria-label={name}
            />
          </label>
        </Render>
        <Render when={filterType === FilterType.list && contentTypes?.length > 0}>
          <label aria-label={t('Filter')}>
            <TextField
              size="small"
              select
              title={contentTypes.find(it => it.cType === sortFilter?.contentType)?.text}
              defaultValue=""
              placeholder={'Filter'}
              variant={Config.input.labelVariant}
              value={contentTypes.find(it => it.cType === sortFilter?.contentType)?.text}
              aria-label="Filter"
              sx={{
                width: listFilterWidth,
                marginTop: '0 !important;'
              }}
              onChange={e => {
                e.stopPropagation()
                handleFilter(e, name)
              }
              }>
              {contentTypes.map(item => (
                <MenuItem key={item.text} value={item.text}>
                  {`${item.text}`}
                </MenuItem>
              ))}
            </TextField>
          </label>
        </Render>
        <Render when={filterType === FilterType.priceRange}>
          <div className='period-filter-container'>
            <label aria-label={t('priceFrom')}>
              <TextField className="ads-table-header-item-filter" size="small"
                aria-label={t("priceFrom")}
                onClick={(e) => e.stopPropagation()}
                onBlur={(e) => handleFilter(e, "priceFrom")}
                defaultValue={currentFilter.get("priceFrom")}
                onKeyDown={(e) => keyPress(e, "priceFrom")}
                placeholder={titleCase(t('from'))} variant={Config.input.labelVariant}
              />
            </label>
          </div>
          <div className='period-filter-container'>
            <label aria-label={t('priceTo')}>
              <TextField className="ads-table-header-item-filter" size="small"
                aria-label={t('priceTo')}
                onClick={(e) => e.stopPropagation()}
                onBlur={(e) => handleFilter(e, "priceTo")}
                defaultValue={currentFilter.get("priceTo")}
                onKeyDown={(e) => keyPress(e, "priceTo")}
                placeholder={titleCase(t('to'))} variant={Config.input.labelVariant}
              />
            </label>
          </div>
        </Render>
        <Render when={filterType === FilterType.periodRange && periods?.length > 0}>
          <div className='period-filter-container'>
            <span className='period-filter-container-span'>{titleCase(t('from'))}</span>
            <label aria-label={t('from')}>
              <TextField
                size="small"
                select
                title={sortFilter && sortFilter.from ? periodToString(+sortFilter.from) : ""}
                defaultValue=""
                placeholder={t('from')}
                variant={Config.input.labelVariant}
                value={sortFilter && sortFilter.from ? sortFilter.from : ""}
                aria-label={t('from')}
                sx={{
                  width: listFilterWidth,
                  marginTop: '0 !important;'
                }}
                onChange={e => {
                  e.stopPropagation()
                  handleFilter(e, "from")
                }
                }>
                {periods.map(item => (
                  <MenuItem key={item} value={item}>
                    {`${periodToString(+item)}`}
                  </MenuItem>
                ))}
              </TextField>
            </label>
          </div>
          <div className='period-filter-container'>
            <span className='period-filter-container-span'>{titleCase(t('to'))}:</span>
            <label aria-label={t('to')}>
              <TextField
                size="small"
                select
                title={sortFilter && sortFilter.to ? periodToString(+sortFilter.to) : ""}
                defaultValue=""
                placeholder={t('to')}
                variant={Config.input.labelVariant}
                value={sortFilter?.to}
                aria-label={t('to')}
                sx={{
                  width: listFilterWidth,
                  marginTop: '0 !important;'
                }}
                onChange={e => {
                  e.stopPropagation()
                  handleFilter(e, "to")
                }}>
                {periods.map(item => (
                  <MenuItem key={item} value={item}>
                    {`${periodToString(+item)}`}
                  </MenuItem>
                ))}
              </TextField>
            </label>
          </div>
        </Render>
        <Render when={filterType === FilterType.combinedColumns}>
          <div className='combined-columns-filter-container'>
            <TextField className="ads-table-header-item-filter" size="small"
              aria-label={t('Payer')}
              onClick={(e) => e.stopPropagation()}
              onBlur={(e) => handleFilter(e, t('Payer'))}
              defaultValue={currentFilter.get(t('Payer'))}
              onKeyDown={(e) => keyPress(e, t('Payer'))}
              placeholder={t('Payer')} variant={Config.input.labelVariant} />
          </div>
          <div className='combined-columns-filter-container'>
            <TextField className="ads-table-header-item-filter" size="small"
              aria-label={t('Beneficiary')}
              onClick={(e) => e.stopPropagation()}
              onBlur={(e) => handleFilter(e, t('Beneficiary'))}
              defaultValue={currentFilter.get(t('Beneficiary'))}
              onKeyDown={(e) => keyPress(e, t('Beneficiary'))}
              placeholder={t('Beneficiary')} variant={Config.input.labelVariant} />
          </div>
          <div className='combined-columns-filter-container'>
            <TextField className="ads-table-header-item-filter" size="small"
              aria-label={t('name')}
              onClick={(e) => e.stopPropagation()}
              onBlur={(e) => handleFilter(e, t('name'))}
              defaultValue={currentFilter.get(t('name'))}
              onKeyDown={(e) => keyPress(e, t('name'))}
              placeholder={t('name')} variant={Config.input.labelVariant} />
          </div>
        </Render>
      </div>
    );
  }

  const isUnderMdWidth = useMediaQuery({ minWidth: Config.ads.minWidthForAllColumns })

  // useMemo wasn´t used because of the customised filter
  // with useMemo the state of sortFilter was the initial one,
  // therefore the filter couldn´t be updated to have multiple
  const columns: TableColumn<any>[] = [
    {
      name: headerItem(t('Type'), FilterType.list, contentTypes, '50px'),
      selector: row => row?.contentType,
      sortable: true,
      width: '60px',
      filterable: false,
      sortField: "contentType",
      cell: row => <span title={row?.contentType}>
        <SvgIcon className="objects-list-item-icon" component={
          Config.ads.videoTypes.includes(row?.contentType) ? VideoFileIcon :
            Config.ads.imageTypes.includes(row?.contentType) ? ImageIcon :
              Config.ads.audioTypes.includes(row?.contentType) ? AudioFileIcon : InsertDriveFileIcon} inheritViewBox /></span>
    },
    {
      name: headerItem(t('Payer'), location?.pathname.startsWith("/ads") ? FilterType.string : FilterType.none),
      selector: row => (row?.metadata?.adActions as any[])?.length > 0 ? (row?.metadata?.adActions as any[])[0].organisation : "",
      sortable: true,
      filterable: false,
      sortField: "metadata.transfer.organisation",
      hide: Config.ads.minWidthForAllColumns,
    },
    {
      name: headerItem(t('Payer') + Config.ads.combinedColumnSeparator + t('Beneficiary') + Config.ads.combinedColumnSeparator + t('name'),
        FilterType.combinedColumns),
      selector: row => row?.metadata?.transfer?.organisation
        + Config.ads.combinedColumnSeparator + row?.metadata?.transfer?.media + Config.ads.combinedColumnSeparator
        + row?.metadata?.givenFileName,
      sortable: true,
      filterable: false,
      sortField: "metadata.?transfer?.media",
      cell: row => <div className="combined-column-outer-container">
        <div className="combined-column-inner-container">{(row?.metadata?.adActions as any[])?.length > 0 ? (row?.metadata?.adActions as any[])[0].organisation : ""} |</div>
        <div className="combined-column-inner-container">{row?.metadata?.adActions?.map(it => it?.media)?.join("\n")} |</div>
        <div className="combined-column-inner-container">{row?.metadata?.givenFileName}</div>
      </div>,
      omit: isUnderMdWidth
    }, {
      name: headerItem(t('Beneficiary'), location?.pathname.startsWith("/ads") ? FilterType.string : FilterType.none),
      selector: row => row?.metadata?.adActions?.map(it => it?.media)?.join("\n"),
      sortable: true,
      filterable: false,
      sortField: "metadata.transfer.media",
      hide: Config.ads.minWidthForAllColumns,
      cell: row => <div className="row-column-sep-n">
        {row?.metadata?.adActions?.map(it => it?.media)?.join("\n")}
      </div>
    },
    {
      name: headerItem(t('name')),
      selector: row => row?.metadata?.givenFileName,
      sortable: true,
      filterable: false,
      sortField: "metadata.givenFileName",
      hide: Config.ads.minWidthForAllColumns,
    },
    {
      name: headerItem(t('price'), FilterType.priceRange),
      selector: row => row?.metadata?.adActions?.map(it => fmt.format(it?.adActionAmount))?.join("\n"),
      sortable: true,
      width: '10%',
      hide: Config.ads.minWidthForAllColumns,
      filterable: false,
      sortField: "metadata.price",
      cell: row => <div className="row-column-sep-n">
        {row?.metadata?.adActions?.map(it => fmt.format(it?.adActionAmount))?.join("\n")}
      </div>
    }, {
      name: headerItem(t('period'), location?.pathname.startsWith("/ads") ? FilterType.periodRange : FilterType.none),
      selector: row => row?.metadata?.adActions?.map(it => periodToString(it?.adActionAmount))?.join("\n"),
      filterable: false,
      sortable: true,
      width: '90px',
      sortField: "metadata.transfer.period",
      cell: row => <div className="row-column-sep-n">
        {row?.metadata?.adActions?.map(it => periodToString(it?.period))?.join("\n")}
      </div>
    }, {
      name: "",
      selector: row => "",
      sortable: false,
      width: '50px',
      cell: row => <span>
        <SvgIcon className="objects-list-open-icon" component={LaunchIcon} inheritViewBox onClick={() => onOpenAds(row)}
        /></span>
    }
  ];
  /*
  useEffect(() => {
    if (selectedAd && selectedAd._id) {
      setAdUrl('');
      setObjectPending(true);
      renderOneAd(selectedAd._id).then(res => {
        const objectUrl = URL.createObjectURL(res);
        setAdUrl(objectUrl);
        setObjectPending(false);
      });
    }
  }, [selectedAd]) // eslint-disable-line react-hooks/exhaustive-deps
  */
  useEffect(() => {
    dispatch(setNeedsUpdate());
  }, [sortFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (needsUpdate) {

      // load data
      dispatch(setPending(true));
      let filter = sortFilter as IAdsSortFilter;
      filter = {
        ...filter, organisation: props.org ? props.org : filter && lastQuery ? filter.organisation : [],
        media: props.media ? props.media : filter && lastQuery ? filter.media : [],
        from: props.from ? props.from : filter && lastQuery ? filter.from : "",
        to: props.to ? props.to : filter && lastQuery ? filter.to : "",
        orgGroups: props.orgGroups ? props.orgGroups : filter?.orgGroups && lastQuery ? filter.orgGroups : [],
        mediaGroups: props.mediaGroups ? props.mediaGroups : filter?.mediaGroups && lastQuery ? filter.mediaGroups : [],
        mediaGroupType: props.mediaGroupType ? props.mediaGroupType : filter?.mediaGroupType && lastQuery ? filter.mediaGroupType : "",
        orgGroupType: props.orgGroupType ? props.orgGroupType : filter?.orgGroupType && lastQuery ? filter.orgGroupType : "",
      };
      if (JSON.stringify(filter) !== lastQuery) {
        setLastQuery(JSON.stringify(filter));
        getAdsList(filter as IAdsSortFilter).then(res => {
          console.log("### ", res.resultFiles);
          dispatch(setTotalCount(res.totalCount));
          dispatch(setAdsCount(res.totalCount));
          dispatch(setData(res.resultFiles));
          dispatch(setAdsSeen(false));
          setContentTypes(convertContentTypes(res.uniqueContentTypes));
        }).catch(
          err => {
            setError(err?.response?.data?.error ?? err.message)
            dispatch(clearNeedsUpdate())
          })
          .finally(() => {
            dispatch(clearNeedsUpdate())
            dispatch(setPending(false))
          })
      } else {
        dispatch(clearNeedsUpdate())
        dispatch(setPending(false));
      }
    } else if (needsUpdate) {
      dispatch(clearNeedsUpdate())
    }
  }, [needsUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = async (column, sortDirection) => {
    if (column && column.sortField) {
      dispatch(setSortFilter({ ...sortFilter, sortBy: column.sortField, sortOrder: sortDirection }));
      dispatch(setNeedsUpdate());
    }
  };

  const onOpenAds = (item: any) => {
    if (item) {
      setSelectedAd(item);
      setAdUrl('/api/files/renderOne/' + item._id);
      setDialogOpen(true);
    }
  }

  const onClearSortFilter = () => {
    setCurrentFilter(new Map<string, string>());
    dispatch(setSortFilter({
      ...sortFilter, givenFileName: "", priceFrom: "", priceTo: "", contentType: "", sortBy: "", sortOrder: "",
      organisation: [], media: [], from: "", to: ""
    }));
    dispatch(setNeedsUpdate());
  }

  const getHasSortFilterApplied = () => {
    return Boolean(sortFilter?.contentType)
      || Boolean(sortFilter?.givenFileName) || Boolean(sortFilter?.sortBy) || Boolean(sortFilter?.priceFrom) || Boolean(sortFilter?.priceTo)
      || Boolean(sortFilter?.media) || Boolean(sortFilter?.organisation) || Boolean(sortFilter?.from)
      || Boolean(sortFilter?.to);
  }

  return (
    <>
      <ModalLoader isPending={pending} />
      <IfNoError error={error}>
        <div>
          <span className="ads-table">
            <DataTable
              title=""
              noDataComponent={<div style={{ margin: '1em', display: 'flex', flexDirection: 'column' }} className="alert alert-warning" role="alert">
                <p style={{ marginBottom: getHasSortFilterApplied() ? 'auto' : '0' }}>{t('No Data found that matches your settings')}.</p>
                <Render when={getHasSortFilterApplied()}>
                  <Button variant="contained" onClick={onClearSortFilter}>{t('delete_ads_table_filter')}</Button>
                </Render>
              </div>}
              progressComponent={<div style={{ marginTop: '1em' }}>
                <p>{t('Loading Data')}.</p>
              </div>}
              columns={columns}
              data={data}
              pagination={true}
              paginationServer={true}
              progressPending={pending}
              onChangePage={e => {
                dispatch(setPage(e));
                dispatch(setNeedsUpdate());
              }}
              onChangeRowsPerPage={e => {
                dispatch(setSize(e));
                dispatch(setNeedsUpdate());
              }}
              paginationTotalRows={totalCount}
              onSort={handleSort}
            />
          </span>
        </div>
      </IfNoError>
      <Dialog onClose={() => {
        setSelectedAd(null);
        setDialogOpen(false);
      }} open={dialogOpen}>
        <DialogTitle>
          <div className="dialog-ad-header">
            {selectedAd?.metadata?.givenFileName}{selectedAd?.metadata?.fileExtension}
            <SvgIcon className="dialog-ad-close-icon" component={CloseIcon} onClick={() => setDialogOpen(false)} inheritViewBox />
          </div>
          {selectedAd?.metadata?.fileDescription}</DialogTitle>
        <div style={{ padding: '16px 24px', maxHeight: '800', maxWidth: '600' }}>
          {/*
          <Render when={objectPending}>
            <Spinner animation="border" role="status">
            </Spinner>
            <span>   {t('Loading Data')}...</span>
          </Render>
          */}
          <Render when={true}>

            <Render when={Config.ads.imageTypes.includes(selectedAd?.contentType)}>
              <img style={{ height: '100%', width: '100%' }} src={adUrl} alt={selectedAd?.metadata?.givenFileName} />
            </Render>
            <Render when={Config.ads.videoTypes.includes(selectedAd?.contentType)}>
              <video controls autoPlay={true} width="100%" height="auto" loop={false} muted={false} >
                <source src={adUrl} type={selectedAd?.contentType} />
              </video>
            </Render>
            <Render when={Config.ads.audioTypes.includes(selectedAd?.contentType)}>
              <audio controls src={adUrl} />
            </Render>
            <Render when={Config.ads.fileTypes.includes(selectedAd?.contentType)}>
              <Document file={adUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages || 0), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </Render>
          </Render>
        </div>
      </Dialog>
    </>
  );
};
