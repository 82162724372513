import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../index";
import { getTop, objectToParams } from "../../services/data-service";
import Config from "../../config/settings";
import qs from "query-string";
import { ExportToExcel } from "../../components/excel-exporter";

import "./top.scss";
import {
  Help,
  IfNoError,
  ModalLoader,
  Render,
} from "../../components/helper-components";
import { TInfoState } from "../../App";

import "rc-slider/assets/index.css";
import {
  commonChartOptions,
  getGroupTypesByType,
  getPaymentType,
  isEqual,
  isObjectSubset,
  isTouchSupported,
  periodToString,
  range,
  toCurrency,
} from "../../helpers/helpers";

import { useLocation, useNavigate } from "react-router-dom";

import { Region, regions } from "../../models/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ToggleSwitch } from "../../components/three-state/three-state-switch";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import { MTATooltip } from "../../components/tooltip";
import "./top-transfers.css";
import {
  SwitchButton,
  SwitchButton2,
} from "../../components/switch-button/switchButton";
import { useMediaQuery } from "react-responsive";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Unstable_Grid2";

import { PeriodSlider } from "../../components/slider/rangeSlider";
import { GroupTypeSelector } from "../../components/group-type-selector";
import { baseReducersWithoutData } from "../../states/base_states";
import { Fold, SettingsViewer } from "../../components/settings-viewer";

import * as echarts from "echarts/core";
import german from "echarts/i18n/langDE-obj";
import { Alert, Box } from "@mui/material";
import DataTable from "react-data-table-component-with-filter";
import {
  TTopResult,
  ITopTransferQuery,
  TableRecord,
  ITopTransferState,
  resultToSunburstModel,
  resultToChartModel,
} from "../../types/top_types";
import { DownloadIcon } from "../../components/icons";
import { useSettingsStore } from "../../context/SettingsStore";
import { getColorRTR } from "../../colors";

echarts.registerLocale("de", german);

/* eslint react-hooks/exhaustive-deps: 0 */

const defaultQuery: ITopTransferQuery = {
  resultType: "org",
  pType: 2,
  x: 10,
  groupType: "",
  onlyGroups: false,
  federalState: "AT",
};

export const topSlice = createSlice({
  name: "top",
  initialState: {
    activeTab: 1,
    pending: true,
    switchState: 2,
    pristine: true,
    query: { ...defaultQuery },
    sunburstModel: [],
    orgGroupType: "",
    mediaGroupType: "",
    onlyOrgGroups: false,
    onlyMediaGroups: false,
    otherMediaDisabled: true, //window.innerWidth < 600,
    needsUpdate: true,
    table: [],
  } as ITopTransferState,
  reducers: {
    ...baseReducersWithoutData<ITopTransferQuery, ITopTransferState>(),
    setData: (state: ITopTransferState, action: PayloadAction<TTopResult>) => ({
      ...state,
      data: action.payload,
      chartModel: resultToChartModel(action.payload),
      sunburstModel: resultToSunburstModel(action.payload, state),
      table: action.payload.top.reduce(
        (acc, e) =>
          e.isGrouping
            ? [
              ...acc,
              ...(e.children ?? []).map((m) => ({
                ...m,
                group: e.organisation,
              })),
            ]
            : [...acc, { ...e, group: "" }],
        [] as TableRecord[]
      ),
      pending: false,
    }),
    setQuery: (
      state: ITopTransferState,
      action: PayloadAction<ITopTransferQuery>
    ) => ({
      ...state,
      query: action.payload,
      needsUpdate: true,
      switchState: Array.isArray(action.payload.pType)
        ? (action.payload.pType as number[]).length > 1
          ? 0
          : +action.payload.pType[0]
        : +action.payload.pType,
    }),
    setQueryRange(
      state: ITopTransferState,
      action: PayloadAction<{ from: number; to: number }>
    ) {
      state.needsUpdate = true;
      state.query.from = action.payload.from;
      state.query.to = action.payload.to;
    },
    setQueryX: (state: ITopTransferState, action: PayloadAction<number>) => ({
      ...state,
      needsUpdate: true,
      query: { ...state.query, x: action.payload },
    }),
    setQueryFederalState: (
      state: ITopTransferState,
      action: PayloadAction<Region>
    ) => ({
      ...state,
      needsUpdate: true,
      query: { ...state.query, federalState: action.payload },
    }),
    setNeedsUpdate: (
      state: ITopTransferState,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      needsUpdate: action.payload,
    }),
    setOtherMediaDisabled: (
      state: ITopTransferState,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      otherMediaDisabled: action.payload,
    }),
    setGroupsEnabled: (
      state: ITopTransferState,
      action: PayloadAction<{ groupType: string; onlyGroups: boolean }>
    ) => ({
      ...state,
      [`${state.query.resultType}GroupType`]: action.payload.groupType,
      needsUpdate: true,
      [`only${state.query.resultType.charAt(0).toUpperCase() +
        state.query.resultType.slice(1)
        }Groups`]: action.payload.onlyGroups,
      query: {
        ...state.query,
        groupType: action.payload.groupType,
        onlyGroups: action.payload.onlyGroups,
      },
    }),
    setOrgGroupType: (
      state: ITopTransferState,
      action: PayloadAction<string>
    ) => ({
      ...state,
      orgGroupType: action.payload,
    }),
    setMediaGroupType: (
      state: ITopTransferState,
      action: PayloadAction<string>
    ) => ({
      ...state,
      mediaGroupType: action.payload,
    }),
    setQueryResultType: (
      state: ITopTransferState,
      action: PayloadAction<"org" | "media">
    ) => ({
      ...state,
      needsUpdate: true,
      query: {
        ...state.query,
        resultType: action.payload,
        groupType:
          action.payload === "org" ? state.orgGroupType : state.mediaGroupType,
      },
    }),
    setGroupType: (
      state: ITopTransferState,
      action: PayloadAction<string>
    ) => ({
      ...state,
      needsUpdate: true,
      query: { ...state.query, groupType: action.payload },
    }),
    setActiveTab: (
      state: ITopTransferState,
      action: PayloadAction<number>
    ) => ({ ...state, activeTab: action.payload }),
  },
});

const {
  setData,
  setQuery,
  setPending,
  setOrgGroupType,
  setMediaGroupType,
  setNeedsUpdate,
  setTouched,
  setGroupsEnabled,
  setQueryRange,
  setQueryFederalState,
  setQueryX,
  setGroupType,
  setQueryResultType,
  saveQuery,
} = topSlice.actions;


export const defaultSunBurtsOptions = (
  isMobile: boolean,
  formatter: (p: any) => string,
  text: string,
  subtext: string,
  chartModel: any
) =>
  isMobile
    ? {
      ...commonChartOptions(isMobile),
      series: {
        type: "sunburst",
        center: ["50%", "50%"],
        nodeClick: false,
        data: chartModel,
        radius: [30, "70%"],
        levels: [
          {},
          {
            r0: "35%",
            r: "90%",
            label: {
              show: true,
              rotate: "radial",
              width: 100,
              status: "normal",
              overflow: "truncate",
              position: "left",
              formatter,
            },
            emphasis: {
              rotate: 0,
              focus: "ancestor",
              label: {
                formatter,
              },
            },
            downplay: {
              label: {
                rotate: "radial",
                formatter,
              },
            },
            itemStyle: {
              borderWidth: 3,
            },
          },
          {
            r0: "90%",
            r: "98%",
            label: {
              show: false,
              position: "outside",
              rotate: 0,
              padding: 3,
              silent: false,
              formatter,
            },
            emphasis: {
              rotate: 0,
              focus: "ancestor",
              label: {
                formatter,
              },
            },
            downplay: {
              label: {
                rotate: 0,
                formatter,
              },
            },
            itemStyle: {
              borderWidth: 3,
            },
          },
        ],
        label: {
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
        },
      },
      animation: process.env.NODE_ENV !== "development",
      title: {
        ...commonChartOptions(isMobile).title,
        text,
        subtext,
      },
    }
    : ({
      grid: {
        top: 400,
      },
      series: {
        type: "sunburst",
        label: {
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
        },
        center: ["50%", "55%"],
        nodeClick: false,
        data: chartModel,
        radius: [30, "70%"],
        levels: [
          {},
          {
            r0: isMobile ? "35%" : "15%",
            r: isMobile ? "90%" : "70%",
            label: {
              show: true,
              rotate: "radial",

              align: "right",
              overflow: "truncate",
              formatter,
            },
            emphasis: {
              rotate: 0,
              focus: "ancestor",
              label: {
                formatter,
              },
            },
            downplay: {
              label: {
                rotate: "radial",
                formatter,
              },
            },
            itemStyle: {
              borderWidth: 3,
            },
          },
          {
            r0: "70%",
            r: "80%",
            label: {
              show: true,
              position: "outside",
              rotate: 0,
              padding: 3,
              silent: false,
              formatter,
            },
            emphasis: {
              rotate: 0,
              focus: "ancestor",
              label: {
                formatter,
              },
            },
            downplay: {
              label: {
                rotate: 0,
                formatter,
              },
            },
            itemStyle: {
              borderWidth: 3,
            },
          },
        ],
      },
      animation: process.env.NODE_ENV !== "development",
      toolbox: {
        show: true,
        orient: "horizontal",
        left: "center",
        top: "bottom",
        feature: {
          mark: { show: true },
          dataView: { show: false, readOnly: false },
          magicType: { show: false, type: ["line", "bar", "stack"] },
          restore: { show: false },
          saveAsImage: {
            show: true,
            icon: DownloadIcon,
          },
        },
      },
      title: {
        ...commonChartOptions(isMobile).title,
        text,
        subtext,
      },
    } as EChartsOption);

export const TopTransfers = () => {
  //const { setGroupsEnabled } = infoSlice.actions
  const { t, i18n } = useTranslation();
  const moduleSettings = useSettingsStore().modules.top_transfer;
  const [error, setError] = useState("");
  const {
    query,
    pending,
    needsUpdate,
    switchState,
    orgGroupType,
    mediaGroupType,
    lastQuery,
    table,
    sunburstModel,
    otherMediaDisabled,
    pristine,
  } = useSelector<AppState, ITopTransferState>((state) => state.top);
  const { periods, groupTypes } = useSelector<AppState, TInfoState>(
    (state) => state.info
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const isMobile = isMobileLandscape || isMobilePortrait;

  const fieldName = (type: "org" | "media", isGrouping?: boolean) => {
    if (type === "org") {
      return isGrouping ? "orgGroups" : "organisations";
    } else {
      return isGrouping ? "mediaGroups" : "media";
    }
  };

  const sunBurstLabelFormatter = ({ name, value, data, status }) => {
    if (name === "Others") {
      name = t(name);
    }
    if (data.percent < 0.45) {
      return " ";
    }
    switch (status) {
      case "emphasis":
        return `${name}\n${value.toLocaleString(i18n.language, {
          style: "currency",
          currency: "EUR",
        })} (${data.percent}%)`;
      case "normal":
        return name.length > 30 ? `${name.substring(0, 29)}...` : `${name}\n`;
      default:
        return status;
    }
  };

  const sunBurstOptions = useMemo(
    () =>
      defaultSunBurtsOptions(
        isMobilePortrait,
        sunBurstLabelFormatter,
        t("top_transfer_title", {
          count: query.x,
          type: t(query.resultType === "org" ? "Payers" : "Recipients"),
          paymentType: t(
            t(
              getPaymentType(query) === "advertising"
                ? "advertising_payments"
                : getPaymentType(query) === "funding"
                  ? "funding_payments"
                  : "advertising_and_funding_payments"
            )
          ),
        }),
        t("top_transfer_sub_title", {
          period:
            query.to !== query.from
              ? `${periodToString(query.from ?? 0)} - ${periodToString(
                query.to ?? 0
              )}`
              : periodToString(query.to ?? 0),
          region: t(query.federalState),
        }) +
        `\n${t("Source")}: KommAustria, ${t(
          "Date"
        )}: ${new Date().toLocaleString()}` +
        `\nLink: ${window.location.href}`,
        sunburstModel
      ),
    [sunburstModel, isMobilePortrait, i18n.language]
  );

  const openDetails_ = ({
    name,
    children,
  }: {
    name: string;
    children?: [];
  }) => {
    const isGrouping = children && children.length > 0;
    // dispatch(flowsActions.setTouched());
    const queryString = objectToParams({
      organisations: [],
      media: [],
      orgGroups: [],
      mediaGroups: [],
      [fieldName(query.resultType, isGrouping)]: [name],
      pType: query.pType,
      from: query.from,
      to: query.to,
      orgGroupType: query.resultType === "org" ? query.groupType : "",
      mediaGroupType: query.resultType === "media" ? query.groupType : "",
    });
    console.log(queryString)
    navigate(`/flows/transfers/flows?${queryString}`, { replace: true });
  };

  useEffect(() => {
    /*
     Setting the default group type to membership if the user has not yet selected a group type
    */
    if (pristine) {
      const preSelectedOrgGroupType = getGroupTypesByType(
        groupTypes,
        "org"
      ).find((g) => g.name === moduleSettings.defaultOrgGroupType);
      if (preSelectedOrgGroupType) {
        dispatch(
          setOrgGroupType(preSelectedOrgGroupType._id?.toString() ?? "")
        );
        dispatch(setGroupType(preSelectedOrgGroupType._id?.toString() ?? ""));
      }
      const preSelectedMediaGroupType = getGroupTypesByType(
        groupTypes,
        "media"
      ).find((g) => g.name === moduleSettings.defaultMediaGroupType);
      if (preSelectedMediaGroupType) {
        dispatch(
          setMediaGroupType(preSelectedMediaGroupType._id?.toString() ?? "")
        );
      }
      dispatch(setTouched());
    }
  }, []);

  useEffect(() => {
    //dispatch(setQuery({ ...query }))
  }, [otherMediaDisabled]);

  useEffect(() => {
    if (periods && periods.length > 0) {
      //console.log("Setting from and to!!!")
      //console.log("Periods: " + JSON.stringify(periods, null, 2))
      const maxPeriodIndex = periods.length - 1;
      const maxPeriod = periods[maxPeriodIndex];
      //console.log("Maxperiod: " + maxPeriod)
      if (!query.from || !query.to) {
        dispatch(
          setQueryRange({
            from: maxPeriod,
            to: maxPeriod,
          })
        );
        //setPeriods([maxPeriodIndex, maxPeriodIndex])
      } else {
        dispatch(setNeedsUpdate(true));
      }
    }
  }, [periods]);
  useEffect(() => {
    if (query.to && query.from && moduleSettings.enabled) {
      const queryString = objectToParams(query);
      const currentQueryStringObj = qs.parse(location.search);
      const queryStringObjFromQueryObject = qs.parse(queryString);
      console.log(
        "isEqual: " +
        isEqual(currentQueryStringObj, queryStringObjFromQueryObject)
      );
      if (!isEqual(currentQueryStringObj, qs.parse(queryString))) {
        navigate(`/top/transfers/?${queryString}`, { replace: true });
      }
      dispatch(setNeedsUpdate(true));
    }
  }, [query]);

  useEffect(() => {
    if (needsUpdate && query.from && query.to) {
      if (lastQuery === undefined || !isEqual(lastQuery, query)) {
        dispatch(saveQuery());
        Promise.resolve(dispatch(setPending(true)))
          .then(() => getTop(query))
          .then((result) => dispatch(setData(result)))
          .catch((err) => {
            setError(err?.response?.data ?? err.message);
          })
          .finally(() => {
            dispatch(setPending(false));
            dispatch(setNeedsUpdate(false));
          });
      } else {
        dispatch(setNeedsUpdate(false));
      }
    }
  }, [needsUpdate, query.from, query.to]);

  useEffect(() => {
    let q = { ...defaultQuery };
    if (location.search) {
      try {
        const queryObj = qs.parse(location.search);
        if (queryObj.from) {
          q.from = parseInt(queryObj.from as string);
        }
        if (queryObj.to) {
          q.to = parseInt(queryObj.to as string);
        }
        if (queryObj.x) {
          q.x = parseInt(queryObj.x as string);
        }
        if (queryObj.pType) {
          const pT = Array.isArray(queryObj.pType)
            ? queryObj.pType
            : [queryObj.pType];
          q.pType = pT.map((p) => parseInt(p as string));
        }
        if (queryObj.groupType) {
          q.groupType = queryObj.groupType as string;
        }
        if (queryObj.onlyGroups) {
          q.onlyGroups = queryObj.onlyGroups === "true";
        }
        if (queryObj.federalState) {
          q.federalState = queryObj.federalState as Region;
        }
        if (queryObj.resultType) {
          q.resultType = queryObj.resultType as "org" | "media";
        }
      } catch (error) {
        console.log("Error parsing query string: " + error);
      }
    }
    if (q["from"] && q["to"]) {
      if (!isObjectSubset(q, query)) {
        dispatch(setQuery(q));
        dispatch(setNeedsUpdate(true));
      }
    }
  }, [location]);

  const updateX = (x: number) => {
    if (query.x !== x) {
      dispatch(setQueryX(x));
    }
  };
  const updateFederalState = (federalState: Region) => {
    if (query.federalState !== federalState) {
      dispatch(setQueryFederalState(federalState));
    }
  };
  const toggleStates = [
    { label: t("Advertising"), value: 2 },
    { label: t("Funding"), value: 4 },
    { label: t("Both"), value: 0 },
  ];

  const colums = useMemo(
    () => [
      ...(query.groupType
        ? [
          {
            name: t("Gruppe"),
            selector: (row) => row["group"],
            sortable: true,
          },
        ]
        : []),
      {
        name: t("Organisation"),
        selector: (row) => row["organisation"],
        sortable: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row["total"],
        sortable: true,
        format: toCurrency("total"),
        right: true,
      },
    ],
    [t, query.groupType]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const Settings = () => {
    return (
      <div className="settings" data-test-id="topPlayerSettings">
        <Grid container spacing={2}>
          <Grid
            xs={8}
            sm={4}
            md={4}
            lg={3}
            xl={3}
            data-test-id="payersOrBeneficiariesToggle"
          >
            <SwitchButton2
              simple={true}
              checked={query.resultType === "media"}
              offlabel={t("Payers")}
              onlabel={t("Recipients")}
              offIconD={Config.switchButton.icons.payerIconD}
              onIconD={Config.switchButton.icons.receiptIconD}
              onChange={(checked: boolean) => {
                dispatch(setQueryResultType(checked ? "media" : "org"));
              }}
              label={t("Payers or Beneficiaries")}
              aria-label={t("Payers or Beneficiaries")}
            />
          </Grid>
          <Grid
            className="col-center-element-without-heading"
            xs={10}
            sm={8}
            md={4}
            lg={3}
            xl={3}
            alignItems={"center"}
            data-test-id="advertisingOrFundingToggle"
            aria-label={t("Advertising or Funding")}
          >
            {/* <div className="label">{t('Advertising or Funding')}</div> */}
            <ToggleSwitch
              values={toggleStates}
              selected={switchState}
              onChange={(pT) =>
                dispatch(setQuery({ ...query, pType: pT === 0 ? [2, 4] : pT }))
              }
              aria-label={t("Advertising or Funding")}
            />
          </Grid>
          <Grid xs={10} sm={4} md={4} lg={3} xl={3}>
            <label aria-label={t("Number of Entries")}>
              <TextField
                id="select-x"
                select
                label={t("Number of Entries")}
                defaultValue=""
                variant={Config.input.labelVariant}
                fullWidth={true}
                value={query.x}
                onChange={(e) => updateX(parseInt(e.target.value))}
                aria-label={t("Number of Entries")}
              //helperText={t("Please select number of entries")}
              >
                {[5, 10, 15, 20].map((nr) => (
                  <MenuItem key={nr} value={nr}>
                    {`Top ${nr}`}
                  </MenuItem>
                ))}
              </TextField>
            </label>
          </Grid>
          <Grid xs={10} sm={6} md={6} lg={3} xl={3} data-test-id="regionToggle">
            <label aria-label={t("Region")}>
              <TextField
                id="select-region"
                select
                label={t("Region")}
                aria-label={t("Region")}
                variant={Config.input.labelVariant}
                fullWidth={true}
                value={query.federalState}
                helperText={t("Restrict to a certain province")}
                onChange={(e) => updateFederalState(e.target.value as Region)}
              >
                {regions.map((r) => (
                  <MenuItem key={r} value={r}>
                    {t(r)}
                  </MenuItem>
                ))}
              </TextField>
            </label>
          </Grid>
          <Render when={groupTypes && groupTypes.length > 0}>
            <Grid xs={10} md={3} xl={3} data-test-id="groupingsToggle">
              <GroupTypeSelector
                id="select-group-type-transfers"
                value={query.groupType}
                type={query.resultType}
                availableGroupTypes={groupTypes}
                onChange={(e) => {
                  dispatch(
                    setGroupsEnabled({
                      groupType: e.target.value,
                      onlyGroups: query.onlyGroups,
                    })
                  );
                }}
                aria-label={t("Group Type Selector")}
              />
            </Grid>
          </Render>
          {(orgGroupType || mediaGroupType) && (
            <Grid
              className="col-center-element-without-heading"
              xs={10}
              sm={4}
              md={3}
              xl={2}
              data-test-id="onlyGroupsToggle"
              sx={{ alignItems: "start" }}
            >
              <SwitchButton
                simple={true}
                selValue={query.onlyGroups}
                label={
                  <div className="label">
                    {t("Only Groups")} <Help text="groups_only_help" />
                  </div>
                }
                onChange={(checked: boolean) => {
                  dispatch(setQuery({ ...query, onlyGroups: checked }));
                }}
                aria-label={t("Only Groups Switch Button")}
              ></SwitchButton>
            </Grid>
          )}
        </Grid>

        <div className="row justify-content-between">
          <div className="col-12" data-test-id="periodRange">
            <PeriodSlider
              label={t("Period")}
              startPeriod={query.from ?? 0}
              endPeriod={query.to ?? 0}
              step={1}
              onChange={(start, end) =>
                dispatch(setQuery({ ...query, from: start, to: end }))
              }
              labelFormater={periodToString}
              periods={periods}
              marksFilter={(period) => period.startsWith("Q1")}
              aria-label={t("Period Slider")}
            ></PeriodSlider>
          </div>
        </div>
      </div>
    );
  };

  //custom colors for echart
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const rtrColors = range(1, 9).map(i => getComputedStyle(document.documentElement)
      .getPropertyValue(`--echart-color-${i}`)
      .trim()).filter(c => c.length > 0)
    const colors = rtrColors.length > 0 ?
      range(0, query.x - 1).map((i) => getColorRTR(i, query.x, rtrColors)) : [];
    setColors(colors);
    //setColors(colorsFromCSS);
  }, [query.x]);

  const theme = useMemo(() =>
    colors.length > 0 ? { color: colors } : {}
    , [colors])

  return (
    <>
      {/*<pre>{JSON.stringify(sunburstModel, null, 2)}</pre>*/}
      {/*<p>Pristine: {pristine ? 'yes':'no'}</p>
    <pre>MediaGroupType: {mediaGroupType}, only: {onlyMediaGroups}</pre>
    <pre>OrgGroupType: {orgGroupType}, only: {onlyOrgGroups}</pre>
<pre>{JSON.stringify(query,null,2)}</pre>*/}

      <ModalLoader isPending={pending} aria-label="Loading Modal" />
      <IfNoError error={error}>
        <div className={isMobile ? "top-settings-mobile" : "top-settings"}>
          <SettingsViewer id="top-settings" fold={true} open={!isMobile}>
            <Settings />
          </SettingsViewer>
        </div>
        <div className="row">
          <Render when={sunburstModel.length <= 1}>
            <Alert severity="warning">
              {t("No Data found that matches your settings")}
            </Alert>
          </Render>

          <div className="the-sunburst-echart">
            <Render when={sunburstModel.length > 1}>
              <div className="col-12">
                <Box sx={{ marginY: 3 }}>
                  <div className="info text-end" data-test-id="tooltipHint">
                    <FontAwesomeIcon icon={faInfoCircle} />{" "}
                    {t(
                      `${isTouchSupported() ? "Double click" : "Click"
                      } on the chart to get more information`
                    )}
                  </div>
                </Box>
                <MTATooltip openLink={openDetails_} aria-label={t("Tooltip")}>
                  <ReactECharts
                    option={sunBurstOptions}
                    style={{ height: 900 }}
                    opts={{ locale: i18n.language.split("-")[0] }}
                    aria-label={t("Sunburst Chart")}
                    theme={theme}
                  />
                </MTATooltip>
              </div>
            </Render>
          </div>
        </div>
        <Fold
          id={`top-transfer-table`}
          header={t("Raw Data")}
          fold={true}
          open={false}
          icon={<></>}
          aria-label={t("Foldable Section")}
        >
          <DataTable
            title={
              t("top_transfer_title", {
                count: query.x,
                type: t(query.resultType === "org" ? "Payers" : "Recipients"),
                paymentType: t(
                  getPaymentType(query) === "advertising"
                    ? "advertising_payments"
                    : getPaymentType(query) === "funding"
                      ? "funding_payments"
                      : "advertising_and_funding_payments"
                ),
              }) +
              " - " +
              t("top_transfer_sub_title", {
                period:
                  query.to !== query.from
                    ? `${periodToString(query.from ?? 0)} - ${periodToString(
                      query.to ?? 0
                    )}`
                    : periodToString(query.to ?? 0),
                region: t(query.federalState),
              }) +
              `, ${t("Source")}: KommAustria, ${t(
                "Date"
              )}: ${new Date().toLocaleString()}`
            }
            pagination={true}
            columns={colums}
            data={table}
            actions={
              <ExportToExcel
                data={table}
                columns={colums}
                fileName="MoneyFlow"
              />
            }
            aria-label={t("Data Table")}
          />
        </Fold>
      </IfNoError>
    </>
  );
};
