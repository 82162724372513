import { createSlice } from "@reduxjs/toolkit";
import {
  IUniverseResult,
  getUniverse,
  searchNames,
} from "../services/data-service";
import {
  IBaseState,
  IOrgAndMediaQuery,
  orgAndMediaReducers,
} from "../states/base_states";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "..";
import { useEffect, useMemo, useCallback, useRef, useState } from "react";
import ForceGraph3D, { ForceGraphMethods } from "react-force-graph-3d";
import {
  ModalLoader,
  Render,
  ShowError,
} from "../components/helper-components";
import { useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";
import { GroupTypeSelector } from "../components/group-type-selector";
import { MultiSelectComp } from "../components/multi-select/multi-select";
import { TransferMultiSelectComp } from "../components/multi-select/transfer-multi-select";
import { AmountSlider, PeriodSlider } from "../components/slider/rangeSlider";
import { ToggleSwitch } from "../components/three-state/three-state-switch";
import { getDefaultQuery, isEqual, periodToString } from "../helpers/helpers";
import { TInfoState } from "../App";
import { useMediaQuery } from "react-responsive";
import { PayloadAction } from "@reduxjs/toolkit";
import Config from "../config/settings";
import { SettingsViewer } from "../components/settings-viewer";
import { Box, Card, CardContent } from "@mui/material";
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { useSettingsStore } from "../context/SettingsStore";
import { Modules } from "../models/modules";
import Brightness1Icon from '@mui/icons-material/Brightness1';

export interface IUniverseState
  extends IBaseState<IUniverseQuery, IUniverseResult[]> {
  switchState: number;
  settingsOpen: boolean;
  paymentRange: [number, number];
}

export interface IUniverseQuery extends IOrgAndMediaQuery {
  currentPaymentRange: [number, number];
}

export const universeSlice = createSlice({
  name: "universe",
  initialState: {
    settingsOpen: true,
    switchState: 2,
    pending: false,
    error: "",
    data: [],
    pristine: true,
    needsUpdate: true,
    paymentRange: [0, 0],
    query: {
      pType: 2,
      currentPaymentRange: [0, 0],
    } as IUniverseQuery,
  } as IUniverseState,
  reducers: {
    ...orgAndMediaReducers<IUniverseQuery, IUniverseResult[], IUniverseState>(),
    setQuery: (state, action: PayloadAction<IUniverseQuery>) => ({
      ...state,
      query: { ...state.query, ...action.payload },
      switchState: Array.isArray(action.payload.pType)
        ? (action.payload.pType as number[]).length > 1
          ? 0
          : +action.payload.pType[0]
        : +action.payload.pType,
    }),
    setPaymentRange: (state, action: PayloadAction<[number, number]>) => {
      state.paymentRange = action.payload;
    },
    setCurrentPaymentRange: (
      state,
      action: PayloadAction<[number, number]>
    ) => {
      state.query.currentPaymentRange = action.payload;
    },
    toggleSettingsOpen: (state) => ({
      ...state,
      settingsOpen: !state.settingsOpen,
    }),
  },
});

const {
  setPending,
  setData,
  saveQuery,
  setError,
  setQuery,
  setRange,
  clearNeedsUpdate,
  setOrgGroupType,
  setMediaGroupType,
  setPaymentRange,
  setCurrentPaymentRange,
  setPristine
} = universeSlice.actions;

type GraphRef = ForceGraphMethods;

export const Universe = () => {
  const { pending, data, query, error, lastQuery,
    switchState, paymentRange, pristine } =
    useSelector((state: AppState) => state.universe);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const fgRef = useRef<GraphRef>();
  const {
    ref: universeRef,
    width = 1,
    height = 1,
  } = useResizeObserver<HTMLDivElement>();
  const [highlightingEnabled, setHighlightingEnabled] = useState(false);
  // const [paymentRange, setPaymentRange] = useState([0, 0]);
  // const [currentPaymentRange, setCurrentPaymentRange] = useState([0, 0]);
  // const [maxVal, setMaxVal] = useState(0);
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const isMobile = isMobileLandscape || isMobilePortrait;
  const moduleSettings = useSettingsStore().modules[Modules.AdsUniverse];

  const handleClick = useCallback(
    (node) => {
      // Aim at node from outside it
      const distance = 100;
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

      fgRef?.current?.cameraPosition(
        { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
        node, // lookAt ({ x, y, z })
        3000 // ms transition duration
      );
    },
    [fgRef]
  );

  const { periods, groups, groupTypes } = useSelector<AppState, TInfoState>(
    (state) => state.info
  );

  const toggleStates = [
    { label: t("Advertising"), value: 2 },
    { label: t("Funding"), value: 4 },
    { label: t("Both"), value: 0 },
  ];

  useEffect(() => {
    if (pristine) {
      let defaultQuery = getDefaultQuery(moduleSettings, groupTypes);
      dispatch(setQuery({ ...query, ...defaultQuery }));
      dispatch(setPristine(false));
    }

  }, [pristine, dispatch, query, moduleSettings, groupTypes]);

  useEffect(() => {
    const latestPeriod = periods[periods.length - 1];
    dispatch(setRange({ from: latestPeriod, to: latestPeriod }));
  }, [periods, dispatch]);

  useEffect(() => {
    if (
      !pending &&
      !isEqual(query, lastQuery, ["currentPaymentRange"]) &&
      query.to &&
      query.from
    ) {
      dispatch(setPending(true));
      dispatch(setError(""));
      dispatch(saveQuery());
      getUniverse(query)
        .then((data) => {
          dispatch(setData([data]));
          dispatch(clearNeedsUpdate());
        })
        .catch((error) => {
          dispatch(setError(error.response.data));
        })
        .finally(() => dispatch(setPending(false)));
    }
  }, [data, pending, dispatch, query, lastQuery]);

  useEffect(() => {
    if (data.length > 0) {
      //const maxVal = data[0].nodes.reduce((max, node) => Math.max(max, node.val), -Infinity);
      //const minVal = data[0].nodes.reduce((min, node) => Math.min(min, node.val), Infinity);
      const directLinks = data[0].links.filter(
        (l) => l.source[0] === "o" && l.target[0] === "m"
      );
      const maxLinkVal = directLinks.reduce(
        (max, link) => Math.max(max, link.amount),
        -Infinity
      );
      const minLinkVal = directLinks.reduce(
        (max, link) => Math.min(max, link.amount),
        Infinity
      );
      dispatch(setCurrentPaymentRange([minLinkVal, maxLinkVal]));
      dispatch(setPaymentRange([minLinkVal, maxLinkVal]));
      //dispatch(setMaxVal(maxVal));
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const { network, maxNode, maxLink } = useMemo(() => {
    if (data.length > 0) {
      const [minLinkVal, maxLinkVal] = query.currentPaymentRange;
      return createGraph(data, setHighlightingEnabled, minLinkVal, maxLinkVal);
    } else {
      return { network: { nodes: [], links: [] }, maxNode: 20, maxLink: 5 };
    }
  }, [paymentRange, query.currentPaymentRange]); // eslint-disable-line react-hooks/exhaustive-deps

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());

  const handleNodeHover = (node) => {
    if (!highlightingEnabled) return;
    if (node) {
      setHighlightNodes(
        new Set(node.neighbors ? [node, ...node.neighbors] : [node])
      );
      setHighlightLinks(new Set(node.links));
    } else {
      setHighlightLinks(new Set());
      setHighlightNodes(new Set());
    }
  };

  useEffect(() => {
    if (!highlightingEnabled) return;
    if (fgRef.current) {
      fgRef.current?.refresh();
    }
  }, [highlightNodes, highlightLinks, highlightingEnabled]);

  const handleLinkHover = (link) => {
    if (!highlightingEnabled) return;
    if (link) {
      setHighlightLinks(new Set([link]));
      setHighlightNodes(new Set([link.source, link.target]));
    } else {
      setHighlightLinks(new Set());
      setHighlightNodes(new Set());
    }
  };

  const updateSelection =
    (orgType: "org" | "media" | "orgGroups" | "mediaGroups") =>
      (list: string[]) => {
        setError("");
        const fields = {
          org: "organisations",
          media: "media",
          orgGroups: "orgGroups",
          mediaGroups: "mediaGroups",
        };
        dispatch(
          setQuery({
            ...query,
            [fields[orgType]]: list.filter((v) => v.length > 0),
          })
        );
        //dispatch(setNeedsUpdate())
      };

  const Settings = () => {
    return (
      <div className="settings" data-test-id="flowSettings">
        <Grid container spacing={2}>

          <Grid item xs={12} md={4} lg={4}>

            <TransferMultiSelectComp
              value={query.organisations}
              searchNames={searchNames}
              orgType="org"
              placeholder={t("Select a Payer")}
              label={t("Payers")}
              onChange={(e) => {
                updateSelection("org")(e);
              }}
            />

            <GroupTypeSelector
              id="select-group-type-org-transfer-flow"
              value={query.orgGroupType}
              availableGroupTypes={groupTypes}
              type="org"
              onChange={(e) => {
                dispatch(setOrgGroupType(e.target.value));
              }}
            />

            <Render
              when={
                query.orgGroupType !== undefined &&
                query.orgGroupType.length > 0
              }
            >
              <MultiSelectComp
                options={groups
                  .filter(
                    (g) =>
                      g.type === "org" && g.group_type === query.orgGroupType
                  )
                  .map((g) => g.name)
                  .sort((a, b) => a.localeCompare(b))}
                value={query.orgGroups as string[]}
                placeholder={t("Select a Group")}
                label={t("Select payer groups")}
                onChange={(newValue) => updateSelection("orgGroups")(newValue)}
              />
            </Render>

          </Grid>
          <Grid item xs={12} md={4} lg={4}
            container
            spacing={0}
            direction="column"
            alignItems="center"
          //justifyContent="center"
          >

            <Render when={!isMobileLandscape}>
              <ToggleSwitch
                values={toggleStates}
                selected={switchState}
                onChange={(pT) =>
                  dispatch(
                    setQuery({ ...query, pType: pT === 0 ? [2, 4] : pT })
                  )
                }
              />


            </Render>


            <Render when={isMobileLandscape}>

              <ToggleSwitch
                values={toggleStates}
                selected={switchState}
                onChange={(pT) =>
                  dispatch(
                    setQuery({ ...query, pType: pT === 0 ? [2, 4] : pT })
                  )
                }
              />

            </Render>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>

            <TransferMultiSelectComp
              value={query.media}
              orgType="media"
              placeholder={t("Select a Beneficiary")}
              label={t("Beneficiaries")}
              searchNames={searchNames}
              onChange={(e) => {
                updateSelection("media")(e);
              }}
            />

            <GroupTypeSelector
              id="select-group-type-transfer-flow"
              value={query.mediaGroupType}
              availableGroupTypes={groupTypes}
              type="media"
              onChange={(e) => {
                dispatch(setMediaGroupType(e.target.value));
              }}
            />

            <Render
              when={
                query.mediaGroupType !== undefined &&
                query.mediaGroupType.length > 0
              }
            >
              <MultiSelectComp
                options={groups
                  .filter(
                    (g) =>
                      g.type === "media" &&
                      g.group_type === query.mediaGroupType
                  )
                  .map((g) => g.name)
                  .sort((a, b) => a.localeCompare(b))}
                value={query.mediaGroups as string[]}
                placeholder={t("Select a Group")}
                label={t("Select beneficiary groups")}
                onChange={(newValue) =>
                  updateSelection("mediaGroups")(newValue)
                }
              />
            </Render>

          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} paddingLeft={'1em'}>

            <PeriodSlider
              label={t("Period")}
              aria-labelledby="period-slider-label"
              startPeriod={query.from ?? 0}
              endPeriod={query.to ?? 0}
              step={1}
              onChange={(start, end) =>
                dispatch(setRange({ from: start, to: end }))
              }
              labelFormater={periodToString}
              periods={periods}
              marksFilter={(period) => period.startsWith("Q1")}
            ></PeriodSlider>
            <AmountSlider
              label={t("Payment Range")}
              availableAmounts={paymentRange}
              value={query.currentPaymentRange}
              onChange={(range) => dispatch(setCurrentPaymentRange(range))}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const UniverseLegend = () => {
    const colors = useMemo(() => Config.universe.colors, [])

    return <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={5} sx={{ marginTop: "2em", marginLeft: "0em" }} >
        {Object.keys(colors).map(nodeType => <Grid item xs={6} md={6} lg={3} sx={{ margin: "1em", alignItems: "center", display: "contents" }}>
          <Brightness1Icon sx={{ color: colors[nodeType].normal }} />&nbsp;{t(nodeType)}&nbsp;&nbsp;</Grid>)}
      </Grid>
    </Box>

  }

  return (
    <>
      <ShowError error={error} onClose={() => dispatch(setError(""))} />
      <ModalLoader isPending={pending} />

      <Card>
        <CardContent>
          <SettingsViewer id="universer-settings" fold={true} open={!isMobile}>
            <Settings />
          </SettingsViewer>
          <Render when={network.nodes.length === 0}>
            <Alert severity="warning">
              {t("No Data found that matches your settings")}
            </Alert>
          </Render>
          <Render when={network.nodes.length > 0}>
            <UniverseLegend />
            <div
              style={{ height: "80vh", width: "100%", margin: "auto" }}
              ref={universeRef}
            >

              <ForceGraph3D
                width={width}
                height={height}
                ref={fgRef as any}
                graphData={network}
                nodeColor={(n) =>
                  highlightNodes.has(n)
                    ? Config.universe.colors[n.type].selected
                    : Config.universe.colors[n.type].normal
                }
                nodeVal={(n) => (n.val / maxNode) * 100}
                onNodeClick={handleClick}
                nodeLabel={(n) =>
                  `<span class="univ-label">${n.id.slice(
                    2
                  )} (${n.val.toLocaleString(i18n.language, {
                    style: "currency",
                    currency: "EUR",
                  })})</span>`
                }
                linkLabel={(l) =>
                  `<span class="univ-label">${l.source["id"].slice(
                    2
                  )} > ${l.target["id"].slice(2)} (${l.amount.toLocaleString(
                    i18n.language,
                    {
                      style: "currency",
                      currency: "EUR",
                    }
                  )})</span>`
                }
                linkWidth={(l) =>
                  (l.amount / maxLink) * 10 * (highlightLinks.has(l) ? 2 : 1)
                }
                linkColor={(l) =>
                  highlightLinks.has(l) ? "rgba(230,220,40,1)" : "#999999"
                }
                linkOpacity={0.8}
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--universe-background-color")}
                //linkDirectionalParticles={link => highlightLinks.has(link) ? link.amount / maxLink * 10 : 0}
                //linkDirectionalParticleWidth={link => link.amount / maxLink * 10}
                //nodeCanvasObjectMode={node => highlightNodes.has(node) ? 'before' : undefined}
                //nodeCanvasObject={paintRing}
                onNodeHover={handleNodeHover}
                onLinkHover={handleLinkHover}
              />
            </div>
          </Render>
        </CardContent>
      </Card>
    </>
  );
};

export default Universe;

const createGraph = (
  data: IUniverseResult[],
  setHighlightingEnabled,
  minPayment,
  maxPayment
) => {
  const links = data[0].links
    .filter(
      ({ amount, target }) =>
        (amount >= minPayment && amount <= maxPayment) ||
        target[0] === "h" ||
        target[0] === "g"
    )
    .map((link) => ({ ...link }));
  const linkedNodeIds = new Set(
    links.flatMap((link) => [link.source, link.target])
  );
  const nodes = data[0].nodes
    .filter((n) => linkedNodeIds.has(n.id))
    .map((node) => ({
      id: node.id,
      val: node.val,
      type: node.type,
    }));
  const maxVal = nodes.reduce((acc, n) => Math.max(acc, n.val), -Infinity);
  const graph = {
    network: {
      nodes,
      links,
    },
    maxNode: maxVal,
    maxLink: links.reduce((max, link) => Math.max(max, link.amount), -Infinity),
  };
  if (
    Config.universe.highlight.enabled &&
    graph.network.nodes.length < Config.universe.highlight.maxNodes
  ) {
    const nodesIndexed = graph.network.nodes.reduce((acc, node) => {
      acc[node.id] = node;
      return acc;
    }, {});

    graph.network.links.forEach((link) => {
      const a = nodesIndexed[link.source];
      const b = nodesIndexed[link.target];
      !a.neighbors && (a.neighbors = []);
      !b.neighbors && (b.neighbors = []);
      a.neighbors.push(b);
      b.neighbors.push(a);

      !a.links && (a.links = []);
      !b.links && (b.links = []);
      a.links.push(link);
      b.links.push(link);
    });
    setHighlightingEnabled(true);
  }
  return graph;
};


