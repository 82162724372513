import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IChange, IDataImport } from "../../../server/models/change";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "..";
import { useEffect, useMemo } from "react";
import { countChanges, countDataImports, getChanges, getDataImports } from "../services/data-service";
import DataTable, { TableColumn } from "react-data-table-component-with-filter";
import { useTranslation } from 'react-i18next';
import { ModalLoader } from "../components/helper-components";
import { Alert } from "@mui/material";


interface ChangeState {
  changes: IChange[];
  imports: IDataImport[]
  needsUpdate: boolean;
  changesPage: number;
  changesSize: number;
  importsPage: number;
  importsSize: number;
  changesCount: number;
  importsCount: number;
  pending: boolean;
}

export const changeSlice = createSlice({
  name: 'changes',
  initialState: {
    changes: [],
    imports: [],
    needsUpdate: true,
    changesPage: 1,
    importsPage: 1,
    changesSize: 10,
    importsSize: 10,
    changesCount: 0,
    importsCount: 0,
    pending: false
  } as ChangeState,
  reducers: {
    setChangesList: (state, action: PayloadAction<IChange[]>) => ({
      ...state,
      changes: action.payload,
      needsUpdate: false
    }),
    setImportsList: (state, action: PayloadAction<IDataImport[]>) => ({
      ...state,
      imports: action.payload,
      needsUpdate: false
    }),
    setChangesPage: (state, action: PayloadAction<number>) => ({ ...state, changesPage: action.payload, needsUpdate: true }),
    setChangesSize: (state, action: PayloadAction<number>) => ({ ...state, changesSize: action.payload, needsUpdate: true }),
    setImportsPage: (state, action: PayloadAction<number>) => ({ ...state, importsPage: action.payload, needsUpdate: true }),
    setImportsSize: (state, action: PayloadAction<number>) => ({ ...state, importsSize: action.payload, needsUpdate: true }),
    setChangesCount: (state, action: PayloadAction<number>) => ({ ...state, changesCount: action.payload }),
    setImportsCount: (state, action: PayloadAction<number>) => ({ ...state, importsCount: action.payload }),
    setNeedsUpdate: (state, action: PayloadAction<boolean>) => ({ ...state, needsUpdate: action.payload }),
    setPending: (state, action: PayloadAction<boolean>) => ({ ...state, pending: action.payload })
  }
})

const { setChangesList, setNeedsUpdate, setChangesCount,
        setChangesPage, setChangesSize, setPending, setImportsCount,
        setImportsList, setImportsPage, setImportsSize } = changeSlice.actions;

export const Changes = () => {

  const { changes, needsUpdate, changesPage, changesSize,
     changesCount, pending, imports, importsPage,
     importsSize, importsCount } = useSelector<AppState, ChangeState>(
    (state) => state.change
  );

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();


  useEffect(() => {
    setPending(true);
    Promise.all([
      countChanges(),
      countDataImports(),
    ]).then(([changesCount, importsCount]) => {
      dispatch(setChangesCount(changesCount.count));
      dispatch(setImportsCount(importsCount.count));
      dispatch(setNeedsUpdate(true));
    })
      .catch((err) => { })
      .finally(() => {
        setPending(false);
      })
      .finally(() => {
        setPending(false);
      })
  }, [dispatch]);



  useEffect(() => {
    if (needsUpdate ) {
      dispatch(setPending(true));
      Promise.all([
        getChanges(changesPage, changesSize),
        getDataImports(importsPage, importsSize),
      ])
        .then(([changes, imports]) => {
          dispatch(setChangesList(changes));
          dispatch(setImportsList(imports));
          dispatch(setPending(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setPending(false));
        });
    }
  }, [dispatch, needsUpdate, changesPage, changesSize, importsPage, importsSize]);



  const changesColumns = useMemo<TableColumn<IChange>[]>(() => [
    {
      name: t("Timestamp"),
      selector: (row) => new Date(row.timestamp).toLocaleString(),
      sortable: true,
    },
    {
      name: t("Organisation"),
      selector: (row) => row.document.organisation,
      sortable: true,
    },
    {
      name: t("Media"),
      selector: (row) => row.document.media,
      sortable: true,
    },
    {
      name: t("Period"),
      selector: (row) => row.document.period,
      sortable: true,
    },
    {
      name: t("Changed Columns"),
      selector: (row) => Object.keys(row.updatedFields).map(k => `${k}: ${row.updatedFields[k]}`).join(",\n"),
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [t, i18n.language]);

  const importsColumns = useMemo<TableColumn<IDataImport>[]>(() => [
    {
      name: t("Timestamp"),
      selector: (row) => new Date(row.timestamp).toLocaleString(),
      sortable: true,
    },
    {
      name: t("Period"),
      selector: (row) => row.period,
      sortable: true,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [t, i18n.language])

  return (
    <div>
      <ModalLoader isPending={pending} />
      {  imports && <div>
        <h1>{t('Protocol of Data Imports')}</h1>
        <DataTable
          columns={importsColumns}
          data={imports}
          pagination
          paginationServer
          paginationTotalRows={importsCount}
          paginationPerPage={importsSize}
          paginationComponentOptions={{
            rowsPerPageText: t('Rows per page'),
            rangeSeparatorText: t('of'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: t('All')
          }}
          onChangeRowsPerPage={e => dispatch(setImportsSize(e))}
          onChangePage={e => dispatch(setImportsPage(e))}
          noDataComponent={<Alert severity="info">{t('No imports found')}</Alert>}
          noHeader
          dense
        />
      </div> }
      {  changes && <div>
        <h1>{t('Protocol of Data Changes')}</h1>
        <DataTable
          columns={changesColumns}
          data={changes}
          pagination
          paginationServer
          paginationTotalRows={changesCount}
          paginationPerPage={changesSize}
          paginationComponentOptions={{
            rowsPerPageText: t('Rows per page'),
            rangeSeparatorText: t('of'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: t('All')
          }}
          noDataComponent={<Alert severity="info">{t('No changes found')}</Alert>}
          onChangeRowsPerPage={e => dispatch(setChangesSize(e))}
          onChangePage={e => dispatch(setChangesPage(e))}
          noHeader
          dense
        />
      </div> }
    </div>
  )

}


