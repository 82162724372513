import { faWindowClose, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { useTranslation } from "react-i18next";
import mongoose from 'mongoose';
import { Popup } from "./popup";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

type ConfirmDeleteProps<T extends mongoose.Document> = {
  onDelete: () => any, 
  elementToDelete: T | null,
  propertyToPresent: keyof T, 
  onHide: ()=>any
  show: boolean
}  

export const ConfirmDelete = <T extends mongoose.Document>( {
  elementToDelete,
  propertyToPresent,
  onDelete,
  onHide,
  show
}: ConfirmDeleteProps<T>) => {
  const { t } = useTranslation();

  return <Popup show={show}
    title={t("Attention!")}
    description={
      <>
        <p>
          {t('Do you really want to delete the following element?')}
        </p>
        <p><strong>{elementToDelete && (elementToDelete[propertyToPresent] as any).toString()}</strong></p>
      </>
    }
    footer={
      <Box sx={{ display: 'flex', gap: 1, marginBottom: 2 }}>
        <Button color="secondary" variant="contained" onClick={onHide} startIcon={<FontAwesomeIcon icon={faWindowClose}/> }>{t('Cancel')}</Button>
        <Button color="error" variant="contained" onClick={onDelete} startIcon={<FontAwesomeIcon icon={faTrashAlt}/>}>{t('Delete')}</Button>
      </Box>

    }
  />
}