import { LoginInformation, User } from '../models/models';
import config from './server-config';
import axios from 'axios';
import jwt from 'jsonwebtoken';

const endpoint = axios.create({
    baseURL: config.host,
    responseType: 'json'
});

interface TokenResponse {
    token: string
}

const timeout = 5000;

export const register = (user: User) =>
    endpoint.post<User, User>('/users', user, { timeout }).then(res =>
        console.log("## ", res));

export const login = (loginData: LoginInformation) =>
    endpoint.post<TokenResponse>('/login', loginData, { timeout })
        .then(
            ({ data: { token } }) => {
                const dec = jwt.decode(token)
                const user: User = (dec && typeof dec === 'object') ? dec['user'] : {};
                return Promise.all([
                    user,
                    token,
                    localStorage.setItem('user', JSON.stringify((dec && typeof dec === 'object') ? dec['user'] : {})),
                    localStorage.setItem('token', token),
                ])
            }
        ).then(([user, token, ...others]) => ({ user, token }))


export const isNotExpired = (token: string | null | undefined) => {
    if (!token) return false;
    const dec = jwt.decode(token)
    return (dec && typeof dec === 'object') ? new Date((dec['exp'] || 1) * 1000) > new Date() : false;
}

export const loadUserData = () => Promise.all([localStorage.getItem('user'), localStorage.getItem('token')])
    .then(([user, token]) => ({ user: user ? JSON.parse(user) : null, token: token }))

export const clearUserData = () => Promise.all([localStorage.removeItem('user'), localStorage.removeItem('token')])

export const getUserInfo = () => Promise.all([localStorage.getItem('user'), localStorage.getItem('token')])
    .then(([user, token]) => {
        if (user && token)
            return { user: JSON.parse(user), token: token }
        else throw new Error('Not logged in!')
    })

export const createAuthenticationHeader = () => ({ 'Authorization': `Bearer ${localStorage.getItem('token')}` })