import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import "./tabSegments.scss";

export type TabSegmentLabel = {
  title: string;
  tooltip: string;
};

export type TabSegmentsProps = {
  selValue: number;
  labels: TabSegmentLabel[];
  mobileLabels: TabSegmentLabel[];
  onChange: (v: any) => void;
};

export const TabSegments = ({
  selValue,
  labels,
  mobileLabels,
  onChange,
}: TabSegmentsProps) => {
  const [value, setValue] = useState(selValue);

  useEffect(() => {
    setValue(selValue);
  }, [selValue]);

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const tabLabels = isMobile ? mobileLabels : labels;
  if (
    selValue === undefined ||
    selValue >= labels.length ||
    tabLabels[value] === undefined
  ) {
    console.log("setValue is undefined");
    return <div>{selValue} is not a valid flow index!</div>;
  }
  return (
    <RadioGroup
      orientation="horizontal"
      aria-label={"Switch " + tabLabels.map((l) => l.title).join(",")}
      name="justify"
      value={tabLabels[value].title}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(tabLabels.findIndex((l) => l.title === event.target.value));
      }}
      sx={{
        minHeight: 40,
        marginBottom: 2,
        padding: "4px",
        borderRadius: "md",
        bgcolor: "var(--primary-color)",
        "--RadioGroup-gap": "4px",
        "--Radio-actionRadius": "8px",
      }}
    >
      {tabLabels?.map((item) => (
        <Radio
          key={item.title}
          color="neutral"
          value={item.title}
          disableIcon
          label={item.title}
          variant="plain"
          sx={{
            px: 2,
            alignItems: "center",
            color: "white",
            width: 100 / tabLabels.length + "%",
            textAlign: "center",
          }}
          slotProps={{
            action: ({ checked }) => ({
              sx: {
                ...(checked && {
                  bgcolor: "var(--secondary-color)",
                  boxShadow: "md",
                  "&:hover": {
                    bgcolor: "var(--secondary-color)",
                  },
                }),
                ...(!checked && {
                  "&:hover": {
                    bgcolor: "var(--secondary-color)",
                  },
                }),
              },
            }),
          }}
        />
      ))}
    </RadioGroup>
  );
};
