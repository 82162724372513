import { TSearchResult } from "../pages/search";

export const mergeTransferAndFundingSearchResult = (transRes: any, fundRes: any) => {
    const media = mergeArrayBasedOnProperty(transRes.media.concat(fundRes.receiver), 'lowerName');
    const a: TSearchResult ={
        org: transRes.org,
        media: media
    };

    return a;
}

export const mergeArrayBasedOnProperty = (array: any, property: any) => {
    const newArray = new Map();
    array.forEach((item: any) => {
      const propertyValue = item[property];
      newArray.has(propertyValue) ? newArray.set(propertyValue, { ...item, ...newArray.get(propertyValue) }) : newArray.set(propertyValue, item);
    });
    return Array.from(newArray.values());
  }