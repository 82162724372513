import { TInfoState } from "../../App";
import { AppState } from "../..";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { Blog } from "./blog";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import "./view-blogs.scss";
import { infoSlice } from "../../App";
import { useEffect } from "react";

export const blogToCard = (language: string, t) => (blog: Blog) =>
  (
    <Card className="card-text news">
      <CardHeader
        title={language.startsWith("de") ? blog.titleGerman : blog.titleEnglish}
        subheader={`${t("created on")} ${new Date(
          blog.createDate
        ).toLocaleDateString(language, {})}`}
      />
      <CardContent>
        <div
          dangerouslySetInnerHTML={{
            __html: language.startsWith("de")
              ? blog.contentGerman
              : blog.contentEnglish,
          }}
        ></div>
      </CardContent>
    </Card>
  );

export const News = () => {
  const { t } = useTranslation();
  const { setNewsSeen } = infoSlice.actions;
  const currentLang: string = i18n.language;
  const { blogs } = useSelector<AppState, TInfoState>((state) => state.info);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setNewsSeen());
  }, [dispatch, setNewsSeen]);
  return (
    <>
      {blogs.length === 0 ? (
        <Alert severity="info">t("no_news")</Alert>
      ) : (
        blogs.map(blogToCard(currentLang, t))
      )}
    </>
  );
};
