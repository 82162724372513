import AccordionMui from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import Config from "../config/settings";

interface FoldProps {
  children: JSX.Element;
  fold: boolean;
  open: boolean;
  id: string;
  icon: JSX.Element;
  header: string;
}

interface SettingsViewerProps {
  children: JSX.Element;
  fold: boolean;
  open: boolean;
  id: string;
}

export const Fold = ({
  id,
  children,
  header,
  icon,
  fold = true,
  open = false,
}: FoldProps) => {
  const [isOpen, setIsOpen] = useState(open);

  const expandIcon = useMemo(
    () =>
      Config.skin === "fhj" ? (
        <ExpandMoreIcon />
      ) : isOpen ? (
        <RemoveIcon />
      ) : (
        <AddIcon />
      ),
    [isOpen]
  );

  return fold ? (
    <>
      <AccordionMui
        className="overview-accordion-text-container"
        expanded={isOpen}
        onChange={() => setIsOpen(!isOpen)}
      >
        <AccordionSummary
          expandIcon={expandIcon}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
          color="secondary"
        >
          <Typography className="overview-accordian-header-text">
            <span className="accordion-icon">{icon}</span> {header}
          </Typography>
        </AccordionSummary>
        {isOpen && <AccordionDetails>{children}</AccordionDetails>}
      </AccordionMui>
    </>
  ) : (
    <>{children}</>
  );
};

export const SettingsViewer = ({
  id,
  children,
  fold = true,
  open = false,
}: SettingsViewerProps) => {
  const { t } = useTranslation();
  return (
    <Fold
      id={id}
      header={t("Settings")}
      icon={<FontAwesomeIcon icon={faSliders} />}
      fold={fold}
      open={open}
    >
      {children}
    </Fold>
  );
};
