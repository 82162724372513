import { useCallback, useState, useEffect } from "react";
import { IfNoError } from "../components/helper-components";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputLabel,
  TablePagination,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import { TextField, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  getTranslations,
  updateTranslation,
  getTranslationCount,
  insertTranslation,
  deleteTranslation,
} from "../services/data-service";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { PopupProps, Popup } from "../components/popup";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import React from "react";
export type KeyWithTranslation = {
  key: string;
  de?: string;
  en?: string;
};

export type TKeyWithTranslation = KeyWithTranslation[];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const EditLanguages = () => {
  const [error] = useState<string>(""); //const [error, setError] = useState<string>("");
  const { t } = useTranslation();
  const [languageStrings, setLanguageStrings] = useState<TKeyWithTranslation>(
    []
  );
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [updatedLanguageStrings, setUpdatedLanguageStrings] =
    useState(languageStrings);
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [newLanguageString, setNewLanguageString] =
    useState<KeyWithTranslation>({
      key: "",
      de: "",
      en: "",
    });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const loadTranslations = useCallback(() => {
    getTranslations(currentPage + 1, rowsPerPage, searchString)
      .then((entries) => {
        if (entries === undefined || entries.length === 0) {
          console.log("ERROR")
          setErrorMessage(t("Nothing_found"));
        }
        setLanguageStrings(entries);
        setErrorMessage(null); // Clear the error message when successful
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(t("Nothing_found")); // Set the error message when an error occurs
      });
  }, [currentPage, rowsPerPage, searchString, t]);

  const handleSearchChange = (event) => {
    setSearchString(event.target.value);
    setCurrentPage(0);

    getTranslations(1, rowsPerPage, searchString).then((entries) => {
      setLanguageStrings(entries);

      getTranslationCount(searchString).then((count) => setTotalCount(count));
    });
  };

  useEffect(() => {
    loadTranslations();
    getTranslationCount().then((count) => setTotalCount(count));
  }, [loadTranslations]);

  const handleChange = (index, field, value) => {
    const updatedStrings = [...updatedLanguageStrings];
    const fieldName = field === "german" ? "de" : "en";
    updatedStrings[index][fieldName] = value;
    setUpdatedLanguageStrings(updatedStrings);
  };

  const handleChangeNewString = (field, value) => {
    setNewLanguageString((prevLanguageString) => {
      if (field === "key") {
        return {
          ...prevLanguageString,
          key: value,
        };
      } else {
        return {
          ...prevLanguageString,
          [field === "german" ? "de" : "en"]: value,
        };
      }
    });
  };

  const HandleSave = () => {
    updateTranslation(updatedLanguageStrings).then(() => {
      loadTranslations()
      setUpdateSuccess(true)
     console.log("Update successful.")
     console.log(updateSuccess)
    },
      () => setUpdateSuccess(false));
  };

  const Insert = () => {
    insertTranslation(newLanguageString)
      .then(() => setShowPopup(false))
      .then(() => loadTranslations())
      .then(() =>
        setNewLanguageString({
          key: "",
          de: "",
          en: "",
        })
      ).catch((error) => {
        console.log(error);
        setErrorMessage(t("Insert_failed"));
        setShowPopup(false)
      });
  }

  const Delete = (key) => {
    deleteTranslation(key).then(() => loadTranslations()).catch((error) => {
      console.log(error);
      setErrorMessage(t("Delete_failed"));
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  useEffect(() => {
    console.log("LanguageStrings changed.");
    setUpdatedLanguageStrings(languageStrings);
  }, [languageStrings]);

  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (key) => {
    console.log("Delete: " + key);
    Delete(key);
    setOpen(false);
  };

  const popupProps: PopupProps = {
    show: showPopup,
    setShow: setShowPopup,
    title: <CardHeader title={t("new_translation")} />,
    description: (
      <CardContent style={{ display: "flex", flexDirection: "column" }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Textarea
              minRows={1}
              variant="soft"
              aria-label={t("key")}
              placeholder={t("key")}
              value={newLanguageString?.key}
              onChange={(e) => handleChangeNewString("key", e.target.value)}
              sx={{
                background: "white",
                borderBottom: "2px solid",
                borderColor: "neutral.outlinedBorder",
                borderRadius: 0,
                "&:hover": {
                  borderColor: "neutral.outlinedHoverBorder",
                },
                "&::before": {
                  border: "1px solid var(--Textarea-focusedHighlight)",
                  transform: "scaleX(0)",
                  left: 0,
                  right: 0,
                  bottom: "-2px",
                  top: "unset",
                  transition: "transform .15s cubic-bezier(0.1,0.9,0.2,1)",
                  borderRadius: 0,
                },
                "&:focus-within::before": {
                  transform: "scaleX(1)",
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Textarea
              minRows={1}
              aria-label={t("german")}
              placeholder={t("german")}
              variant="soft"
              value={newLanguageString.de || ""}
              onChange={(e) => handleChangeNewString("german", e.target.value)}
              sx={{
                background: "white",
                borderBottom: "2px solid",
                borderColor: "neutral.outlinedBorder",
                borderRadius: 0,
                "&:hover": {
                  borderColor: "neutral.outlinedHoverBorder",
                },
                "&::before": {
                  border: "1px solid var(--Textarea-focusedHighlight)",
                  transform: "scaleX(0)",
                  left: 0,
                  right: 0,
                  bottom: "-2px",
                  top: "unset",
                  transition: "transform .15s cubic-bezier(0.1,0.9,0.2,1)",
                  borderRadius: 0,
                },
                "&:focus-within::before": {
                  transform: "scaleX(1)",
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Textarea
              minRows={1}
              aria-label={t("english")}
              placeholder={t("english")}
              variant="soft"
              value={newLanguageString.en || ""}
              onChange={(e) => handleChangeNewString("english", e.target.value)}
              sx={{
                background: "white",
                borderBottom: "2px solid",
                borderColor: "neutral.outlinedBorder",
                borderRadius: 0,
                "&:hover": {
                  borderColor: "neutral.outlinedHoverBorder",
                },
                "&::before": {
                  border: "1px solid var(--Textarea-focusedHighlight)",
                  transform: "scaleX(0)",
                  left: 0,
                  right: 0,
                  bottom: "-2px",
                  top: "unset",
                  transition: "transform .15s cubic-bezier(0.1,0.9,0.2,1)",
                  borderRadius: 0,
                },
                "&:focus-within::before": {
                  transform: "scaleX(1)",
                },
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: "auto",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: "16px" }}
            onClick={Insert}
            disabled={!newLanguageString.key}
          >
            {t("save")}
          </Button>
        </div>
      </CardContent>
    ),
  };

  return (
    <div className="languageSettings">
      <IfNoError error={error}>
        {errorMessage && (
          <Grid item xs={12}>
            <Card className="settings-card">
              <CardContent className="settings-banner-content settings-banner-error">
                <div className="settings-banner-message">
                  <div>{errorMessage}</div>
                </div>
                <IconButton color="primary" onClick={() => setErrorMessage(null)}>
                  <CloseIcon />
                </IconButton>
              </CardContent>
            </Card>
          </Grid>
        )}
        {updateSuccess !== undefined && (
          <Grid item xs={12}>
            <Card className="settings-card">
              <CardContent
                className={`settings-banner-content ${updateSuccess ? "settings-banner-success" : "settings-banner-error"}`}
              >
                <div className="settings-banner-message">
                  {updateSuccess
                    ? <div>{t("language_update_successful")}</div>
                    : <div>{t("language_update_failed")}</div>
                  }
                </div>
                <IconButton color="primary" onClick={() => setUpdateSuccess(undefined)}>
                  <CloseIcon />
                </IconButton>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Card className="top">
          <CardHeader
            title={
              <Box display="flex" alignItems="center">
                <span style={{ marginRight: "auto" }}>
                  {t("Manage_languages")}
                </span>
                <TextField
                  label={t("Search")}
                  variant="standard"
                  size="small"
                  sx={{ marginTop: "0 !important", marginRight: 2 }}
                  value={searchString}
                  onChange={handleSearchChange}
                />
                <IconButton color="primary" onClick={openPopup}>
                  <AddIcon />
                </IconButton>
                <Popup {...popupProps} />
              </Box>
            }
          />
          <CardContent>
            <form onSubmit={handleSubmit}>
              {Array.isArray(updatedLanguageStrings) &&
                updatedLanguageStrings.map((languageString, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={3.6}>
                      <TextField
                        variant="standard"
                        label="Key"
                        value={languageString.key}
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3.6} style={{ marginBottom: "16px" }}>
                      <InputLabel
                        style={{ fontSize: "12px", marginBottom: "4px" }}
                      >
                        {t("german")}
                      </InputLabel>
                      <Textarea
                        minRows={1}
                        aria-label={t("german")}
                        placeholder={t("german")}
                        variant="soft"
                        value={languageString.de || ""}
                        onChange={(e) =>
                          handleChange(index, "german", e.target.value)
                        }
                        sx={{
                          background: "white",
                          borderBottom: "2px solid",
                          borderColor: "neutral.outlinedBorder",
                          borderRadius: 0,
                          "&:hover": {
                            borderColor: "neutral.outlinedHoverBorder",
                          },
                          "&::before": {
                            border:
                              "1px solid var(--Textarea-focusedHighlight)",
                            transform: "scaleX(0)",
                            left: 0,
                            right: 0,
                            bottom: "-2px",
                            top: "unset",
                            transition:
                              "transform .15s cubic-bezier(0.1,0.9,0.2,1)",
                            borderRadius: 0,
                          },
                          "&:focus-within::before": {
                            transform: "scaleX(1)",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3.6} style={{ marginBottom: "16px" }}>
                      <InputLabel
                        style={{ fontSize: "12px", marginBottom: "4px" }}
                      >
                        {t("english")}
                      </InputLabel>
                      <Textarea
                        minRows={1}
                        aria-label={t("english")}
                        placeholder={t("english")}
                        variant="soft"
                        value={languageString.en || ""}
                        onChange={(e) =>
                          handleChange(index, "english", e.target.value)
                        }
                        sx={{
                          background: "white",
                          borderBottom: "2px solid",
                          borderColor: "neutral.outlinedBorder",
                          borderRadius: 0,
                          "&:hover": {
                            borderColor: "neutral.outlinedHoverBorder",
                          },
                          "&::before": {
                            border:
                              "1px solid var(--Textarea-focusedHighlight)",
                            transform: "scaleX(0)",
                            left: 0,
                            right: 0,
                            bottom: "-2px",
                            top: "unset",
                            transition:
                              "transform .15s cubic-bezier(0.1,0.9,0.2,1)",
                            borderRadius: 0,
                          },
                          "&:focus-within::before": {
                            transform: "scaleX(1)",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        variant="contained"
                        color="error"
                        type="submit"
                        sx={{ marginTop: 2 }}
                        onClick={() => {
                          setSelectedLanguage(languageString.key);
                          handleClickOpen();
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                      <Dialog
                        open={open}
                        aria-labelledby="alert-dialog-title"
                        keepMounted
                        TransitionComponent={Transition}
                        onClose={handleClose}
                        BackdropProps={{ invisible: true }}
                        PaperProps={{
                          style: {
                            boxShadow: "0px 3px 5px 2px rgba(0, 0, 0, 0.1)",
                            border: "1px solid #000",
                          },
                        }}
                      >
                        <DialogTitle id="alert-dialog-title">
                          {t("Are you sure you want to delete the key?")}
                        </DialogTitle>
                        <DialogActions>
                          <Button
                            color="error"
                            variant="contained"
                            onClick={(e) => handleDelete(selectedLanguage)}
                          >
                            {t("Delete")}
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleClose}
                          >
                            {t("Cancel")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Grid>
                ))}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ marginTop: 2 }}
                onClick={HandleSave}
              >
                {t("save")}
              </Button>
              <Box display="flex" justifyContent="flex-end">
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={currentPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </form>
          </CardContent>
        </Card>
      </IfNoError>
    </div>
  );
};
