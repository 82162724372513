import config from "./server-config";
import axios, { AxiosResponse } from "axios";
import { createAuthenticationHeader } from "./security";
import { IZipCodeDocument } from "../../../server/models/zipcodes";
import {
  TOverViewResult,
  TOverViewTransferResult,
} from "../../../server/controllers/transfer";
import { IFlowsQuery, TFlowsQueryResult } from "../pages/flows/flows-transfers";
import { ITopTransferQuery, TTopResult } from "../types/top_types";
import { TSearchResult } from "../pages/search";
import {
  IGrouping,
  IGroupingDocument,
  IStoredGrouping,
} from "../../../server/models/grouping";
import {
  IOrganisationDocument,
  IOrganisation,
  IStoredOrganisation,
} from "../../../server/models/organisations";
import { IStoredTransfer } from "../../../server/models/transfer";
import { IDifference } from "../../../server/models/differences";
import { IDuplicate } from "../../../server/models/duplicates";
import { mergeTransferAndFundingSearchResult } from "../helpers/search-helper";
import { TTopFundingQuery, TTopFundingResult } from "../pages/top/top-fundings";
import { TOverViewFundingResult } from "../../../server/controllers/funding";
import { IBlogDocument } from "../../../server/models/blog";
import { Blog, TBlogResult } from "../pages/blog/blog";
import {
  TFundingFlowsResponse,
  TTimelineFundingResult,
} from "../helpers/flows-funding.helper";
import { GroupType } from "../models/models";
import { IGroupTypeDocument } from "../../../server/models/group-type";
import { IFlowsFundingQuery } from "../pages/flows/flows-funding";
import { IAdsSortFilter } from "../pages/ads/ads-helper";
import { ISettings, ISettingsDocument } from "../../../server/models/settings";
import { KeyWithTranslation } from "../pages/languages-edit";
import { IUniverseQuery } from "../pages/universe";
import { IChange, IDataImport } from "../../../server/models/change";
import { TUploadResult } from "../../../server/models/types";

const endpoint = axios.create({
  baseURL: config.host,
  responseType: "json",
});

export type GroupListEntry = {
  _id: string;
  isActive: boolean;
  name: string;
  type: string;
  group_type: string;
};

export interface IUniverseResult {
  nodes: {
    val: number;
    id: string;
    type: "media" | "organisation";
  }[];
  links: {
    source: string;
    target: string;
    amount: number;
  }[];
}

interface HighlightData {
  period: string;
  totalAmount: number;
}

export const objectToParams = (obj: { [key: string]: any }): string => {
  //console.log(JSON.stringify(obj, null, 2));
  return Object.entries(obj)
    .reduce(
      (acc, [k, v]) => [
        ...acc,
        ...(Array.isArray(v)
          ? v
              .filter((v_) => (v_.length ? v_.length > 0 : true))
              .map((v_) => `${k}=${encodeURIComponent(v_)}`)
          : [`${k}=${encodeURIComponent(v)}`]),
      ],
      [] as any
    )
    .join("&");
};

export const fileUpload = (url: string) => (files: FileList) => {
  const formData = new FormData();
  formData.append("file", files[0]);
  //const Authorization = JSON.stringify(createAuthenticationHeader())
  return endpoint.post<any, AxiosResponse<TUploadResult>>(url, formData, {
    headers: {
      ...createAuthenticationHeader(),
      "Content-Type": "multipart/form-data",
    },
  }).then(({ data }) => data);
};

export const countEntities = (url: string, query?: { [key: string]: any }) =>
  endpoint
    .get<number>(
      `${url}${
        query && Object.keys(query).length > 0
          ? "?" + objectToParams(query)
          : ""
      }`,
      {
        headers: {
          ...createAuthenticationHeader(),
        },
      }
    )
    .then(({ data }) => data);

const deleteEntity = (url: string) => (id: string) =>
  endpoint
    .delete(`${url}/${id}`, {
      headers: {
        ...createAuthenticationHeader(),
      },
    })
    .then(({ data }) => data);

const getEntitiesPaginated =
  <T>(url: string) =>
  (
    page: number,
    size: number,
    sortBy: string = "",
    sortOrder: "asc" | "desc" = "asc",
    query?: { [key: string]: any }
  ) =>
    endpoint
      .get<T>(
        `${url}?page=${page}&size=${size}&sortBy=${sortBy}&sortOrder=${sortOrder}${
          query && Object.keys(query).length > 0
            ? "&" + objectToParams(query)
            : ""
        }`,
        {
          headers: {
            ...createAuthenticationHeader(),
          },
        }
      )
      .then(({ data }) => data);

export const deleteGroup = deleteEntity("/groupings");
export const deleteOrganisation = deleteEntity("/organisations");

export const getZipCodes =
  getEntitiesPaginated<IZipCodeDocument[]>("/zipcodes");
export const getOrganisations =
  getEntitiesPaginated<IOrganisationDocument[]>("/organisations");
export const getAllGroups =
  getEntitiesPaginated<IGroupingDocument[]>("/groupings/all");

export const getDifferences = () =>
  endpoint.get<IDifference[]>("/differences").then(({ data }) => data);
export const getDuplicates = () =>
  endpoint.get<IDuplicate[]>("/duplicates").then(({ data }) => data);

export const getTransferOverview = () =>
  endpoint
    .get<TOverViewTransferResult>("/transfers/overview")
    .then(({ data }) => data);

export const getFundingOverview = () =>
  endpoint
    .get<TOverViewFundingResult>("/fundings/overview")
    .then(({ data }) => data);

export const getOverview = () =>
  Promise.all([getTransferOverview(), getFundingOverview()]).then((results) => {
    return {
      transfers: results[0],
      fundings: results[1],
    } as TOverViewResult;
  });

export const getTop = (query: ITopTransferQuery) =>
  endpoint
    .get<TTopResult>("/transfers/top?" + objectToParams(query))
    .then(({ data }) => data);

export const getPeriods = () =>
  endpoint.get<number[]>("/transfers/periods").then(({ data }) => data);

export const getAppVersion = () =>
  endpoint.get<string>("/versions/app").then(({ data }) => data);

export const getFlow = (query: IFlowsQuery) =>
  endpoint
    .get<TFlowsQueryResult>("/transfers/flows?" + objectToParams(query))
    .then(({ data }) => data);

export const getAllAds = () =>
  endpoint.get<any>("/files/getAll").then(({ data }) => data);

export const getAdsList = (query: IAdsSortFilter) =>
  endpoint
    .get<any>("/files/getList?" + objectToParams(query))
    .then(({ data }) => data);

export const renderOneAd = (id: string) =>
  endpoint
    .get<any>("/files/renderOne/" + id, { responseType: "blob" })
    .then(({ data }) => data);

export const downloadOneAd = (id: string) =>
  endpoint
    .get<any>("/files/download/" + id, { responseType: "blob" })
    .then(({ data }) => data);

export const getUniverse = (query: IUniverseQuery) =>
  endpoint
    .get<IUniverseResult>("/transfers/universe?" + objectToParams(query))
    .then(({ data }) => data);

export const searchTranferNames = (name: string, orgType: "org" | "media") =>
  endpoint
    .get<string[]>(
      `/transfers/searchNames?name=${encodeURIComponent(name)}&type=${orgType}`
    )
    .then(({ data }) => data);

export const searchFundingNames = (name: string) =>
  endpoint
    .get<string[]>(
      `/fundings/searchNames?name=${encodeURIComponent(name)}&type=receiver`
    )
    .then(({ data }) => data);

export const searchNames = (name: string, orgType: "org" | "media") =>
  Promise.all([searchTranferNames(name, orgType), searchFundingNames(name)])
    .then((results) => results.flat().sort())
    .then((results) => new Set(results))
    .then((results) => Array.from(results));

export const search = (name: string) =>
  Promise.all([searchTransfers(name), searchFundings(name)]).then((results) => {
    return mergeTransferAndFundingSearchResult(results[0], results[1]);
  });

export const searchTransfers = (name: string) =>
  endpoint
    .get<TSearchResult>(`/transfers/search?name=${encodeURIComponent(name)}`)
    .then(({ data }) => data);

export const searchFundings = (name: string) =>
  endpoint
    .get<TSearchResult>(`/fundings/search?name=${encodeURIComponent(name)}`)
    .then(({ data }) => data);

// TODO: Maybe return IGroupingDocument from put and transform it into IStoredGrouping afterwards
export const updateGroup = (group: IStoredGrouping) =>
  endpoint.put<IStoredGrouping, IStoredGrouping>(
    `/groupings/${group._id}`,
    group,
    {
      headers: {
        ...createAuthenticationHeader(),
      },
    }
  );

export const updateOrganisation = (organisation: IStoredOrganisation) =>
  endpoint.put<IStoredOrganisation, IStoredOrganisation>(
    `/organisations/${organisation._id}`,
    organisation,
    {
      headers: {
        ...createAuthenticationHeader(),
      },
    }
  );

export const updateTransfer = (transfer: IStoredTransfer) =>
  endpoint.put<IStoredTransfer, IStoredTransfer>(
    `/transfers/${transfer._id}`,
    transfer,
    {
      headers: {
        ...createAuthenticationHeader(),
      },
    }
  );

const getEntity =
  <T>(url: string) =>
  (id: string) =>
    endpoint.get<T>(`/${url}/${id}`).then(({ data }) => data);

const insertEntity =
  <T1, T2>(url: string) =>
  (entity: T1) =>
    endpoint.post<T1, T2>(url, entity, {
      headers: {
        ...createAuthenticationHeader(),
      },
    });

export const createGroup =
  insertEntity<IGrouping, IGroupingDocument>("groupings");

export const getGroup = getEntity<IStoredGrouping>("groupings");

export const getOrganisation = getEntity<IStoredOrganisation>("organisations");
export const getTransfer = getEntity<IStoredTransfer>("transfers");
export const getTransfers =
  getEntitiesPaginated<IStoredTransfer[]>("/transfers");
export const createOrganisation =
  insertEntity<IOrganisation, IOrganisationDocument>("organisations");

export const getGroupList = () =>
  endpoint.get<GroupListEntry[]>(`/groupings/list`).then(({ data }) => data);

export const addGroupType = (groupType: GroupType) =>
  endpoint.post<GroupType, GroupType>("/group-types", groupType, {
    timeout: 5000,
    headers: {
      ...createAuthenticationHeader(),
    },
  });

export const getGroupTypes = () =>
  endpoint
    .get<GroupType[]>("/group-types", {
      headers: {
        ...createAuthenticationHeader(),
      },
    })
    .then(({ data }) => data);

export const getGroupTypeList = () =>
  endpoint
    .get<IGroupTypeDocument[]>("/group-types/list", {
      headers: {
        ...createAuthenticationHeader(),
      },
    })
    .then(({ data }) => data);
export const updateGroupType = (groupType: GroupType) =>
  endpoint.patch<GroupType, IGroupTypeDocument>(
    `/group-types/${groupType._id}`,
    groupType,
    {
      headers: {
        ...createAuthenticationHeader(),
      },
    }
  );

export const deleteGroupType = (groupType: GroupType) =>
  endpoint.delete<GroupType, IGroupTypeDocument>(
    `/group-types/${groupType._id}`,
    {
      headers: {
        ...createAuthenticationHeader(),
      },
    }
  );

export const getTopFundings = (query: TTopFundingQuery) =>
  endpoint
    .get<TTopFundingResult>("/fundings/top?" + objectToParams(query))
    .then(({ data }) => data);

export const getPeriodsFunding = () =>
  endpoint.get<number[]>("/fundings/periods").then(({ data }) => {
    if (data[0] && data[1]) {
      return Array.from(Array(data[1] - data[0] + 1).keys()).map(
        (x) => x + data[0]
      );
    } else {
      return [];
    }
  });

export const getFlowFunding = (
  query: IFlowsFundingQuery,
  otherReceiverDisabled: boolean
) =>
  endpoint
    .get<TFundingFlowsResponse>("/fundings/flows?" + objectToParams(query))
    .then(({ data }) =>
      otherReceiverDisabled
        ? data
        : {
            timeline: data.timeline,
            flows: data.flows.filter(
              (item) =>
                item.receiver.toLowerCase() !== "other receivers" &&
                item.fundingType.toLowerCase() !== "other fundingtypes"
            ),
          }
    )
    .then(({ flows, timeline }) => ({
      timeline,
      flows: flows.flatMap((d) => ({
        ...d,
        showFundingBasis: query.showFundingBasis ?? false,
      })),
    }));

export const getTimelineFundings = (query: IFlowsFundingQuery) =>
  endpoint
    .get<TTimelineFundingResult>("/fundings/timeline?" + objectToParams(query))
    .then(({ data }) => data);

export const addBlog = insertEntity<Blog, IBlogDocument>("blogs");

export const getActiveBlogData = () =>
  endpoint.get<TBlogResult>("/blogs").then(({ data }) => data);

export const getBlogData = () =>
  endpoint
    .get<TBlogResult>("/blogs/all?sortBy=createDate&sortOrder=desc", {
      headers: createAuthenticationHeader(),
    })
    .then(({ data }) => data);

export const deleteBlog = deleteEntity("/blogs");

export const updateBlog = (blog: Blog) =>
  endpoint.put<Blog, IBlogDocument>(`/blogs/${blog._id}`, blog, {
    headers: {
      ...createAuthenticationHeader(),
    },
  });

export const resetGroups = () =>
  endpoint
    .post(
      `/groupings/members/reset`,
      {},
      {
        headers: {
          ...createAuthenticationHeader(),
        },
      }
    )
    .then(({ data }) => data);

export const getTranslation = (languageCode: string) =>
  endpoint.get("/translation/" + languageCode).then(({ data }) => data);

export const getTranslations = (page, limit, search = "") =>
  endpoint
    .get(
      "/translation?page=" +
        page +
        "&limit=" +
        limit +
        (search != null ? "&search=" + search : ""),
      { headers: createAuthenticationHeader() }
    )
    .then(({ data }) => data);

export const getTranslationCount = (search = "") =>
  endpoint
    .get("/translationcount" + (search != null ? "?search=" + search : ""))
    .then(({ data }) => data);

export const updateTranslation = (translations: KeyWithTranslation[]) =>
  endpoint.put<KeyWithTranslation>(`/translations`, translations, {
    headers: {
      ...createAuthenticationHeader(),
    },
  });

export const insertTranslation = (translation: KeyWithTranslation) =>
  endpoint.post<KeyWithTranslation>(`/translation`, translation, {
    headers: {
      ...createAuthenticationHeader(),
    },
  });

export const deleteTranslation = (key: string) =>
  endpoint.put(`/translation/` + key, key, {
    headers: {
      ...createAuthenticationHeader(),
    },
  });

// Settings
export const getSettings = () =>
  endpoint.get<ISettingsDocument>("/settings").then(({ data }) => data);

export const createOrUpdateSettings = (settings: ISettings) =>
  endpoint
    .post<ISettings, ISettingsDocument>("/settings", settings, {
      headers: {
        ...createAuthenticationHeader(),
      },
    })
    .then((doc) => doc["data"])
    .catch((err) => {
      console.error(err?.message);
      throw err;
    });

export const getLatestChangeTimestamp = () =>
  endpoint.get<{ lastChange?: Date, lastImport?: Date }>("/changes/latest").then(({ data }) => data);

export const countChanges = () =>
  endpoint.get<{ count: number }>("/changes/count").then(({ data }) => data);

export const countDataImports = () =>
  endpoint.get<{ count: number }>("/imports/count").then(({ data }) => data);

export const getChanges = (page: number, limit: number) =>
  endpoint
    .get<IChange[]>(
      `/changes?page=${page}&limit=${limit}`
    )
    .then(({ data }) => data);

export const getDataImports = (page: number, limit: number) =>
  endpoint
    .get<IDataImport[]>(
      `/imports?page=${page}&limit=${limit}`
    )
    .then(({ data }) => data);

// Highlight Numbers
export const getAdvertisingSum = () => {
  let url = "/advertising-sum";
  return endpoint.get<HighlightData>(url).then(({ data }) => data);
};

export const getPayerCount = () => {
  let url = "/payer-count";
  return endpoint.get<HighlightData>(url).then(({ data }) => data);
};

export const getFundingSum = () => {
  let url = "/funding-sum";
  return endpoint.get<HighlightData>(url).then(({ data }) => data);
};
