import { useTranslation } from "react-i18next";

import React from "react";
import Button from "@mui/material/Button";
import { Popup } from "./popup";

export const GroupInUseError: React.FC<{ show: boolean; setShow: any }> = ({
  show,
  setShow,
}) => {
  const { t } = useTranslation();

  return (
    <Popup show={show}
      setShow={setShow}
      title={t("Attention!")}
      description={t("Group is in use")}
      footer={<Button color="primary" variant="contained" onClick={() => setShow(false)}>
        OK
      </Button>}
    />

  );
};
