import React, {FormEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {IfNoError} from "../components/helper-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Autocomplete, Button,
  Card,
  CardContent,
  CardHeader,
  Grid, IconButton,
  TextField
} from "@mui/material";
import {useSettingsStore, useUpdateSettings} from "../context/SettingsStore";
import {defaultGeneralSettings} from "../models/generalSettings";
import {MultiSelectComp, renderAutocompleteTags} from "../components/multi-select/multi-select";
import {ISettings} from "../../../server/models/settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {SwitchButton} from "../components/switch-button/switchButton";
import './settings-edit.css'
import {ModulesSettings} from "../models/modules";
import {defaultAboutUsModuleSettings} from "./about_us.settings";
import {defaultAdsUniverseModuleSettings} from "./universe.settings";
import {defaultBlogModuleSettings} from "./blog/blog.settings";
import {defaultFlowsFundingsModuleSettings} from "./flows/flows-fundings.settings";
import {defaultTopTransferModuleSettings} from "./top/top-transfer.settings";
import {defaultTopFundingsModuleSettings} from "./top/top-fundings.settings";
import {defaultSujetsModuleSettings} from "./ads/ads.settings";
import {defaultFlowsTransferModuleSettings} from "./flows/flows-transfer.settings";
import {defaultFlowsMixedModuleSettings} from "./flows/flows-mixed.settings";
import {typedEntries} from "../helpers/helpers";
import Config from "../config/settings";
import CloseIcon from "@mui/icons-material/Close";

export const EditSettings = () => {
  const [error, setError] = useState<string>("");
  const {t} = useTranslation();

  const settings = useSettingsStore()
  const updateSettings = useUpdateSettings();
  const [updatedSettings, setUpdatedSettings] = useState<ISettings>(settings);

  const [moduleExpanded, setModuleExpanded] = useState<string>("");
  const [updateSuccess, setUpdateSuccess] = useState<boolean | undefined>(undefined);
  const [generalDirty, setGeneralDirty] = useState<boolean>(false);
  const [modulesDirty, setModulesDirty] = useState<boolean>(false);

  const handleSave = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Do not reload page
    updateSettings(updatedSettings,
      () => {
        console.log("Settings updated")
        window.scrollTo(0, 0);
        setUpdateSuccess(true)
        setGeneralDirty(false)
        setModulesDirty(false)
      }, () => {
        console.log("Settings update failed")
        window.scrollTo(0, 0);
        setUpdateSuccess(false)
      });
  }

  const handleGeneralSettingsChange = (key: string, value: any) => {
    const tmpSettings = {...updatedSettings};
    tmpSettings.general[key] = value;
    setUpdatedSettings(tmpSettings);
    setDirtyFlag("general");
  }

  const handleModuleEnabledChange = (module: string, value: boolean) => {
    const tmpSettings = {...updatedSettings};
    tmpSettings.modules[module].enabled = value;
    setUpdatedSettings(tmpSettings);
    setDirtyFlag("modules");
  }

  const handleModuleSettingsChange = (module: keyof ModulesSettings, key: string, value: any) => {
    const tmpSettings = {...updatedSettings};
    tmpSettings.modules[module][key] = value;
    setUpdatedSettings(tmpSettings);
    setDirtyFlag("modules");
  }

  const setDirtyFlag = (which: "general" | "modules" | undefined) => {
    switch (which) {
      case "general":
        if (!generalDirty) setGeneralDirty(true);
        break;
      case "modules":
        if (!modulesDirty) setModulesDirty(true);
        break;
      default:
        if (!generalDirty) setGeneralDirty(true);
        if (!modulesDirty) setModulesDirty(true);
        break;
    }
  }

  const unhandledKey = (key: string, ...additionalInformation: string[]) => {
    console.error(`Unhandled key: ${key}`, additionalInformation.length ? "\nAdditional information:" : "", ...additionalInformation)
    // Defer setting the error to the next event loop cycle to prevent infinite re-rendering (needed because this function is called from within the render function)
    setTimeout(() => setError(`${t("settings_error_couldNotDisplayKey")}: "${key}"! ${t("settings_error_tryReloadOrContactAdministrator")}`), 0)
    return <></>
  }

  return (
    <div className="settings">
      <IfNoError error={error}>
        <form onSubmit={handleSave}>
          <Grid container spacing={2}>
            {updateSuccess !== undefined && <Grid item xs={12}>
              <Card className="settings-card">
                <CardContent
                  className={`settings-banner-content ${updateSuccess ? "settings-banner-success" : "settings-banner-error"}`}>
                  <div className="settings-banner-message">
                    {updateSuccess
                      ? <div>{t("settings_update_successful")}</div>
                      : <div>{t("settings_update_failed")}</div>
                    }
                  </div>
                  <IconButton color="primary"
                              onClick={() => setUpdateSuccess(undefined)}>
                    <CloseIcon/>
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
            }
            <Grid item xs={12} md={6}>
              <Card className="settings-card">
                <CardHeader title={
                  <span className={generalDirty ? "text-italic" : ""}>
                    {t("settings_general_header")}
                    {generalDirty && <span className="text-red">*</span>}
                  </span>
                }/>
                <CardContent>
                  <Grid container spacing={2}>
                    {Object.entries(updatedSettings.general).map(([key, value]) => {
                      const label = t(`settings_keys_${key}`);
                      switch (key) {
                        case "supportedLanguages":
                          return (
                            <Grid item key={key} xs={12}>
                              <MultiSelectComp
                                options={defaultGeneralSettings[key]}
                                value={value || []}
                                placeholder={t("settings_selectLanguages")}
                                label={label}
                                noOptionsText={t("settings_noOptionsAvailable")}
                                onChange={(v => handleGeneralSettingsChange(key, v))}/>
                            </Grid>
                          );
                        default:
                          return (
                            <Grid item key={key} xs={12}>
                              <TextField
                                variant="standard"
                                label={label}
                                value={value || ""}
                                onChange={(e) => handleGeneralSettingsChange(key, e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          );
                      }
                    })}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className="settings-card">
                <CardHeader title={
                  <span className={modulesDirty ? "text-italic" : ""}>
                    {t("settings_modules_header")}
                    {modulesDirty && <span className="text-red">*</span>}
                  </span>
                }/>
                <CardContent>
                  <Grid container>
                    {/*Create an accordion section for each module*/}
                    {typedEntries(updatedSettings.modules)
                      .map(([module, moduleSettings]) =>
                        <Accordion
                          key={module}
                          expanded={moduleExpanded === module}
                          onChange={() => setModuleExpanded(moduleExpanded === module ? "" : module)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls={`${module}-settings-content`}
                            id={`${module}-header`}
                          >
                            {/*Place the module enabled setting directly on the accordion summary (always visible)*/}
                            <div onClick={(e) =>
                              // Prevent the accordion from expanding when clicking on the switch
                              e.stopPropagation()
                            }>
                              <SwitchButton
                                simple
                                label={t(`settings_modules_${module}`)}
                                labelPlacement="end"
                                selValue={moduleSettings.enabled}
                                onChange={(v) => handleModuleEnabledChange(module, v)}
                              />
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            {/*Display all settings (except enabled) of the respective module in the accordion details (visible when expanded)*/}
                            {Object.entries(defaultModulesSettings[module]).filter(s => s[0] !== "enabled").map(([key, defaultValue]) => {
                              const label = t(`settings_keys_${key}`);
                              switch (typeof defaultValue) {
                                case "boolean":
                                  return (
                                    <Grid item key={key} xs={12}>
                                      <SwitchButton
                                        simple
                                        label={label}
                                        selValue={moduleSettings[key] !== undefined ? moduleSettings[key] : defaultValue}
                                        onChange={(v) => handleModuleSettingsChange(module, key, v)}
                                      />
                                    </Grid>
                                  );
                                case "string":
                                case "number":
                                  return (
                                    <Grid item key={key} xs={12}>
                                      <TextField
                                        variant="standard"
                                        type={typeof defaultValue === "number" ? "number" : "text"}
                                        label={label}
                                        value={moduleSettings[key] !== undefined ? moduleSettings[key] : defaultValue}
                                        onChange={(e) => handleModuleSettingsChange(module, key, e.target.value)}
                                        fullWidth
                                      />
                                    </Grid>
                                  );
                                case "object":
                                  if (Array.isArray(defaultValue)) {
                                    return (
                                      <Grid item key={key} xs={12}>
                                        <Autocomplete
                                          multiple
                                          freeSolo
                                          options={[]}
                                          value={moduleSettings[key] || []}
                                          onChange={(e, v) => handleModuleSettingsChange(module, key, v)}
                                          renderTags={renderAutocompleteTags}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              type={isArrayOfNumbers(key) ? "number" : "text"}
                                              InputLabelProps={params.InputLabelProps as any}
                                              InputProps={{...params.InputProps}}
                                              variant={Config.input.labelVariant}
                                              label={label}
                                              placeholder={t("settings_enterValues")}
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </Grid>
                                    );
                                  }
                                  return unhandledKey(key, "Is object but not array!", "\ndefaultValue:", defaultValue);
                                default:
                                  return unhandledKey(key, `Type ${typeof defaultValue} is not handled!`, "\ndefaultValue:", defaultValue);
                              }
                            })}
                          </AccordionDetails>
                        </Accordion>
                      )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{marginTop: 1}}
            fullWidth
          >
            {t("settings_update")}
          </Button>
        </form>
      </IfNoError>
    </div>
  );
};

export const defaultModulesSettings: ModulesSettings = {
  aboutUs: defaultAboutUsModuleSettings,
  adsUniverse: defaultAdsUniverseModuleSettings,
  blog: defaultBlogModuleSettings,
  flows_fundings: defaultFlowsFundingsModuleSettings,
  flows_mixed: defaultFlowsMixedModuleSettings,
  flows_transfer: defaultFlowsTransferModuleSettings,
  sujets: defaultSujetsModuleSettings,
  top_fundings: defaultTopFundingsModuleSettings,
  top_transfer: defaultTopTransferModuleSettings
}

const isArrayOfNumbers = (key: string): boolean => {
  const keysThatAreArraysOfNumbers = ["defaultFundingType"];
  return keysThatAreArraysOfNumbers.includes(key);
}
