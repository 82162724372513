import React from "react";
import styled from "styled-components";
//import { Switch, SwitchLabel, SwitchRadio, SwitchSelection } from "./switch-styles.js";
import { useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  ".MuiToggleButton-root": {
    fontSize: "small",
    padding: 8,
    textTransform: "none",
  },
  ".Mui-selected": {
    color: "#fff",
    background: "var(--primary-color)",
    fontWeight: 600,
  },
}));

const titleCase = (str) =>
  str
    .split(/\s+/)
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(" ");

export type TSwitchProps = {
  values: {
    value: any;
    label: string;
  }[];
  selected: any;
  onChange: (v: any) => void;
};

export const ToggleSwitch = ({ values, selected, onChange }: TSwitchProps) => {
  const [selected_, setSelected] = useState(selected);

  const handleChange = (e, val) => {
    setSelected(val);
    onChange(val);
  };

  return (
    <StyledToggleButtonGroup
      color="primary"
      value={selected_}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      style={{ marginTop: "auto", marginBottom: "auto" }}
    >
      {values.map((val, i) => {
        return (
          <ToggleButton key={`val${i}`} value={val.value}>
            {titleCase(val.label)}
          </ToggleButton>
        );
      })}
    </StyledToggleButtonGroup>
  );
};
